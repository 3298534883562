<template>
  <footer class="page-footer">
    <div class="container">
      <div class="flex flex-wrap print:flex-col">
        <p class="flex flex-col text-sm page-footer__content">
          <span class="font-semibold">EBZ Akademie</span>
          <span class="mb-4">
            Springorumallee 20
            <br />44795 Bochum
          </span>
          <span class="mb-4">
            Tel:
            <a href="tel:+492349447575">+49 (234) 9447 575</a>
            <br />Fax:
            <a href="tel:+492349447599">+49 (234) 9447 599</a>
            <br />E-Mail:
            <a href="mailto:akademie@e-b-z.de">akademie@e-b-z.de</a>
            <br />
          </span>
          <a href="https://ebz-akademie.de">www.ebz-akademie.de</a>
        </p>
        <div class="flex flex-col lg:flex-row xs:ml-16">
          <div class="flex-col print:hidden page-footer__content lg:mr-16">
            <span class="inline-block mb-5 text-sm"
              >Unsere Zertifizierungen</span
            >
            <div class="flex">
              <img
                src="~/assets/img/footer/dqs.svg"
                alt="DQS"
                class="h-32 mr-4"
              />
              <img src="~/assets/img/footer/zfu.svg" alt="ZFU" class="h-32" />
            </div>
          </div>
          <div class="flex-col print:hidden page-footer__content">
            <span class="inline-block mb-4 text-sm">EBZ Akademie Social</span>
            <div class="flex flex-wrap text-gray-700 print:flex-col">
              <a
                href="https://www.wir-ebzler.de/"
                class="flex-shrink-0 mb-4 mr-4"
                aria-label="Wir EBZler"
              >
                <img
                  src="../assets/img/footer/wir-ebzler-logo.png"
                  alt="Wir EBZler"
                />
              </a>
              <a
                href="https://www.xing.com/companies/ebz-europ%C3%A4ischesbildungszentrumderwohnungs-undimmobilienwirtschaft"
                class="mr-4 text-gray-700 print:mb-4"
                title="EBZ auf Xing"
                aria-label="EBZ auf Xing"
              >
                <Icon name="Xing" width="40" height="40" />
              </a>
              <a
                href="https://de.linkedin.com/company/ebz-business-school-gmbh"
                class="mr-4 text-gray-700 print:mb-4"
                title="EBZ auf LinkedIn"
                aria-label="EBZ auf LinkedIn"
              >
                <Icon name="LinkedIn" width="40" height="40" />
              </a>
              <a
                href="https://www.facebook.com/EBZAkademie/"
                class="mr-4 text-gray-700 print:mb-4"
                title="EBZ auf Facebook"
                aria-label="EBZ auf Facebook"
              >
                <Icon name="Facebook" width="40" height="40" />
              </a>
              <!-- <a
                href="https://twitter.com/ebzakademie"
                class="mr-4 text-gray-700 print:mb-4"
                title="EBZ auf Twitter"
                aria-label="EBZ auf Twitter"
              >
                <Icon name="Twitter" width="40" height="40" />
              </a> -->
              <a
                href="https://www.e-b-z.de/fachbereiche/ebz-akademie/ebz-akademie-blog.html"
                class="mr-4 text-gray-700 print:mb-4"
                title="EBZ Akademie Blog"
                aria-label="EBZ Akademie Blog"
              >
                <Icon name="rss" width="40" height="40" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="pt-5 pb-1 text-xs leading-snug text-gray-100 bg-dark-blue-900 print:hidden"
    >
      <div class="container page-footer__links">
        <a
          href="#"
          title="Datenschutz-Einstellungen"
          @click="showCookieConsent()"
          >Datenschutz-Einstellungen</a
        >
        <a href="https://www.e-b-z.de/sitemap.html" title="Sitemap">Sitemap</a>
        <a href="https://www.e-b-z.de/impressum.html" title="Impressum"
          >Impressum</a
        >
        <a
          href="https://www.e-b-z.de/datenschutzerklaerung.html"
          title="Datenschutzerklärung"
          >Datenschutzerklärung</a
        >
        <a
          href="https://www.e-b-z.de/agb.html"
          title="Allgemeine Geschäftsbedingungen"
          >AGB</a
        >
        <a href="https://www.e-b-z.de/kontakt.html" title="Kontakt">Kontakt</a>
        <a href="https://www.e-b-z.de/bibliothek.html" title="Bibliothek"
          >Bibliothek</a
        >
        <a href="https://www.e-b-z.de/it-support.html" title="IT-Support"
          >IT-Support</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from '~/components/Icon'

export default {
  components: {
    Icon
  },
  methods: {
    showCookieConsent() {
      this.$store.dispatch('cookiebanner/showModal')
    }
  }
}
</script>

<style lang="postcss">
.page-footer {
  @apply pt-12 bg-gray-300;
  background: url('../assets/img/footer/footern-pattern.png');
}

.page-footer__social .icon {
  @apply mr-4;
}

.page-footer__content {
  @apply mb-12 inline-flex;
}

@screen lg {
  .page-footer__content {
    width: auto;
  }
}

.page-footer__social a:last-of-type .icon {
  @apply mr-0;
}

.page-footer__links {
  @apply flex flex-wrap pr-16;
}

.page-footer__links a {
  @apply text-gray-100 mr-4 mb-4;
}

@screen md {
  .page-footer__links {
    @apply justify-end;
  }

  .page-footer__links a {
    @apply ml-4 mr-0;
  }
}
</style>
