<template>
  <header class="pb-16 Hero md:pt-6 sm:pb-32" :style="heroStyle">
    <div class="container relative z-10">
      <a
        href="https://ebz-akademie.de/"
        class="inline-block mt-8"
        aria-label="Zur EBZ-Startseite"
      >
        <EbzLogo class="select-none" />
      </a>
      <div class="mt-12">
        <h1 class="mb-2 text-white md:mb-6">
          {{ heroHeadline }}
        </h1>
        <p
          class="max-w-md text-sm font-semibold lg:max-w-2xl md:text-base text-white hyphenate"
        >
          {{ heroText }}
        </p>

        <div
          v-if="heroButton"
          class="inline-block md:absolute md:right-0 md:pr-8"
        >
          <div
            class="bg-white bg-opacity-25 md:bg-opacity-50 rounded-sm px-6 py-3 md:mr-8 mt-6 md:-mt-16"
          >
            <a :href="heroButton.link" class="button" target="_blank">
              {{ heroButton.text }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div aria-hidden="true" class="Hero__Gradient" />
  </header>
</template>
<script>
import { head, find, flatten } from 'lodash'
import EbzLogo from '~/components/EbzLogo'

export default {
  components: {
    EbzLogo
  },
  data() {
    return {
      heros: [
        {
          code: 'default',
          headline: 'EBZ Bildungsfinder',
          text: `Die EBZ Akademie bietet Weiterbildungen für die Wohnungs- und Immobilienwirtschaft an. Dazu gehören z.B. Zertifikatslehrgänge zum/r Immobilienkaufmann/frau, zum/r geprüfte/n Immobilienfachwirt/in (EBZ/IHK) oder Arbeitskreise und Führungsforen zu den aktuellen Branchenthemen wie Digitalisierung, Quartiersmanagement oder Personalentwicklung.`,
          button: {
            text: 'zur 360° Bildungsberatung',
            link: 'https://ebz-akademie.de/360gradbildungsberatung/'
          }
        },
        {
          code: 'BT',
          headline: 'Bauträger und Projektentwickler',
          text: `Die EBZ Akademie bietet Weiterbildungen für Bauträger und Projektentwickler an. Dazu gehören z.B. Zertifikatslehrgänge zum/r Immobilienkaufmann/frau, zum/r geprüfte/n Immobilienfachwirt/in (EBZ/IHK) oder der geprüfte/r Bauleiter/in (EBZ). Ebenso gibt es vielfältige Tagesveranstaltungen zu aktuellen Themen wie Gebäudetechnik, Bauprojektmanagement und Modernisierung.`
        },
        {
          code: 'IV',
          headline: 'Immobilienverwalter',
          text: `Die EBZ Akademie bietet mit der Weiterbildung Immobilienverwalter/in (IHK) die passende Qualifizierung für Quereinsteiger an, für berufserfahrene Immobilienverwalter etwa die Weiterbildungen zum Immobilienfachwirt, Immobilienfachverwalter für Wohnungseigentum oder sogar maßgeschneiderte Trainings- und Beratungsbausteine für Immobilienverwaltungen. Auch die Weiterbildungspflicht können Sie mit unseren Trainings für Immobilienverwalter erfüllen.`
        },
        {
          code: 'MA',
          headline: 'Makler und Bankenmakler',
          text: `Die EBZ Akademie bietet mit der Weiterbildung Immobilienmakler/in (IHK) die passende Qualifizierung für Quereinsteiger an, für berufserfahrene Immobilienmakler etwa die Weiterbildungen zum Immobilienfachwirt, Immobilienbewerter oder sogar maßgeschneiderte Trainings- und Coachingbausteine für Immobilienmakler. Auch die Weiterbildungspflicht können Sie mit unseren Trainings für Immobilienmakler erfüllen.`
        },
        {
          code: 'PM-FM',
          headline: 'Property Management und Facility Management',
          text: `Gewerbeimmobilien spielen in den Trainingsangeboten der EBZ Akademie einen zunehmend große Rolle. Neben Tagesveranstaltungen zum Gewerbemietrecht, der Betriebskostenabrechnung für Gewerbeimmobilien oder zur technischen Gebäudeausstattung, bietet wir dem den Weiterbildungen Commercial Property Manager/in (IHK) sowie Fachwirt/in Facility Management (GEFMA) auch zwei hochwertige und anerkannte Zertifikatslehrgänge an.`
        },
        {
          code: 'WU',
          headline: 'Wohnungsunternehmen',
          text: `Die EBZ Akademie bietet Weiterbildungen für die Wohnungs- und Immobilienwirtschaft an. Dazu gehören z.B. Zertifikatslehrgänge zum/r Immobilienkaufmann/frau, zum/r geprüfte/n Immobilienfachwirt/in (EBZ/IHK) oder Arbeitskreise und Führungsforen zu den aktuellen Branchenthemen wie Digitalisierung, Quartiersmanagement oder Personalentwicklung.`
        }
      ]
    }
  },
  computed: {
    branchen() {
      const b =
        this.$store.state.route.query &&
        flatten([this.$store.state.route.query.b])

      return b
    },
    heroBranche() {
      const selected =
        this.branchen && this.branchen.length === 1 && head(this.branchen)

      return (
        (selected && find(this.heros, { code: selected })) ||
        find(this.heros, { code: 'default' })
      )
    },
    heroHeadline() {
      return this.heroBranche.headline
    },
    heroImage() {
      return this.heroBranche.code
    },
    heroText() {
      return this.heroBranche.text
    },
    heroButton() {
      return this.heroBranche.button || null
    },
    heroStyle() {
      const image = require(`@/assets/img/hero/${this.heroImage}.png`)
      return {
        backgroundImage: `url(${image})`
      }
    }
  }
}
</script>
<style lang="postcss">
.Hero {
  @apply bg-cover bg-center bg-no-repeat;
  @apply relative;

  background-image: url('../assets/img/hero/default.png');
}

.Hero__Gradient {
  @apply absolute top-0 left-0 w-full h-full block;
  content: ' ';
  background-image: linear-gradient(
    45deg,
    #23334f 13.63%,
    rgba(0, 133, 255, 0) 59.78%
  );
}

@screen lg {
  .Hero__Gradient {
    background-image: linear-gradient(
      45deg,
      #23334f 13.63%,
      rgba(0, 133, 255, 0) 59.78%
    );
  }
}

.text-shadow {
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.33);
}
</style>
