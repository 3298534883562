import { flatten } from 'lodash'
import moment from 'moment'

export const state = () => ({
  q: null,
  inhouse: false,
  online: false,
  mabvAgent: false,
  mabvManager: false,
  start: null,
  end: null,
  themen: [],
  branchen: [],
  regionen: [],
  veranstaltungsarten: [],
  selectedThemen: [],
  selectedBranchen: [],
  selectedRegionen: [],
  selectedVeranstaltungsarten: []
})

export const getters = {
  getSelectedThemen: (state) => {
    return state.selectedThemen.map((selected) => {
      return state.themen.find((item) => item.code === selected)
    })
  },
  getSelectedBranchen: (state) => {
    return state.selectedBranchen.map((selected) => {
      return state.branchen.find((item) => item.code === selected)
    })
  },
  getSelectedRegionen: (state) => {
    return state.selectedRegionen.map((selected) => {
      return state.regionen.find((item) => item.code === selected)
    })
  },
  getSelectedVeranstaltungsarten: (state) => {
    return state.selectedVeranstaltungsarten.map((selected) => {
      return state.veranstaltungsarten.find((item) => item.code === selected)
    })
  }
}

export const mutations = {
  setThemen(state, data) {
    state.themen = data
  },
  setBranchen(state, data) {
    state.branchen = data
  },
  setRegionen(state, data) {
    state.regionen = data
  },
  setVeranstaltungsarten(state, data) {
    state.veranstaltungsarten = data
  },

  /** Inhouse buchbar */
  setInhouse(state, value) {
    state.inhouse = value && Number(JSON.parse(value))
  },
  resetInhouse(state) {
    state.inhouse = false
  },

  /** Online */
  setOnline(state, value) {
    state.online = value && Number(JSON.parse(value))
  },
  resetOnline(state) {
    state.online = false
  },

  /** MaBV Stunden */
  setMabvAgent(state, value) {
    state.mabvAgent = value && Number(JSON.parse(value))
  },
  resetMabvAgent(state) {
    state.mabvAgent = false
  },
  setMabvManager(state, value) {
    state.mabvManager = value && Number(JSON.parse(value))
  },
  resetMabvManager(state) {
    state.mabvManager = false
  },

  /** Suche */
  setQuery(state, data) {
    state.q = data
  },
  resetQuery(state) {
    state.q = null
  },

  /** Thema */
  selectThema(state, code) {
    state.selectedThemen.push(state.themen.find((thema) => thema.code === code))
  },
  setSelectedThemen(state, value) {
    state.selectedThemen = value
  },
  resetSelectedThemen(state) {
    state.selectedThemen = []
  },

  /** Branche */
  selectBranche(state, code) {
    state.selectedBranchen.push(
      state.branchen.find((branche) => branche.code === code)
    )
  },
  setSelectedBranchen(state, value) {
    state.selectedBranchen = value
  },
  resetSelectedBranchen(state) {
    state.selectedBranchen = []
  },

  /** Region */
  selectRegion(state, code) {
    state.selectedRegionen.push(
      state.regionen.find((region) => region.code === code)
    )
  },
  setSelectedRegionen(state, value) {
    state.selectedRegionen = value
  },
  resetSelectedRegionen(state) {
    state.selectedRegionen = []
  },

  /** Veranstaltungsart */
  selectVeranstaltungsart(state, code) {
    state.selectedVeranstaltungsarten.push(
      state.veranstaltungsarten.find((art) => art.code === code)
    )
  },
  setSelectedVeranstaltungsarten(state, value) {
    state.selectedVeranstaltungsarten = value
  },
  resetSelectedVeranstaltungsarten(state) {
    state.selectedVeranstaltungsarten = []
  },

  /** Datum */
  setDate(state, value) {
    if (!value) return
    state.start = value.start && moment(value.start).format('YYYY-MM-DD')
    state.end = value.end && moment(value.end).format('YYYY-MM-DD')
  },
  resetDate(state) {
    state.start = null
    state.end = null
  }
}

export const actions = {
  async getFilters({ commit }) {
    await this.$axios.get(`/themen`).then(({ data }) => {
      data = data.map((item) => ({
        code: item.code,
        value: item.titel
      }))

      commit('setThemen', data)
    })

    await this.$axios.get(`/branchen`).then(({ data }) => {
      data = data.map((item) => ({
        code: item.code,
        value: item.titel
      }))

      commit('setBranchen', data)
    })

    await this.$axios.get(`/regionen`).then(({ data }) => {
      data = data.map((item) => ({
        code: item.code,
        value: item.beschreibung
      }))

      commit('setRegionen', data)
    })

    await this.$axios.get(`/art`).then(({ data }) => {
      data = data.map((item) => ({
        code: item.code,
        value: item.titel
      }))

      commit('setVeranstaltungsarten', data)
    })
  },

  updateInhouse({ commit }, value) {
    commit('setInhouse', value)
  },
  prefillInhouse({ commit }, value) {
    commit('setInhouse', value)
  },
  resetInhouse({ commit }) {
    commit('resetInhouse')
  },

  updateOnline({ commit }, value) {
    commit('setOnline', value)
  },
  prefillOnline({ commit }, value) {
    commit('setOnline', value)
  },
  resetOnline({ commit }) {
    commit('resetOnline')
  },

  updateMabvAgent({ commit }, value) {
    commit('setMabvAgent', value)
  },
  prefillMabvAgent({ commit }, value) {
    commit('setMabvAgent', value)
  },
  resetMabvAgent({ commit }) {
    commit('resetMabvAgent')
  },

  updateMabvManager({ commit }, value) {
    commit('setMabvManager', value)
  },
  prefillMabvManager({ commit }, value) {
    commit('setMabvManager', value)
  },
  resetMabvManage({ commit }) {
    commit('resetMabvManager')
  },

  /** Suche */
  updateQuery({ commit }, value) {
    commit('setQuery', value)
  },
  prefillQuery({ commit }, value) {
    commit('setQuery', value)
  },
  resetQuery({ commit }) {
    commit('resetQuery')
  },

  /** Thema */
  updateThema({ commit }, value) {
    commit('setSelectedThemen', value)
  },
  resetSelectedThemen({ commit }) {
    commit('resetSelectedThemen')
  },
  prefillSelectedThemen({ commit }, codes) {
    commit('resetSelectedThemen')

    codes && flatten([codes]).forEach((code) => commit('selectThema', code))
  },

  /** Branche */
  updateBranche({ commit }, value) {
    commit('setSelectedBranchen', value)
  },
  resetSelectedBranchen({ commit }) {
    commit('resetSelectedBranchen')
  },
  prefillSelectedBranchen({ commit }, codes) {
    commit('resetSelectedBranchen')

    codes && flatten([codes]).forEach((code) => commit('selectBranche', code))
  },

  /** Region */
  updateRegion({ commit }, value) {
    commit('setSelectedRegionen', value)
  },
  resetSelectedRegionen({ commit }) {
    commit('resetSelectedRegionen')
  },
  prefillSelectedRegionen({ commit }, codes) {
    commit('resetSelectedRegionen')

    codes && flatten([codes]).forEach((code) => commit('selectRegion', code))
  },

  /** Veranstaltungsarten */
  updateVeranstaltungsart({ commit }, value) {
    commit('setSelectedVeranstaltungsarten', value)
  },
  resetSelectedVeranstaltungsarten({ commit }) {
    commit('resetSelectedVeranstaltungsarten')
  },
  prefillSelectedVeranstaltungsarten({ commit }, codes) {
    commit('resetSelectedVeranstaltungsarten')

    codes &&
      flatten([codes]).forEach((code) =>
        commit('selectVeranstaltungsart', code)
      )
  },

  /** Datum */
  updateDate({ commit }, value) {
    commit('setDate', value)
  },
  prefillDate({ commit }, value) {
    commit('setDate', value)
  },
  resetDate({ commit }) {
    commit('resetDate')
  }
}
