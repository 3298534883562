<template>
  <div class="pb-12">
    <Hero />

    <div class="container relative z-50 sm:-mt-12">
      <Filters
        :themen="themen"
        :branchen="branchen"
        :regionen="regionen"
        :veranstaltungsarten="veranstaltungsarten"
        :date="date"
        @on-thema-updated="updateThema"
        @on-branche-updated="updateBranche"
        @on-region-updated="updateRegion"
        @on-veranstaltungsart-updated="updateVeranstaltungsart"
        @do-search="doSearch"
      />
      <FilterHint v-if="filterSelected" />
      <CoronaHint />
      <div class="flex flex-col my-4 text-center events-info text-gray-750">
        <span class="inline-block mt-4 font-semibold"
          >{{ meta.total }} Ergebnisse</span
        >
        <div v-if="meta.total < 1" class="my-16 text-center">
          <h2 class="text-lg font-medium text-dark-blue-900 mb-4">
            Sie haben nichts passendes gefunden?
          </h2>
          <p class="w-156 mx-auto">
            Buchen Sie jetzt Ihren persönlichen Beratungstermin bei unserer<br />
            360° Bildungsberatung. Wir helfen Ihnen gern!
          </p>
          <a
            href="https://outlook.office365.com/owa/calendar/Bildungsberatung1@ebz.onmicrosoft.com/bookings/"
            target="_blank"
            class="button mt-4"
          >
            zur Online Terminbuchung
          </a>
        </div>
        <span>Seite {{ currentPage }} von {{ meta.last_page }}</span>
      </div>
    </div>
    <div class="container relative z-40">
      <section class="events">
        <Loader v-if="isLoading" />
        <EventCard
          v-for="vorlage in vorlagen"
          v-else
          :key="vorlage.nummer"
          :vorlage="vorlage"
        />
      </section>
      <paginate
        v-model="currentPage"
        :page-count="meta.last_page"
        :click-handler="pageClick"
        prev-text
        next-text
        :prev-link-class="
          'button pagination__button pagination__button--prev mr-4'
        "
        :next-link-class="
          'button pagination__button pagination__button--next ml-4'
        "
        :page-link-class="'pagination__page-link transition'"
        :break-view-class="'pagination__break-link'"
        :container-class="'pagination'"
      />
    </div>
    <ShoppingCartHelper />
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import moment from 'moment'
import { pickBy, omitBy, identity, isEmpty, size } from 'lodash'
import Paginate from 'vuejs-paginate/src/components/Paginate.vue'
import EventCard from '~/components/cards/EventCard'
import Filters from '~/components/Filters'
import Loader from '~/components/Loader'
import Hero from '~/components/Hero'
import CoronaHint from '~/components/CoronaHint'
import FilterHint from '~/components/FilterHint'
import ShoppingCartHelper from '~/components/ShoppingCartHelper'

export default {
  scrollTop: true,
  components: {
    Hero,
    Filters,
    EventCard,
    Loader,
    Paginate,
    CoronaHint,
    ShoppingCartHelper,
    FilterHint
  },
  async beforeRouteUpdate(to, from, next) {
    let toPage = 1

    if (to.query.page === undefined) {
      toPage = from.query.page
    } else {
      toPage = to.query.page
    }

    // this.getContent(to.params.page)
    const query = {
      page: toPage,
      q: to.query.q,
      t: to.query.t,
      b: to.query.b,
      r: to.query.r,
      a: to.query.a,
      inhouse: to.query.inhouse,
      online: to.query.online,
      mabvAgent: to.query.mabvAgent,
      mabvManager: to.query.mabvManager,
      architektenkammer: to.query.architektenkammer,
      energieberater: to.query.energieberater,
      start: to.query.start,
      end: to.query.end
    }

    await this.$store.dispatch('veranstaltungsvorlagen/getAll', query)

    this.$scrollTo('.filters', 600)

    next()
  },
  async asyncData({ store, query }) {
    await store.dispatch('veranstaltungsvorlagen/getAll', {
      page: query.page,
      q: query.q,
      t: query.t,
      b: query.b,
      r: query.r,
      a: query.a,
      inhouse: query.inhouse,
      online: query.online,
      mabvAgent: query.mabvAgent,
      mabvManager: query.mabvManager,
      energieberater: query.energieberater,
      architektenkammer: query.architektenkammer,
      start: query.start,
      end: query.end
    })
    await store.dispatch('filter/getFilters')

    await store.dispatch('filter/prefillQuery', query.q)
    await store.dispatch('filter/prefillInhouse', query.inhouse)
    await store.dispatch('filter/prefillOnline', query.online)
    await store.dispatch('filter/prefillMabvAgent', query.mabvAgent)
    await store.dispatch('filter/prefillMabvManager', query.mabvManager)
    await store.dispatch(
      'filter/prefillArchitektenkammer',
      query.architektenkammer
    )
    await store.dispatch('filter/prefillEnergieberater', query.energieberater)
    await store.dispatch('filter/prefillSelectedThemen', query.t)
    await store.dispatch('filter/prefillSelectedBranchen', query.b)
    await store.dispatch('filter/prefillSelectedRegionen', query.r)
    await store.dispatch('filter/prefillSelectedVeranstaltungsarten', query.a)
    await store.dispatch('filter/prefillDate', {
      start: query.start,
      end: query.end
    })
  },
  data() {
    return {
      currentUrl: this.$route.name,
      currentPage: parseInt(this.$route.query.page) || 1
    }
  },
  head() {
    return {
      title: 'EBZ Bildungsfinder — Bildungsangebote der EBZ Akademie',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content:
            'Die Akademie des Europäischen Bildungszentrums (EBZ) bietet Aus-, Fort- und Weiterbildungsangebote für die Wohnungs- und Immobilienwirtschaft an.'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.getCanonical
        }
      ]
    }
  },
  computed: {
    filterSelected() {
      const filter = this.$store.state.filter.selectedThemen

      return filter.some((row) => row.code === 'GEN')
    },
    hasFilterSelected() {
      const filterThemen = this.$store.state.filter.selectedThemen
      const filterBranchen = this.$store.state.filter.selectedBranchen

      return filterThemen.length >= 1 || filterBranchen.length >= 1
    },
    getQueryParams() {
      return this.$store.state.filter.getQueryParams
    },
    getCanonical() {
      const baseUrl = 'https://www.ebz-training.de'
      let query = this.$route.query

      query = omitBy(query, isEmpty)

      if (!size(query)) {
        return baseUrl
      }

      const queryString = Object.entries(query)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')

      return `${baseUrl}/?${queryString}`
    },
    isLoading() {
      return this.$store.state.veranstaltungsvorlagen.isLoading
    },
    themen() {
      return this.$store.state.filter.themen
    },
    branchen() {
      return this.$store.state.filter.branchen
    },
    regionen() {
      return this.$store.state.filter.regionen
    },
    veranstaltungsarten() {
      return this.$store.state.filter.veranstaltungsarten
    },
    vorlagen() {
      return this.$store.state.veranstaltungsvorlagen.list
    },
    meta() {
      return this.$store.state.veranstaltungsvorlagen.meta
    },
    date() {
      const start = this.$store.state.filter.start
      const end = this.$store.state.filter.end

      return {
        start: start && moment(start).toDate(),
        end: end && moment(end).toDate()
      }
    },
    teaserHeadline() {
      const themen = this.$store.state.filter.selectedThemen
      let o = ''

      if (themen && themen.length) {
        o = `Bildungsangebote zu den Themen ${themen.map(
          (thema) => thema.value
        )}`
      }

      return o
    }
  },
  methods: {
    pageClick(page) {
      let query = this.$route.query

      query = pickBy(query, identity)

      this.$router.push({
        path: this.$route.path,
        query: { ...query, ...{ page } }
      })
    },
    updateThema(value) {
      this.$store.dispatch('filter/updateThema', value)
    },
    updateBranche(value) {
      this.$store.dispatch('filter/updateBranche', value)
    },
    updateRegion(value) {
      this.$store.dispatch('filter/updateRegion', value)
    },
    updateVeranstaltungsart(value) {
      this.$store.dispatch('filter/updateVeranstaltungsart', value)
    },
    doSearch() {
      let query = {
        // page: 1,
        q: this.$store.state.filter.q,
        t: this.$store.state.filter.selectedThemen.map((item) => item.code),
        b: this.$store.state.filter.selectedBranchen.map((item) => item.code),
        r: this.$store.state.filter.selectedRegionen.map((item) => item.code),
        a: this.$store.state.filter.selectedVeranstaltungsarten.map(
          (item) => item.code
        ),
        online: this.$store.state.filter.online,
        inhouse: this.$store.state.filter.inhouse,
        mabvAgent: this.$store.state.filter.mabvAgent,
        mabvManager: this.$store.state.filter.mabvManager,
        architektenkammer: this.$store.state.filter.architektenkammer,
        energieberater: this.$store.state.filter.energieberater,
        start: this.$store.state.filter.start,
        end: this.$store.state.filter.end
      }

      query = pickBy(query, identity)

      this.$router.push({
        path: this.$route.path,
        query
      })
    }
  }
}
</script>

<style lang="postcss">
.events {
  @apply flex flex-wrap -mx-4;
}

.pagination {
  @apply flex flex-wrap justify-center mt-24 mb-8 -mx-4;
}

.pagination__button {
  @apply w-10 bg-no-repeat bg-center;
}

.pagination__button--prev {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgOCAxMiIgd2lkdGg9IjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTYuNzEwMDEuNzFjLS4zOS0uMzktMS4wMi0uMzktMS40MSAwbC00LjU4OTk5OSA0LjU5Yy0uMzkuMzktLjM5IDEuMDIgMCAxLjQxbDQuNTg5OTk5IDQuNTljLjM5LjM5IDEuMDIuMzkgMS40MSAwcy4zOS0xLjAyIDAtMS40MWwtMy44OC0zLjg5IDMuODgtMy44OGMuMzktLjM5LjM4LTEuMDMgMC0xLjQxeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
}

.pagination__button--next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgNyAxMiIgd2lkdGg9IjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibS4yOTI0OTEuMjkyNWMuMzktLjM5IDEuMDE5OTk5LS4zOSAxLjQwOTk5OSAwbDQuNTkgNC41OWMuMzkuMzkuMzkgMS4wMiAwIDEuNDFsLTQuNTkgNC41OWMtLjM5LjM5LTEuMDE5OTk5LjM5LTEuNDA5OTk5IDAtLjM4OTk5OTUtLjM5LS4zODk5OTk1LTEuMDIgMC0xLjQxbDMuODc5OTk5LTMuODktMy44Nzk5OTktMy44OGMtLjM4OTk5OTUtLjM5LS4zNzk5OTk2LTEuMDMgMC0xLjQxeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
}

.pagination li:first-of-type {
  @apply absolute mt-16;
  left: 1rem;
}

.pagination li:last-of-type {
  @apply absolute mt-16;
  right: 1rem;
}

@screen xxs {
  .pagination li:first-of-type,
  .pagination li:last-of-type {
    @apply static mt-0 right-0 left-0;
  }
}

@screen xs {
  .pagination {
    @apply mx-0;
  }
}

.pagination__page-link {
  @apply flex items-center justify-center;
  @apply font-semibold text-sm select-none;
  @apply h-10 w-10 mx-px rounded;
}

.pagination__page-link:hover {
  @apply bg-gray-400 no-underline;
}

.pagination__page-link:focus {
  @apply bg-gray-600 outline-none;
}

.pagination li.disabled .pagination__button {
  @apply cursor-not-allowed pointer-events-none shadow-none bg-gray-600 text-gray-700;
  opacity: 0.4;
}

.pagination li.active .pagination__page-link {
  @apply bg-dark-blue-1000 text-gray-100 cursor-default pointer-events-none;
}

.pagination__break-link {
  @apply select-none pointer-events-none;
}
</style>
