<template>
  <div class="print:hidden">
    <div class="share-icons-wrapper flex items-center text-gray-700">
      <div>
        <Icon
          name="share"
          class="text-gray-600 mr-4 md:mr-6 inline-block w-6 h-6"
        />
        <span class="hidden xs:inline-block font-semibold text-xs mr-6">
          Angebot teilen
        </span>
      </div>
      <div class="share-icons">
        <button
          aria-label="Link kopieren"
          class="w-10 h-10 flex items-center justify-center"
          @click="copyURL"
        >
          <Icon name="link" />
        </button>
        <a
          v-for="link in socialLinks"
          :key="link.name"
          :href="link.url"
          :aria-label="'Teilen auf ' + link.name"
          target="_blank"
          rel="noopener"
        >
          <Icon :name="link.name" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/Icon'

export default {
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    baseUrl: {
      type: String,
      default: ''
    }
  },
  computed: {
    socialLinks() {
      return [
        {
          name: 'Xing',
          url: `https://www.xing.com/spi/shares/new?url=${this.url}`
        },
        {
          name: 'LinkedIn',
          url: `https://www.linkedin.com/shareArticle?url=${this.url}&title=${this.title}`
        },
        {
          name: 'Facebook',
          url: `https://www.facebook.com/sharer.php?u=${this.url}`
        },
        {
          name: 'Twitter',
          url: `https://twitter.com/share?url=${this.url}&text=${this.titleEncoded}%3A%0A&via=EBZAkademie`
        },
        {
          name: 'WhatsApp',
          url: `https://api.whatsapp.com/send?phone=&text=${this.titleEncoded}${this.titleAdditionEncoded}${this.url}`
        }
      ]
    },
    url() {
      return this.baseUrl + this.$route.fullPath
    },
    titleEncoded() {
      return encodeURI(this.title)
    },
    titleAdditionEncoded() {
      return encodeURI(' auf EBZ-Akademie:') + '%0A'
    }
  },
  methods: {
    async copyURL() {
      try {
        await this.$copyText(this.url)
        this.$toast.success('Link wurde in die Zwischenablage kopiert')
      } catch (e) {
        this.$toast.error('Link konnte nicht kopiert werden')
      }
    }
  }
}
</script>

<style lang="postcss">
.share-icons-wrapper {
  @apply right-0 relative flex flex-no-wrap overflow-hidden opacity-100;
}

.share-icons-wrapper:hover {
  @apply opacity-100 right-0;
  transition: 0.2s ease-out 0.05s;
}

.share-icons-wrapper .icon {
  @apply flex-shrink-0;
}

.share-icons {
  @apply opacity-100 flex flex-no-wrap;
  transition: 0.1s ease-out 0.1s;
}

.share-icons:focus-within {
  @apply opacity-100;
}

.share-icons a {
  @apply p-2 flex items-center text-gray-700 flex-shrink-0 w-10 h-10;
}

.share-icons a:focus {
  @apply outline-none;
}

.share-icons a:focus .icon--LinkedIn {
  @apply text-LinkedIn;
}

.share-icons a:focus .icon--Facebook {
  @apply text-Facebook;
}

.share-icons a:focus .icon--Xing {
  @apply text-Xing;
}

.share-icons a:focus .icon--Twitter {
  @apply text-Twitter;
}

.share-icons a:focus .icon--WhatsApp {
  @apply text-WhatsApp;
}

.share-icons button:focus .icon--link {
  @apply text-dark-blue-800;
}

.share-icons .icon path,
footer .icon path {
  transition: 0.1s linear;
}

.icon--LinkedIn:hover path {
  @apply fill-LinkedIn;
}

.icon--Facebook:hover path {
  @apply fill-Facebook;
}

.icon--Xing:hover path {
  @apply fill-Xing;
}

.icon--Twitter:hover path {
  @apply fill-Twitter;
}

.icon--WhatsApp:hover path {
  @apply fill-WhatsApp;
}

.icon--rss:hover path {
  @apply fill-dark-blue-900;
}

.icon--link:hover path {
  @apply fill-dark-blue-800;
}

@media (pointer: coarse) {
  .share-icons-wrapper:hover,
  .share-icons-wrapper:hover .share-icons,
  .share-icons,
  .share-icons-wrapper {
    @apply right-0 opacity-100;
  }

  .icon--share {
    @apply hidden;
  }
}
</style>
