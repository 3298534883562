<template>
  <div>
    <div v-if="processing">
      <h3 class="mt-6 mb-3 text-green-700">Wir übermitteln Ihre Anfrage</h3>
      <p>
        Bitte haben Sie einen Moment Geduld, Sie werden in Kürze weitergeleitet.
      </p>
    </div>
    <div v-else>
      <h3 class="mt-6 mb-3">
        Bitte überprüfen Sie noch einmal Ihre Daten und bestätigen Sie diese
      </h3>

      <h3 class="mt-12 mb-3">
        Folgende Veranstaltungen haben Sie ausgewählt:
      </h3>

      <ul v-for="(item, index) in items" :key="item.titel">
        <li :key="index" class="list-disc">
          {{ item.titel }} ({{ item.nummer }})
        </li>
      </ul>

      <h3 v-if="type === 'company'" class="mt-12 mb-3">
        Sie haben die Anmeldung als <strong>Unternehmen</strong> ausgefüllt
      </h3>

      <h3 v-if="type === 'person'" class="mt-12 mb-3">
        Sie haben die Anmeldung als <strong>Privatperson</strong> ausgefüllt
      </h3>

      <p v-if="fullPrice > 0">
        Die Gesamtsumme beträgt
        <strong>{{ fullPrice | currency }}</strong>
      </p>

      <p v-if="fullPrice > 0">
        Gewählte Zahlungsmethode:
        <strong>{{ paymentMethod.name }}</strong>
      </p>

      <div v-if="type === 'person'">
        <h3 class="mt-12 mb-3">Privatperson</h3>

        <table class="text-sm">
          <tbody>
            <tr>
              <td>Anrede</td>
              <td>
                {{ model.privateSalutation }}
              </td>
            </tr>
            <tr>
              <td>Titel</td>
              <td>
                {{ model.privateJobTitle }}
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ model.firstName }} {{ model.lastName }}</td>
            </tr>
            <tr>
              <td>Adresse</td>
              <td>
                {{ model.address }}
                <br />
                {{ model.zip }} {{ model.city }}
              </td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>{{ model.email }}</td>
            </tr>
            <tr>
              <td>Bemerkungen</td>
              <td>{{ model.privateBemerkung }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="type === 'company'">
        <h3 class="mt-12 mb-3">Unternehmen</h3>

        <table class="text-sm">
          <tbody>
            <tr>
              <td>Name des Unternehmens</td>
              <td>{{ model.company }}</td>
            </tr>
            <tr>
              <td>Adresse</td>
              <td>
                {{ model.companyAddress }}
                <br />
                {{ model.companyZip }} {{ model.companyCity }}
              </td>
            </tr>
            <tr>
              <td>Ansprechpartner</td>
              <td>
                {{ model.companyContactFirstName }}
                {{ model.companyContactLastName }}
              </td>
            </tr>
            <tr>
              <td>Position</td>
              <td>{{ model.companyContactPosition }}</td>
            </tr>
            <tr>
              <td>Funktion</td>
              <td>{{ model.companyContactPositionFunction }}</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>{{ model.companyContactEmail }}</td>
            </tr>
            <tr>
              <td>Telefon</td>
              <td>{{ model.companyContactPhone }}</td>
            </tr>
          </tbody>
        </table>

        <div v-for="participant in model.participants" :key="participant.email">
          <h3 class="mt-12 mb-3">Teilnehmer</h3>
          <table class="text-sm">
            <tbody>
              <tr>
                <td>Anrede</td>
                <td>{{ participant.salutation }}</td>
              </tr>
              <tr>
                <td>Titel</td>
                <td>{{ participant.jobTitle }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ participant.firstName }} {{ participant.lastName }}</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>{{ participant.email }}</td>
              </tr>
              <tr>
                <td>Bestellnummer</td>
                <td>{{ participant.bestellnummer }}</td>
              </tr>
              <tr>
                <td>Kostenstelle</td>
                <td>{{ participant.kostenstelle }}</td>
              </tr>
              <tr>
                <td>Bemerkungen</td>
                <td>{{ participant.bemerkung }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Bestätigung -->
      <div class="mt-12 w-full md:w-2/3">
        <div
          v-if="$v.einwilligung.$error"
          class="text-red-500 font-semibold my-2"
        >
          Sie müssen unseren AGB und Datenschutzbestimmungen zustimmen, um die
          Buchung abzuschließen.
        </div>

        <input
          id="einwilligungCheck"
          v-model="einwilligung"
          type="checkbox"
          :class="{ Input: true, 'Input--Error': $v.einwilligung.$error }"
        />
        <label for="einwilligungCheck">
          Hiermit bestätige ich, dass ich die
          <a href="https://www.e-b-z.de/agb/ebz-stiftung.html" target="_blank"
            >Allgemeinen Geschäftsbedingungen</a
          >, sowie die
          <a href="https://www.e-b-z.de/agb/ebz-stiftung.html" target="_blank"
            >Widerrufsbelehrung</a
          >
          gelesen und zur Kenntnis genommen habe. Die
          <a
            href="https://www.e-b-z.de/datenschutzerklaerung.html"
            target="_blank"
            >Datenschutzbestimmungen</a
          >
          habe ich gelesen und zur Kenntnis genommen.
        </label>
      </div>

      <!-- Newsletter -->
      <div class="mt-12">
        <h3 class="mb-3">Newsletter</h3>
        <p>
          Abonnieren Sie unsere regelmäßigen E-Mail-Newsletter, damit wir Sie
          stets über aktuelle Fortbildungen und Themen in der Immobilien- und
          Wohnungswirtschaft informieren können.
        </p>

        <v-collapse-group class="mt-6">
          <v-collapse-wrapper
            class="border-none"
            @onStatusChange="toggleButtonCaption"
          >
            <button
              v-collapse-toggle
              :disabled="newsletterTopicsSelected"
              :class="{ 'button--disabled': newsletterTopicsSelected }"
              class="button button--gray z-20"
            >
              <span v-if="newsletterVisible">Newsletter-Themen ausblenden</span>
              <span v-else>Newsletter-Themen einblenden</span>
            </button>
            <div v-collapse-content>
              <div class="w-full md:w-2/3">
                <div class="flex flex-wrap justify-between">
                  <div class="mt-6 w-full md:w-1/2">
                    <h4>Branchen-Newsletter</h4>

                    <div class="mt-3">
                      <div>
                        <input
                          id="newsletter_wohnungswirtschaft"
                          v-model="newsletter_topics.wohnungswirtschaft"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_wohnungswirtschaft"
                          >Wohnungswirtschaft</label
                        >
                      </div>
                      <div class="mt-0 ml-6">
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_management_personal_rechnungswesen"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_management_personal_rechnungswesen
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_management_personal_rechnungswesen"
                            >Fokus auf Management, Personal,
                            Rechnungswesen</label
                          >
                        </div>
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_technik"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_technik
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_technik"
                            >Fokus auf Technik</label
                          >
                        </div>
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_vermietung_bestand_weg"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_vermietung_bestand_weg
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_vermietung_bestand_weg"
                            >Fokus auf Vermietung, Bestand und WEG</label
                          >
                        </div>
                      </div>
                      <div>
                        <input
                          id="newsletter_immobilienverwalter"
                          v-model="newsletter_topics.immobilienverwalter"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_immobilienverwalter"
                          >Immobilienverwalter</label
                        >
                      </div>
                      <div>
                        <input
                          id="newsletter_makler_bankenmakler"
                          v-model="newsletter_topics.makler_bankenmakler"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_makler_bankenmakler"
                          >Makler, Bankenmakler</label
                        >
                      </div>
                      <div>
                        <input
                          id="newsletter_property_management_facility_management"
                          v-model="
                            newsletter_topics.property_management_facility_management
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label
                          for="newsletter_property_management_facility_management"
                          >Property Management, Facility Management</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 w-full md:w-1/2">
                    <h4>Weitere Newsletter</h4>

                    <div class="mt-3">
                      <label for="newsletter_ausbildung"
                        >Ausbildung und Karriere</label
                      >
                      <div class="mt-0 ml-3">
                        <div>
                          <input
                            id="newsletter_ausbildung_karriere_auszubildende"
                            v-model="
                              newsletter_topics.ausbildung_karriere_auszubildende
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_ausbildung_karriere_auszubildende"
                            >für Auszubildende</label
                          >
                        </div>
                        <div>
                          <input
                            id="newsletter_ausbildung_karriere_ausbilder_personal"
                            v-model="
                              newsletter_topics.ausbildung_karriere_ausbilder_personal
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_ausbildung_karriere_ausbilder_personal"
                            >für Ausbilder und Personal</label
                          >
                        </div>
                      </div>
                    </div>
                    <div>
                      <input
                        id="newsletter_ebz_business_school"
                        v-model="newsletter_topics.ebz_business_school"
                        type="checkbox"
                        :class="{ Input: true }"
                      />
                      <label for="newsletter_ebz_business_school"
                        >EBZ Business School</label
                      >
                    </div>
                    <div>
                      <input
                        id="newsletter_stellenangebote"
                        v-model="newsletter_topics.stellenangebote"
                        type="checkbox"
                        :class="{ Input: true }"
                      />
                      <label for="newsletter_stellenangebote"
                        >Stellenangebote aus der Immobilienwirtschaft</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-12 w-full md:w-2/3">
                <h4>Neuigkeiten aus dem EBZ Akademie Blog</h4>
                <div class="mt-3">
                  <input
                    id="followBlog"
                    v-model="followBlog"
                    type="checkbox"
                    :class="{
                      Input: true,
                      'Input--Error': $v.followBlog.$error
                    }"
                  />
                  <label for="followBlog">
                    Bitte senden Sie mir regelmäßig Neuigkeiten aus dem
                    <a
                      href="https://www.e-b-z.de/fachbereiche/ebz-akademie/ebz-akademie-blog.html"
                      target="_blank"
                      >EBZ Akademie Blog</a
                    >
                    per E-Mail.
                  </label>
                </div>
              </div>

              <div class="mt-12 w-full md:w-2/3">
                <div
                  v-if="$v.newsletter.$error"
                  class="text-red-500 font-semibold my-2"
                >
                  Sie müssen der Anmeldung zu unserem Newsletter zustimmen, da
                  Sie Newsletter-Themen ausgewählt haben, die Sie interessieren.
                </div>

                <input
                  id="newsletter"
                  v-model="newsletter"
                  type="checkbox"
                  :class="{
                    Input: true,
                    'Input--Error': $v.newsletter.$error
                  }"
                />
                <label for="newsletter">
                  Mit Ihrer Anmeldung akzeptieren Sie unsere
                  <a
                    href="https://www.e-b-z.de/datenschutzerklaerung.html"
                    target="_blank"
                    >Datenschutzbestimmungen</a
                  >
                  sowie
                  <a href="https://www.e-b-z.de/agb.html" target="_blank"
                    >Allgemeinen Geschäftsbedingungen</a
                  >
                  und erklären sich einverstanden, dass Ihnen per E-Mail auf
                  Ihre Interessen abgestimmte Informationen über Angebote des
                  Europäischen Bildungszentrums der Wohnungs- und
                  Immobilienwirtschaft (EBZ) sowie deren angeschlossenen
                  Unternehmen zugesendet werden. Nach der Registrierung zum
                  Newsletter erhalten Sie von uns eine E-Mail mit einem
                  Bestätigungslink. Erst durch das Anklicken dieses Linkes ist
                  Ihre Anmeldung abgeschlossen und wirksam. Sie können Ihre
                  Einwilligung zum Erhalt der Informationen per E-Mail mit
                  Wirkung für die Zukunft unter den in den
                  Datenschutzbestimmungen angegebenen Kontakten oder an
                  <a href="mailto:werbung@e-b-z.de">werbung@e-b-z.de</a>
                  jederzeit widerrufen.
                </label>
              </div>
            </div>
          </v-collapse-wrapper>
        </v-collapse-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sameAs } from 'vuelidate/lib/validators'
import { filter } from 'lodash'

export default {
  props: {
    processing: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'company'
    },
    paymentMethod: {
      type: Object,
      default: () => {
        return {
          name: 'Keine ausgewählt',
          image: null
        }
      }
    },
    model: {
      type: Object,
      default: () => {}
    },
    cart: {
      type: Array,
      default: () => []
    }
  },
  validations: {
    followBlog: {},
    newsletter: {
      sameAs: sameAs('newsletterTopicsSelected')
    },
    einwilligung: {
      checked: (value) => value === true
    }
  },
  data() {
    return {
      newsletterVisible: false,
      einwilligung: false,
      newsletter: false,
      newsletter_topics: {
        wohnungswirtschaft: false,
        wohnungswirtschaft_fokus_management_personal_rechnungswesen: false,
        wohnungswirtschaft_fokus_technik: false,
        wohnungswirtschaft_fokus_vermietung_bestand_weg: false,
        immobilienverwalter: false,
        makler_bankenmakler: false,
        property_management_facility_management: false,
        ausbildung_karriere_auszubildende: false,
        ausbildung_karriere_ausbilder_personal: false,
        ebz_business_school: false,
        stellenangebote: false
      },
      followBlog: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'cart/items'
    }),
    fullPrice() {
      const price = this.$store.getters['cart/completePrice']

      const anzahl =
        this.type === 'person'
          ? 1
          : this.model.participants && this.model.participants.length

      return price * anzahl
    },
    newsletterTopicsSelected() {
      const selectedTopics = filter(
        this.newsletter_topics,
        (item) => item === true
      )

      return selectedTopics && selectedTopics.length > 0
    }
  },
  mounted() {},
  methods: {
    toggleButtonCaption() {
      this.newsletterVisible = !this.newsletterVisible
    },
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    updateRabattCode(value) {
      this.branche = value
    }
  }
}
</script>

<style lang="postcss" scoped>
table {
  @apply w-full;
}

@screen md {
  table {
    @apply w-2/3;
  }
}

tr:nth-child(odd) {
  @apply bg-gray-300;
}

td {
  @apply w-1/3 align-top py-2 px-1;
}

td:first-child {
  @apply font-semibold;
}

.vc-collapse {
  @apply m-0;
}
</style>
