<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <section
      v-if="vorlage"
      class="bg-gray-100 print:bg-white"
      itemscope
      itemtype="http://schema.org/EducationEvent"
    >
      <div class="container">
        <div class="pt-6">
          <nuxt-link :to="backUrl" class="mb-8 button print:hidden"
            >zurück</nuxt-link
          >
          <div
            class="flex flex-col-reverse items-center justify-between mb-2 overflow-hidden xxs:flex-row"
          >
            <span
              v-if="vorlage && vorlage.produktart && !vorlage.is_shop_item"
              class="self-start overline xxs:self-center"
              >{{ vorlage.produktart }}</span
            >
            <span
              v-else-if="vorlage.is_shop_item"
              class="self-start overline xxs:self-center"
            >
              {{ vorlage.produktart }}
              ({{ vorlage.veranstaltungen[0].nummer }})
            </span>
            <span v-else>&nbsp;</span>
            <ShareIcons
              :title="vorlage.titel"
              :base-url="host"
              class="self-end mb-2 xxs:mb-0"
            />
          </div>
          <h1 itemprop="name">{{ vorlage.titel }}</h1>
          <p v-if="vorlage.is_shop_item && vorlage.shop_duration > 0">
            Dauer: {{ vorlage.shop_duration }} Min
            <template
              v-if="
                selectedVeranstaltung.mabv_agent > 0 ||
                  selectedVeranstaltung.mabv_manager > 0
              "
            >
              - Weiterbildung gemäß MaBV (
              {{
                selectedVeranstaltung.mabv_agent > 0 ? 'Immobilienmakler' : ''
              }}
              {{
                selectedVeranstaltung.mabv_agent > 0 &&
                selectedVeranstaltung.mabv_manager
                  ? '&'
                  : ''
              }}
              {{
                selectedVeranstaltung.mabv_manager > 0
                  ? 'Wohnimmobilienverwalter'
                  : ''
              }}
              )
            </template>
          </p>
          <div class="mt-4 print:mt-3">
            <h3 v-if="!vorlage.is_shop_item" class="text-xl print:hidden">
              Termine/Orte
            </h3>
            <form v-if="!vorlage.is_shop_item">
              <fieldset>
                <div
                  v-if="
                    vorlage.veranstaltungen &&
                      vorlage.veranstaltungen.length &&
                      !vorlage.is_special_event
                  "
                  class="date-cards"
                >
                  <DateCard
                    v-for="veranstaltung in vorlage.veranstaltungen"
                    :key="veranstaltung.nummer"
                    :veranstaltung="veranstaltung"
                    :is-available-online="veranstaltung.is_available_online"
                    :is-special-event="vorlage.is_special_event"
                    :selected="
                      selectedVeranstaltung &&
                        veranstaltung.nummer == selectedVeranstaltung.nummer
                    "
                    @update-selected-card-id="updateSelectedCardId"
                  />
                </div>
              </fieldset>
              <fieldset>
                <div
                  v-if="
                    vorlage.veranstaltungen &&
                      vorlage.veranstaltungen[0].module.length &&
                      vorlage.is_special_event
                  "
                  class="date-cards"
                >
                  <DateCard
                    v-for="veranstaltung in vorlage.veranstaltungen[0].module"
                    :key="veranstaltung.nummer"
                    :veranstaltung="veranstaltung"
                    :is-available-online="veranstaltung.is_available_online"
                    :is-special-event="vorlage.is_special_event"
                    :selected="
                      selectedVeranstaltung &&
                        veranstaltung.nummer == selectedVeranstaltung.nummer
                    "
                    @update-selected-card-id="updateSelectedCardId"
                  />
                </div>
              </fieldset>
            </form>
            <div v-else class="mt-4 mb-4">
              <p>{{ vorlage.shop_description }}</p>
            </div>
            <div
              v-if="
                vorlage.is_shop_item &&
                  selectedVeranstaltung.dozenten.length === 1
              "
            >
              <p>
                Autor/-in:
                <strong>{{ selectedVeranstaltung.dozenten[0].name }}</strong>
              </p>
              <p
                v-if="
                  selectedVeranstaltung.dozenten[0].basis.beschreibung !== null
                "
                class="mt-4 mb-4"
                v-html="selectedVeranstaltung.dozenten[0].basis.beschreibung"
              />
            </div>
            <div class="flex flex-col flex-wrap lg:flex-row print:flex-row">
              <div :class="getShopItemPriceClass()">
                <span class="mb-6 text-sm font-semibold print:mb-2"
                  >Teilnehmerpreis</span
                >
                <span class="mb-1 text-4xl text-green-700 print:text-xl">
                  <span
                    v-if="
                      selectedVeranstaltung &&
                        selectedVeranstaltung.leistungen &&
                        lowestPriceGroup(selectedVeranstaltung.leistungen) &&
                        !vorlage.is_shop_item
                    "
                    itemprop="offers"
                    itemscope
                    itemtype="http://schema.org/Offer"
                  >
                    <span class="hidden" itemprop="price">
                      {{
                        lowestPriceGroup(selectedVeranstaltung.leistungen)
                          .preis_lcy_net
                      }}
                    </span>
                    <span class="hidden" itemprop="priceCurrency">EUR</span>
                    <span
                      v-if="
                        selectedVeranstaltung &&
                          selectedVeranstaltung.leistungen &&
                          selectedVeranstaltung.leistungen.length > 1 &&
                          vorlage.is_special_event === 0
                      "
                      >ab</span
                    >
                    <!-- Preisgruppe === 0? -->
                    <span
                      v-if="
                        lowestPriceGroup(selectedVeranstaltung.leistungen)
                          .preis_lcy_net === 0
                      "
                    >
                      kostenlos</span
                    >
                    <span v-else>
                      {{
                        lowestPriceGroup(selectedVeranstaltung.leistungen)
                          .preis_lcy_net | currency
                      }}
                    </span>
                  </span>
                  <span
                    v-else-if="
                      lowestPriceGroup(
                        vorlage.veranstaltungen.map((va) => va.leistungen)
                      ) &&
                        !vorlage.is_shop_item &&
                        vorlage.is_special_event === 0
                    "
                    >ab
                    {{
                      lowestPriceGroup(
                        vorlage.veranstaltungen.map((va) => va.leistungen)
                      ).preis_lcy_net | currency
                    }}</span
                  >
                  <span v-else-if="vorlage.is_shop_item">
                    {{ vorlage.shop_price | currency }}
                    <small class="text-sm text-gray-ebz">zzgl. Ust.</small>
                  </span>
                  <span v-else-if="vorlage.is_special_event">
                    {{ getSpecialEventPriceGroup() | currency }}
                  </span>
                  <span v-else>auf Anfrage</span>
                </span>
                <a
                  v-if="!vorlage.is_shop_item"
                  href="https://ebz-akademie.de/weiterbildungsforderung/"
                  target="_blank"
                  rel="noopener"
                  class="flex items-center text-sm leading-none text-gray-700"
                >
                  Fördermöglichkeiten
                  <Icon name="new-tab" class="ml-1" />
                </a>
                <span
                  v-if="
                    selectedVeranstaltung.mabv_agent &&
                      vorlage.is_shop_item === 0
                  "
                  class="mt-1 text-sm text-gray-750"
                  >{{ selectedVeranstaltung.mabv_agent }} MaBV-Stunden
                  (Immobilienmakler)</span
                >
                <span
                  v-if="
                    selectedVeranstaltung.mabv_manager &&
                      vorlage.is_shop_item === 0
                  "
                  class="mt-1 text-sm text-gray-750"
                  >{{ selectedVeranstaltung.mabv_manager }} MaBV-Stunden
                  (Wohnimmobilienverwalter)</span
                >
                <span
                  v-if="
                    selectedVeranstaltung.aknw && vorlage.is_shop_item === 0
                  "
                  class="mt-1 text-sm text-gray-750"
                  >{{ selectedVeranstaltung.aknw }} AKNW-Stunden</span
                >
              </div>
              <div
                class="flex flex-col items-start justify-end pt-8 pb-6 lg:pt-0 lg:pl-8 print:hidden"
              >
                <button
                  v-scroll-to="{
                    el: '.downloads',
                    easing: 'ease-in-out',
                    duration: 1000
                  }"
                  class="button"
                >
                  <Icon name="arrow-down" class="mr-3 -ml-1" />zu den Downloads
                </button>
              </div>
              <div
                class="relative flex flex-col flex-wrap items-end justify-end pb-6 ml-auto sm:flex-row sm:flex-no-wrap lg:mt-0 print:mt-0 lg:pl-8 print:pl-0 print:ml-0 print:items-stretch"
              >
                <div
                  v-if="vorlage.inhouse_buchbar"
                  class="flex object-top items-start md:object-center md:items-center"
                >
                  <span class="ml-0 mr-2 pt-2 md:pt-0 md:mr-4 w-6 h-6">
                    <img
                      v-tooltip="
                        'Wir kommen zu Ihnen und beraten und schulen Ihr Team - vor Ort oder digital!<br /><br />Sie wählen das Thema und Format für eine individuelle Beratung oder Schulung.<br />Ihre Vorteile: flexibel, individuell, kosteneffizient und nachhaltig.<br />Lassen Sie uns über die Möglichkeiten sprechen, wir freuen uns auf Sie.<br />'
                      "
                      src="~/assets/img/info.svg"
                      class="w-6 h-6 tooltip-icon"
                    />
                  </span>
                  <nuxt-link
                    v-if="vorlage.inhouse_buchbar"
                    :to="
                      `/${vorlage.slug}/inhouse?termin=${selectedVeranstaltung.nummer}`
                    "
                    class="mb-4 button button--gradient sm:mb-0 sm:mr-4 print:-ml-5"
                  >
                    <Icon name="house" class="mr-3 -ml-1" />Inhouse Anfrage
                  </nuxt-link>
                </div>
                <nuxt-link
                  v-if="vorlage.externe_weiterbildung"
                  :to="
                    `/${vorlage.slug}/anfrage?termin=${selectedVeranstaltung.nummer}`
                  "
                  class="mb-4 button button--ghost sm:mb-0 sm:mr-4 print:-ml-5"
                >
                  <Icon name="business" class="mr-3 -ml-1" />Anfrage stellen
                </nuxt-link>
                <!-- TODO: Falls ausgebuchte Veranstaltung angeklickt, "Auf Anfrage" -->
                <div
                  v-if="
                    (selectedVeranstaltung &&
                      selectedVeranstaltung.leistungen &&
                      vorlage.externe_weiterbildung === 0 &&
                      lowestPriceGroup(selectedVeranstaltung.leistungen)) ||
                      vorlage.is_shop_item ||
                      vorlage.is_special_event === 1
                  "
                >
                  <a
                    v-if="selectedVeranstaltung.extern_url"
                    class="button button--green print:hidden"
                    :href="selectedVeranstaltung.extern_url"
                    >anmelden</a
                  >
                  <button
                    v-if="checkShopItem && !isInBasket"
                    :key="vorlage.nummer"
                    class="button button--green relative z-20"
                    @click="addEventToCart(vorlage)"
                  >
                    in den Warenkorb
                  </button>
                  <button
                    v-else-if="checkShopItem && isInBasket"
                    :key="vorlage.nummer"
                    class="button button--green relative z-20"
                    @click="removeEventFromCart(vorlage)"
                  >
                    im Warenkorb
                  </button>
                  <nuxt-link
                    v-else-if="
                      !checkShopItem && selectedVeranstaltung.extern_url === ''
                    "
                    :to="
                      `/${vorlage.slug}/anmeldung?termin=${selectedVeranstaltung.nummer}`
                    "
                    class="button button--green print:hidden"
                  >
                    anmelden
                  </nuxt-link>
                  <nuxt-link
                    v-if="basketItems > 0"
                    to="/shopping_cart"
                    class="button"
                  >
                    zum Warenkorb
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <CoronaHint
          v-if="vorlage.is_shop_item === 0"
          class="md:mt-8 lg:mt-12"
        /> -->
        <OnlineRequirementsMic
          v-if="
            selectedVeranstaltung.is_available_online &&
              vorlage.is_shop_item === 0
          "
          class="md:mt-4 lg:mt-6"
        />
        <OnlineRequirementsCam
          v-if="
            selectedVeranstaltung.is_available_online &&
              vorlage.is_shop_item === 0
          "
          class="md:mt-4 lg:mt-6"
        />
      </div>
      <div
        class="pb-12 mt-12 bg-white border-t print:mt-0 border-dark-gray-600"
      >
        <div
          class="container relative z-10 flex flex-col pt-16 content-wrapper lg:flex-row print:pt-4 print:block"
        >
          <div class="flex-grow w-full text-lg event-text-blocks text-gray-750">
            <div v-if="vorlage.texte && vorlage.is_special_event === 0">
              <TextBlock
                :text="getVeranstaltungsTextBlock('WICHTIGE_INFOS')"
                :highlight="true"
              />
              <TextBlock :text="getTextBlock('INH-EINF')" />
              <TextBlock :text="getTextBlock('INH_SW')" />
              <TextBlock :text="getTextBlock('VORTEILE')" />
              <TextBlock :text="getTextBlock('ZIELGRUPPE')" />
              <TextBlock :text="getTextBlock('VORR')" />
              <!-- <TextBlock
                v-if="vorlage.is_special_event === 1"
                :text="getVeranstaltungsTextBlock('TRAINER/-IN')"
              /> -->
              <TextBlock :text="getTextBlock('SONST')" />
            </div>
            <div v-if="vorlage.is_special_event === 1">
              <TextBlock
                :text="getTextBlock('WICHTIGE_INFOS')"
                :highlight="true"
              />
              <TextBlock :text="getTextBlock('VORTEILE')" />
              <TextBlock :text="getTextBlock('INH-EINF')" />
              <TextBlock :text="getTextBlock('INH_SW')" />
              <TextBlock :text="getTextBlock('VORTEILE')" />
              <TextBlock :text="getTextBlock('ZIELGRUPPE')" />
              <TextBlock :text="getTextBlock('VORR')" />
              <TextBlock :text="getTextBlock('TRAINER/-IN')" />
              <TextBlock :text="getTextBlock('SONST')" />
            </div>
            <div v-if="vorlage.is_shop_item === 1">
              <TextBlock
                :text="getTextBlock('WICHTIGE_INFOS')"
                :highlight="true"
              />
              <TextBlock :text="getVeranstaltungsTextBlock('Zielsetzung')" />
              <TextBlock :text="getVeranstaltungsTextBlock('Inhalte')" />
              <TextBlock :text="getVeranstaltungsTextBlock('Ihre Vorteile')" />
              <TextBlock :text="getVeranstaltungsTextBlock('Zielgruppe')" />
              <TextBlock :text="getVeranstaltungsTextBlock('Trainer')" />
              <TextBlock :text="getVeranstaltungsTextBlock('Sonstiges')" />
            </div>
            <!-- Module -->
            <div
              v-if="
                (module && module.length && vorlage.is_special_event === 0) ||
                  selectedVeranstaltung.kind === 'CON'
              "
              class="event-text-block"
            >
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Termine/Orte
              </h3>
              <div
                v-if="
                  module &&
                    module.length > 1 &&
                    selectedVeranstaltung.kind !== 'CON'
                "
                class="flex-grow"
              >
                <v-collapse-group :only-one-active="true">
                  <v-collapse-wrapper v-for="region in module" :key="region[0]">
                    <ToggleButton v-collapse-toggle>
                      <span v-if="region[0]" class="font-semibold">{{
                        region[0]
                      }}</span>
                      <span v-else class="italic">Keine Region angegeben</span>
                    </ToggleButton>
                    <div v-collapse-content class="print:block">
                      <div
                        v-for="modul in region[1]"
                        :key="modul.id"
                        class="pb-6 mb-8 text-sm leading-snug border-b border-gray-400 module"
                      >
                        <div class="flex flex-col mb-4">
                          <span
                            v-if="modul.end_datum"
                            class="text-dark-blue-800"
                          >
                            {{ modul.start_datum | moment('DD.MM.YYYY') }} -
                            {{ modul.end_datum | moment('DD.MM.YYYY') }}
                          </span>
                          <span v-else class="text-dark-blue-800">{{
                            modul.start_datum | moment('DD.MM.YYYY')
                          }}</span>
                          <span
                            v-if="modul.titel"
                            class="font-semibold text-dark-blue-900"
                          >
                            {{ modul.titel }}
                          </span>
                          <span v-if="modul.ort_name" class="mt-1">{{
                            modul.ort_name
                          }}</span>
                          <span v-if="modul.strasse">{{ modul.strasse }}</span>
                          <span>{{ modul.plz }} {{ modul.stadt }}</span>
                        </div>
                        <div v-if="modul.termine">
                          <span class="mt-3 mb-1 font-semibold text-gray-700"
                            >Termine/Orte</span
                          >
                          <ul>
                            <li
                              v-for="(termin, index) in modul.termine"
                              :key="index"
                              class="mb-2 text-gray-900"
                            >
                              <span class="inline-block">
                                {{ termin.wochentag }},
                                {{ termin.datum | moment('DD.MM.YYYY') }}
                              </span>
                              <div>
                                <span v-if="termin.zeit_von">
                                  {{
                                    [termin.zeit_von, 'HH:mm:ss']
                                      | moment('HH:mm')
                                  }}
                                </span>
                                <span v-if="termin.zeit_bis">
                                  -
                                  {{
                                    [termin.zeit_bis, 'HH:mm:ss']
                                      | moment('HH:mm')
                                  }}
                                </span>
                                <span v-if="termin.zeit_von || termin.zeit_bis">
                                  Uhr
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <ToggleIcon />
                  </v-collapse-wrapper>
                </v-collapse-group>
              </div>
              <v-collapse-group
                v-for="region in module"
                v-else-if="
                  module &&
                    module.length === 1 &&
                    selectedVeranstaltung.kind !== 'CON'
                "
                :key="region[0]"
                :only-one-active="true"
                class="flex-1 px-4 md:px-8 lg:px-0"
              >
                <v-collapse-wrapper
                  v-for="modul in region[1]"
                  :key="modul.id"
                  class="text-sm leading-snug"
                >
                  <ToggleButton v-collapse-toggle>
                    <div class="flex flex-col">
                      <span v-if="modul.end_datum" class="text-dark-blue-800">
                        {{ modul.start_datum | moment('DD.MM.YYYY') }} -
                        {{ modul.end_datum | moment('DD.MM.YYYY') }}
                      </span>
                      <span v-else class="text-dark-blue-800">{{
                        modul.start_datum | moment('DD.MM.YYYY')
                      }}</span>
                      <span
                        v-if="modul.titel"
                        class="font-semibold text-dark-blue-900"
                        >{{ modul.titel }}</span
                      >
                    </div>
                  </ToggleButton>
                  <div v-collapse-content>
                    <div class="flex flex-col mb-4">
                      <span v-if="modul.ort_name" class="mt-1">{{
                        modul.ort_name
                      }}</span>
                      <span v-if="modul.strasse">{{ modul.strasse }}</span>
                      <span>{{ modul.plz }} {{ modul.stadt }}</span>
                    </div>
                    <div v-if="modul.termine">
                      <span class="mt-3 mb-1 font-semibold text-gray-700"
                        >Termine/Orte</span
                      >
                      <ul>
                        <li
                          v-for="(termin, index) in modul.termine"
                          :key="index"
                          class="mb-2 text-gray-900"
                        >
                          <span class="inline-block">
                            {{ termin.wochentag }},
                            {{ termin.datum | moment('DD.MM.YYYY') }}
                          </span>
                          <div>
                            <span v-if="termin.zeit_von">
                              {{
                                [termin.zeit_von, 'HH:mm:ss'] | moment('HH:mm')
                              }}
                            </span>
                            <span v-if="termin.zeit_bis">
                              -
                              {{
                                [termin.zeit_bis, 'HH:mm:ss'] | moment('HH:mm')
                              }}
                            </span>
                            <span v-if="termin.zeit_von || termin.zeit_bis"
                              >Uhr</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ToggleIcon />
                </v-collapse-wrapper>
              </v-collapse-group>
              <!-- <div
                  v-for="modul in region[1]"
                  :key="modul.id"
                  class="pb-6 mb-8 text-sm leading-snug border-b border-gray-400 module"
                >
              </div>-->
              <div v-else-if="selectedVeranstaltung.kind === 'CON'">
                <div
                  class="pb-6 mb-8 text-sm leading-snug border-b border-gray-400 module"
                >
                  <div class="flex flex-col mb-4">
                    <span
                      v-if="selectedVeranstaltung.end_datum"
                      class="text-dark-blue-800"
                    >
                      {{
                        selectedVeranstaltung.start_datum | moment('DD.MM.YYYY')
                      }}
                      -
                      {{
                        selectedVeranstaltung.end_datum | moment('DD.MM.YYYY')
                      }}
                    </span>
                    <span v-else class="text-dark-blue-800">{{
                      selectedVeranstaltung.start_datum | moment('DD.MM.YYYY')
                    }}</span>
                    <span
                      v-if="selectedVeranstaltung.titel"
                      class="font-semibold text-dark-blue-900"
                    >
                      {{ selectedVeranstaltung.titel }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                vorlage.is_special_event === 1 &&
                  spezialModule &&
                  spezialModule.length
              "
              class="event-text-block"
            >
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Termine/Orte
              </h3>
              <div
                v-if="spezialModule && spezialModule.length > 1"
                class="flex-grow"
              >
                <v-collapse-group :only-one-active="true">
                  <v-collapse-wrapper
                    v-for="modul in spezialModule"
                    :key="modul.id"
                  >
                    <ToggleButton v-collapse-toggle>
                      <span class="font-semibold">{{ modul.titel }}</span>
                    </ToggleButton>
                    <div v-collapse-content class="print:block">
                      <div
                        :key="modul.id"
                        class="pb-6 mb-2 text-sm leading-snug border-b border-gray-400 module"
                      >
                        <div class="flex flex-col">
                          <span
                            v-if="modul.end_datum"
                            class="text-dark-blue-800"
                          >
                            {{ modul.start_datum | moment('DD.MM.YYYY') }} -
                            {{ modul.end_datum | moment('DD.MM.YYYY') }}
                          </span>
                          <span v-else class="text-dark-blue-800">{{
                            modul.start_datum | moment('DD.MM.YYYY')
                          }}</span>
                          <span
                            v-if="modul.titel"
                            class="font-semibold text-dark-blue-900"
                          >
                            {{ modul.titel }}
                          </span>
                          <span v-if="modul.ort_name" class="mt-1">{{
                            modul.ort_name
                          }}</span>
                          <span v-if="modul.strasse">{{ modul.strasse }}</span>
                          <span>{{ modul.plz }} {{ modul.stadt }}</span>
                        </div>
                      </div>
                    </div>
                    <ToggleIcon />
                  </v-collapse-wrapper>
                </v-collapse-group>
              </div>
              <v-collapse-group
                v-for="modul in spezialModule"
                v-else-if="spezialModule && spezialModule.length === 1"
                :key="modul.id"
                :only-one-active="true"
                class="flex-1 px-4 md:px-8 lg:px-0"
              >
                <v-collapse-wrapper
                  :key="modul.id"
                  class="text-sm leading-snug"
                >
                  <ToggleButton v-collapse-toggle>
                    <div class="flex flex-col">
                      <span v-if="modul.end_datum" class="text-dark-blue-800">
                        {{ modul.start_datum | moment('DD.MM.YYYY') }} -
                        {{ modul.end_datum | moment('DD.MM.YYYY') }}
                      </span>
                      <span v-else class="text-dark-blue-800">{{
                        modul.start_datum | moment('DD.MM.YYYY')
                      }}</span>
                      <span
                        v-if="modul.titel"
                        class="font-semibold text-dark-blue-900"
                        >{{ modul.titel }}</span
                      >
                    </div>
                  </ToggleButton>
                  <div v-collapse-content>
                    <div class="flex flex-col mb-4">
                      <span v-if="modul.ort_name" class="mt-1">{{
                        modul.ort_name
                      }}</span>
                      <span v-if="modul.strasse">{{ modul.strasse }}</span>
                      <span>{{ modul.plz }} {{ modul.stadt }}</span>
                    </div>
                    <div v-if="modul.termine">
                      <span class="mt-3 mb-1 font-semibold text-gray-700"
                        >Termine/Orte</span
                      >
                      <ul>
                        <li
                          v-for="(termin, index) in modul.termine"
                          :key="index"
                          class="mb-2 text-gray-900"
                        >
                          <span class="inline-block">
                            {{ termin.wochentag }},
                            {{ termin.datum | moment('DD.MM.YYYY') }}
                          </span>
                          <div>
                            <span v-if="termin.zeit_von">
                              {{
                                [termin.zeit_von, 'HH:mm:ss'] | moment('HH:mm')
                              }}
                            </span>
                            <span v-if="termin.zeit_bis">
                              -
                              {{
                                [termin.zeit_bis, 'HH:mm:ss'] | moment('HH:mm')
                              }}
                            </span>
                            <span v-if="termin.zeit_von || termin.zeit_bis"
                              >Uhr</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ToggleIcon />
                </v-collapse-wrapper>
              </v-collapse-group>
            </div>
            <div
              v-if="
                selectedVeranstaltung.termine &&
                  selectedVeranstaltung.termine.length !== 0 &&
                  !vorlage.is_special_event &&
                  selectedVeranstaltung.kind !== 'CON'
              "
              class="event-text-block"
            >
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Termine/Orte
              </h3>
              <v-collapse-group
                :only-one-active="true"
                class="flex-1 px-4 md:px-8 lg:px-0"
              >
                <v-collapse-wrapper
                  v-for="(termin, index) in selectedVeranstaltung.termine"
                  :key="index"
                >
                  <ToggleButton v-collapse-toggle>
                    <span class="font-semibold">
                      {{ termin.wochentag }},
                      {{ termin.datum | moment('DD.MM.YYYY') }}
                    </span>
                  </ToggleButton>
                  <div v-collapse-content>
                    <div class="text-sm font-semibold">
                      <span v-if="termin.zeit_von">
                        {{ [termin.zeit_von, 'HH:mm:ss'] | moment('HH:mm') }}
                      </span>
                      <span v-if="termin.zeit_bis">
                        -
                        {{ [termin.zeit_bis, 'HH:mm:ss'] | moment('HH:mm') }}
                      </span>
                      <span v-if="termin.zeit_von || termin.zeit_bis">Uhr</span>
                    </div>
                    <div class="flex flex-col mt-2 leading-snug">
                      <span v-if="selectedVeranstaltung.ort_name">{{
                        selectedVeranstaltung.ort_name
                      }}</span>
                      <span v-if="selectedVeranstaltung.strasse">{{
                        selectedVeranstaltung.strasse
                      }}</span>
                      <span
                        v-if="
                          selectedVeranstaltung.plz ||
                            selectedVeranstaltung.stadt
                        "
                      >
                        {{ selectedVeranstaltung.plz }}
                        {{ selectedVeranstaltung.stadt }}
                      </span>
                    </div>
                  </div>
                  <ToggleIcon />
                </v-collapse-wrapper>
              </v-collapse-group>
            </div>
            <!-- Moderator -->
            <div
              v-if="moderatoren && moderatoren.length"
              class="event-text-block"
            >
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Moderator/-in
              </h3>
              <div>
                <ul>
                  <li
                    v-for="moderator in moderatoren"
                    :key="moderator.name"
                    class="mb-6"
                    itemprop="performer"
                    itemscope
                    itemtype="http://schema.org/Person"
                  >
                    <div class="inline-flex flex-col">
                      <span itemprop="name" class="font-semibold">
                        <span
                          v-if="moderator.basis && moderator.basis.job_titel"
                          >{{ moderator.basis.job_titel }}</span
                        >
                        {{ moderator.name }}
                      </span>
                      <div
                        v-if="moderator.basis.beschreibung"
                        v-html="moderator.basis.beschreibung"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Trainer -->
            <div
              v-if="dozenten && dozenten.length && vorlage.is_shop_item === 0"
              class="event-text-block"
            >
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Trainer/-in
              </h3>
              <div>
                <ul>
                  <li
                    v-for="dozent in dozenten"
                    :key="dozent.name"
                    class="mb-6"
                    itemprop="performer"
                    itemscope
                    itemtype="http://schema.org/Person"
                  >
                    <div class="inline-flex flex-col">
                      <span itemprop="name" class="font-semibold">
                        <span v-if="dozent.basis && dozent.basis.job_titel">
                          {{ dozent.basis.job_titel }}
                        </span>
                        {{ dozent.name }}
                      </span>
                      <div
                        v-if="dozent.basis.beschreibung"
                        v-html="dozent.basis.beschreibung"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <TextBlock :text="getTextBlock('BULLSHIT')" /> -->
            <div class="print:hidden event-text-block downloads">
              <h3 class="flex-shrink-0 mb-4 text-gray-800 xxl:w-64 xxl:mr-16">
                Downloads
              </h3>
              <div class="flex flex-col">
                <div class="static-downloads">
                  <div class="-mt-2 download">
                    <Icon
                      name="info"
                      class="flex-shrink-0 mr-3 text-blue-700"
                    />
                    <a
                      href="/pdf/AK_FAQ_Allgemein_A4_200210.pdf"
                      class="flex-col print:flex"
                      target="_blank"
                      @click="clickFaq"
                    >
                      FAQs
                      <span class="text-xs font-semibold whitespace-no-wrap">
                        <!-- ({{ download.fileSize }}) -->
                      </span>
                    </a>
                  </div>
                  <div v-if="vorlage.is_shop_item === 0" class="download">
                    <Icon
                      name="info"
                      class="flex-shrink-0 mr-3 text-blue-700"
                    />
                    <a
                      href="/pdf/Veranstaltungsticket_DB_2024.pdf"
                      class="flex-col print:flex"
                      target="_blank"
                      @click="clickRailTicket"
                    >
                      Deutsche Bahn Veranstaltungsticket 2024
                      <!-- <span class="text-xs font-semibold whitespace-no-wrap">
                        ({{ download.fileSize }})
                      </span>-->
                    </a>
                  </div>
                </div>
                <div v-if="downloads && downloads.length > 0" class="mt-4">
                  <div
                    v-for="download in downloads"
                    :key="download.id"
                    class="download"
                  >
                    <!-- <div class="download"> -->
                    <Icon
                      name="attachment"
                      class="flex-shrink-0 mr-3 text-blue-700"
                    />
                    <a
                      :href="download.datei"
                      class="flex-col print:flex"
                      target="_blank"
                      @click="clickDownload"
                    >
                      {{ download.beschreibung }}
                      <span class="text-xs font-semibold whitespace-no-wrap">
                        <!-- ({{ download.fileSize }}) -->
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <aside
            class="flex flex-col items-start flex-shrink-0 mt-0 font-semibold lg:ml-40 lg:w-48 print:w-full print:flex-row"
          >
            <div
              class="flex flex-col flex-shrink-0 print:flex-row print:no-page-break print:mt-8"
            >
              <!-- Focus 2020
              <div
                v-if="
                  selectedVeranstaltung.art.code === 'ZL' ||
                    selectedVeranstaltung.art.code === 'QP'
                "
                class="w-48 mb-8 print:w-20"
              >
                <img
                  src="~/assets/img/focus_2023.png"
                  alt="Focus Top Anbieter für Weiterbildung 2023"
                />
              </div>
              -->
              <div
                v-if="
                  selectedVeranstaltung && selectedVeranstaltung.sachbearbeiter
                "
                itemprop="organizer"
                itemscope
                itemtype="http://schema.org/Person"
                class="lg:max-w-48 print:max-w-full print:w-full print:flex"
              >
                <div class="flex-col print:flex-row print:w-56 print:mx-6">
                  <!-- Für Fragen steht Ihnen.... -->
                  <div class="flex flex-col">
                    <span
                      class="inline-block max-w-sm mb-3 text-sm text-gray-750 hyphenate"
                    >
                      Für Fragen steht Ihnen gerne die zentrale Bildungsberatung
                      zur Verfügung.
                    </span>
                    <span class="inline-block mb-3 text-sm text-gray-750">
                      Sie erreichen uns:
                      <br />Mo. - Fr.: 10:00 - 16:00 Uhr
                    </span>
                  </div>
                  <!-- Veranstaltungsbezogene Ansprechpartner-Daten -->
                  <div class="mb-8">
                    <p class="text-sm">
                      <a :href="`tel:+492349447595`" itemprop="telephone"
                        >+49 234 9447595</a
                      >
                      <span itemprop="telephone" class="hidden"
                        >+49 234 9447595</span
                      >
                    </p>
                    <a
                      :href="
                        `mailto:${selectedVeranstaltung.sachbearbeiter.email}`
                      "
                      class="mt-2 text-sm"
                      >{{ selectedVeranstaltung.sachbearbeiter.email }}</a
                    >
                    <span itemprop="email" class="hidden">{{
                      selectedVeranstaltung.sachbearbeiter.email
                    }}</span>
                  </div>
                </div>
                <div class="flex-col print:flex-row print:w-56 print:mx-6">
                  <!-- Für Fragen steht Ihnen.... -->
                  <div class="flex flex-col">
                    <span
                      class="inline-block max-w-sm mb-3 text-sm text-gray-750 hyphenate"
                    >
                      Für organisatorische Fragen steht Ihnen gerne unser
                      Verwaltungsmanagement zur Verfügung.
                    </span>
                    <span class="inline-block mb-3 text-sm text-gray-750">
                      Sie erreichen uns:
                      <br />Mo. - Do.: 08:00 - 17:00 Uhr <br />Fr.: 08:00 -
                      16:00 Uhr
                    </span>
                  </div>
                  <!-- Veranstaltungsbezogene Ansprechpartner-Daten -->
                  <div class="mb-8">
                    <p class="text-sm">
                      <a :href="`tel:+492349447575`" itemprop="telephone"
                        >+49 234 9447575</a
                      >
                      <span itemprop="telephone" class="hidden"
                        >+49 234 9447575</span
                      >
                    </p>
                    <a
                      :href="
                        `mailto:${selectedVeranstaltung.sachbearbeiter.email}`
                      "
                      class="mt-2 text-sm"
                      >{{ selectedVeranstaltung.sachbearbeiter.email }}</a
                    >
                    <span itemprop="email" class="hidden">{{
                      selectedVeranstaltung.sachbearbeiter.email
                    }}</span>
                  </div>
                </div>
                <!-- Ansprechpartner – Bild + Name -->
                <div
                  v-if="vorlage.is_special_event === 0"
                  class="flex flex-col print:mr-6"
                >
                  <span class="mb-4 leading-snug text-gray-750">
                    Ihr Ansprechpartner
                    <br />vor Ort
                  </span>
                  <img
                    :src="selectedVeranstaltung.sachbearbeiter.bild"
                    :alt="selectedVeranstaltung.sachbearbeiter.name"
                    itemprop="image"
                    class="contact__image"
                  />
                  <div class="flex flex-col">
                    <span itemprop="name" class="mt-0 mt-4 print:mt-0">{{
                      selectedVeranstaltung.sachbearbeiter.name
                    }}</span>
                    <span class="mb-2 text-sm leading-none text-gray-750">
                      {{
                        selectedVeranstaltung.sachbearbeiter
                          .positions_beschreibung
                      }}
                    </span>
                    <a
                      v-if="
                        selectedVeranstaltung.sachbearbeiter.infobox !== null
                      "
                      ref="noopener"
                      :href="selectedVeranstaltung.sachbearbeiter.infobox"
                      target="_blank"
                      class="button button--green button-b print:hidden"
                    >
                      Buchen Sie jetzt einen unverbindlichen Beratungstermin!
                    </a>
                  </div>
                </div>
                <div
                  v-else-if="
                    vorlage.is_special_event === 1 &&
                      vorlage.veranstaltungen[0].sachbearbeiter !== null
                  "
                  class="flex flex-col print:mr-6"
                >
                  <span class="mb-4 leading-snug text-gray-750">
                    Ihr Ansprechpartner
                    <br />vor Ort
                  </span>
                  <img
                    v-if="
                      vorlage.veranstaltungen[0].sachbearbeiter.bild &&
                        vorlage.veranstaltungen[0].sachbearbeiter.bild !==
                          null &&
                        vorlage.veranstaltungen[0].sachbearbeiter.bild !== ''
                    "
                    :src="vorlage.veranstaltungen[0].sachbearbeiter.bild"
                    :alt="vorlage.veranstaltungen[0].sachbearbeiter.name"
                    itemprop="image"
                    class="contact__image"
                  />
                  <div class="flex flex-col">
                    <span itemprop="name" class="mt-0 mt-4 print:mt-0">{{
                      vorlage.veranstaltungen[0].sachbearbeiter.name
                    }}</span>
                    <span class="mb-2 text-sm leading-none text-gray-750">
                      {{
                        vorlage.veranstaltungen[0].sachbearbeiter
                          .positions_beschreibung
                      }}
                    </span>
                    <a
                      v-if="
                        vorlage.veranstaltungen[0].sachbearbeiter.infobox !==
                          null
                      "
                      ref="noopener"
                      :href="vorlage.veranstaltungen[0].sachbearbeiter.infobox"
                      target="_blank"
                      class="button button--green button-b print:hidden"
                    >
                      Buchen Sie jetzt einen unverbindlichen Beratungstermin!
                    </a>
                  </div>
                </div>
                <div
                  v-if="selectedVeranstaltung && selectedVeranstaltung.ort_name"
                >
                  <h3
                    class="mt-6 mb-2 whitespace-no-wrap text-regular print:mt-0"
                  >
                    Ort
                  </h3>
                  <div
                    itemscope
                    itemtype="http://schema.org/Place"
                    itemprop="location"
                    class="text-sm not-italic font-normal text-gray-750"
                  >
                    <span itemprop="name">{{
                      selectedVeranstaltung.ort_name
                    }}</span>
                    <div
                      itemprop="address"
                      itemscope
                      itemtype="http://schema.org/PostalAddress"
                      class="text-sm not-italic font-normal text-gray-750"
                    >
                      <span itemprop="streetAddress">{{
                        selectedVeranstaltung.strasse
                      }}</span>
                      <br />
                      <span itemprop="postalCode">{{
                        selectedVeranstaltung.plz
                      }}</span>
                      <span itemprop="addressLocality">{{
                        selectedVeranstaltung.stadt
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="badges">
              <img
                v-for="logo in selectedVeranstaltung.partnerlogos"
                :key="logo.code"
                :src="getSanitizedLogo(logo.code)"
                :alt="logo.beschreibung"
              />
            </div>
          </aside>
        </div>
      </div>
    </section>
    <Loader v-else />
    <ShoppingCartHelper />
    <BasketPopup />
  </div>
</template>
<script>
/* global fbq */
// import VueScrollClass from 'vue-scroll-class'
import { mapGetters } from 'vuex'
import _, {
  filter,
  head,
  orderBy,
  groupBy,
  sortedUniq,
  uniqWith,
  flattenDeep
} from 'lodash'
import queryString from 'query-string'
import ToggleButton from '~/components/Accordion/ToggleButton'
import ToggleIcon from '~/components/Accordion/ToggleIcon'
import ShareIcons from '~/components/ShareIcons'
import DateCard from '~/components/cards/DateCard'
import Icon from '~/components/Icon'
import Loader from '~/components/Loader'
import TextBlock from '~/components/blocks/TextBlock'
// import CoronaHint from '~/components/CoronaHint'
import OnlineRequirementsMic from '~/components/OnlineRequirementsMic'
import OnlineRequirementsCam from '~/components/OnlineRequirementsCam'
import ShoppingCartHelper from '~/components/ShoppingCartHelper'
import EventBus from '~/events/EventBus'
import BasketPopup from '~/components/BasketPopup'
export default {
  middleware: 'ensure-va-selected',
  scrollToTop: true,
  watchQuery: ['selectedEvent'],
  components: {
    BasketPopup,
    ToggleButton,
    ToggleIcon,
    ShareIcons,
    DateCard,
    Icon,
    Loader,
    TextBlock,
    // CoronaHint,
    OnlineRequirementsMic,
    OnlineRequirementsCam,
    ShoppingCartHelper
  },
  head() {
    return {
      title:
        (this.vorlage && `${this.vorlage.titel} — EBZ Bildungsfinder`) ||
        'EBZ Bildungsfinder',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content:
            (this.vorlage &&
              this.vorlage.produktart &&
              this.vorlage.titel &&
              this.selectedVeranstaltung &&
              this.selectedVeranstaltung.region &&
              `${this.vorlage.produktart} ${this.vorlage.titel} (${this.selectedVeranstaltung.region}) bei der Akademie des Europäischen Bildungszentrums (EBZ)`) ||
            'Die Akademie des Europäischen Bildungszentrums (EBZ) bietet Aus-, Fort- und Weiterbildungsangebote für die Wohnungs- und Immobilienwirtschaft an.'
        },
        {
          hid: 'robots',
          name: 'robots',
          content: `index,follow`
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.getKeywords || ''
        }
      ],
      link: [
        {
          rel: 'canonical',
          href:
            this.vorlage &&
            this.vorlage.slug &&
            this.firstVeranstaltung &&
            this.firstVeranstaltung.nummer &&
            `https://www.ebz-training.de/${this.vorlage.slug}?termin=${this.firstVeranstaltung.nummer}`
        }
      ]
    }
  },
  // directives: {
  //   'scroll-class': VueScrollClass
  // },
  data() {
    return {
      host: null
    }
  },
  computed: {
    ...mapGetters({
      getVorlageBySlug: 'veranstaltungsvorlagen/getVorlageBySlug',
      itemExist: 'cart/exist'
    }),
    checkShopItem() {
      if (this.vorlage.is_special_event === 1) {
        return (
          this.hasPriceGroups &&
          ((this.vorlage.is_shop_item && this.vorlage.shop_price > 0) ||
            this.lowestPrice > 0 ||
            this.lowestPrice === 0)
        )
      }
      if (this.vorlage.is_shop_item === 1) {
        return (
          this.hasMorePriceGroups === false &&
          ((this.vorlage.is_shop_item && this.vorlage.shop_price > 0) ||
            this.lowestPrice > 0 ||
            this.lowestPrice === 0)
        )
      }
      return (
        this.hasMorePriceGroups === false &&
        this.selectedVeranstaltung.extern_url === '' &&
        (this.lowestPrice > 0 || this.lowestPrice === 0)
      )
    },
    isInBasket() {
      return this.itemExist(this.vorlage.nummer)
    },
    basketItems() {
      const items = this.$store.getters['cart/items']
      return items.length
    },
    hasPriceGroups() {
      if (this.vorlage.is_special_event) {
        return true
      }
      const leistungen = this.vorlage.veranstaltungen
        .map((va) => va.leistungen)
        .filter((l) => l.length > 1)
      return leistungen.length > 0
    },
    hasMorePriceGroups() {
      if (this.vorlage.is_special_event) {
        return true
      }
      const leistungen = this.vorlage.veranstaltungen
        .map((va) => va.leistungen)
        .filter((l) => l.length > 1)
      if (leistungen.length === 0) {
        return false
      }
      return leistungen[0].length > 1
    },
    lowestPrice() {
      if (this.vorlage.is_shop_item) {
        return this.vorlage.shop_price
      }
      if (this.vorlage.is_special_event === 1) {
        return this.getSpecialEventPriceGroup()
      }
      const group = this.selectedVeranstaltung.leistungen[0]
      if (group) {
        return group.preis_lcy_net
      }
      return 0
    },
    getKeywords() {
      const addwords =
        this.vorlage &&
        this.vorlage.texte &&
        head(filter(this.vorlage.texte, (text) => text.textart === 'ADDWORDS'))
      if (!addwords || !addwords.text) return
      return addwords.text
    },
    isLoading() {
      return this.$store.state.veranstaltungsvorlagen.isLoading
    },
    isShopItem() {
      return this.vorlage.is_shop_item === 1
    },
    vorlage() {
      return this.getVorlageBySlug(this.$route.params.slug)
    },
    selectedVeranstaltung() {
      const termin = this.$route.query.termin
      if (this.vorlage.is_special_event === 1) {
        return this.vorlage.veranstaltungen[0].module
          .filter((va) => va.nummer === termin)
          .shift()
      }
      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    },
    firstVeranstaltung() {
      if (this.vorlage.is_special_event === 1) {
        const va = this.vorlage.veranstaltungen[0].module
        return head(va)
      }
      const va = this.vorlage.veranstaltungen
      return head(va)
    },
    spezialModule() {
      return this.vorlage.veranstaltungen[0].module
    },
    downloads() {
      if (this.vorlage.is_special_event === 1) {
        return this.vorlage.veranstaltungen[0].downloads
      }
      return this.selectedVeranstaltung && this.selectedVeranstaltung.downloads
    },
    module() {
      const module =
        this.selectedVeranstaltung && this.selectedVeranstaltung.module
      const moduleObject = groupBy(
        orderBy(module, ['region'], ['asc']),
        'region'
      )
      return Object.keys(moduleObject).map((key) => [key, moduleObject[key]])
    },
    dozenten() {
      const typ = this.selectedVeranstaltung.type
      let dozenten = []
      if (typ === 'lehrgang') {
        dozenten =
          this.selectedVeranstaltung &&
          this.selectedVeranstaltung.module &&
          this.selectedVeranstaltung.module.map((m) => m.dozenten)
        dozenten = flattenDeep(dozenten)
      } else {
        dozenten =
          this.selectedVeranstaltung && this.selectedVeranstaltung.dozenten
      }
      dozenten = dozenten.filter((m) => m.moderator === 0)
      dozenten = uniqWith(
        dozenten,
        (a, b) => a.kurs_leiter_nummer === b.kurs_leiter_nummer
      )
      return sortedUniq(orderBy(dozenten, ['name'], ['asc']))
    },
    moderatoren() {
      const dozenten =
        this.selectedVeranstaltung &&
        this.selectedVeranstaltung.dozenten &&
        this.selectedVeranstaltung.dozenten.filter((m) => m.moderator === 1)
      return sortedUniq(orderBy(dozenten, ['name'], ['asc']))
    },
    backUrl() {
      const filter = this.$store.state.filter
      const params = {
        page:
          this.$store.state.veranstaltungsvorlagen.meta.current_page || null,
        q: filter.q,
        online: filter.online || null,
        inhouse: filter.inhouse || null,
        mabvAgent: filter.mabvAgent || null,
        mabvManager: filter.mabvManager || null,
        t: filter.selectedThemen.map((i) => i.code),
        b: filter.selectedBranchen.map((i) => i.code),
        r: filter.selectedRegionen.map((i) => i.code),
        a: filter.selectedVeranstaltungsarten.map((i) => i.code),
        start: filter.start,
        end: filter.end
      }
      const stringified = queryString.stringify(params)
      return `/?${stringified}`
    }
  },
  asyncData(context) {
    let host = ''
    if (context.req) {
      let proto = context.req.connection.encrypted ? 'https' : 'http'
      // only do this if you trust the proxy
      proto = context.req.headers['x-forwarded-proto'] || proto
      proto = proto.split(/\s*,\s*/)[0]
      host = `${proto}://${context.req.headers.host}`
    } else {
      host = window.location.origin
    }
    return {
      host
    }
  },
  async fetch({ store, params }) {
    await store.dispatch('veranstaltungsvorlagen/get', params.slug)
  },
  mounted() {
    if (typeof fbq !== 'undefined') {
      const track = {
        value: 0,
        currency: 'EUR',
        content_ids: this.selectedVeranstaltung.nummer ?? null,
        content_type: 'product'
      }
      fbq('track', 'ViewContent', track)
    }
  },
  methods: {
    getShopItemPriceClass() {
      if (this.vorlage.is_shop_item === 1) {
        return 'flex flex-col pb-8 pr-8 border-b border-gray-500 lg:pb-0 print:border-b-0 print:border-white lg:border-b-0 print:mr-8'
      }
      return 'flex flex-col pb-8 pr-8 border-b border-gray-500 lg:pb-0 lg:border-r print:border-r print:border-b-0 print:border-white lg:border-b-0 print:mr-8'
    },
    addEventToCart(vorlage) {
      const isSpecial = this.vorlage.is_special_event !== 0
      const event = isSpecial
        ? this.vorlage.veranstaltungen[0]
        : this.selectedVeranstaltung
      const item = {
        ...event,
        shop_duration: this.vorlage.shop_duration,
        shop_price: this.vorlage.shop_price,
        shop_nummer: this.vorlage.nummer,
        ...(isSpecial
          ? { start_datum: this.selectedVeranstaltung.start_datum }
          : {}),
        vorlage: this.vorlage
      }
      this.$store.commit('cart/add', item)
      this.$store.commit('cart/addNumber', vorlage.nummer)
      EventBus.$emit('basket:item-added', item)
    },
    removeEventFromCart(vorlage) {
      this.$store.commit('cart/remove', {
        nummer: vorlage.nummer
      })
    },
    clickFaq(e) {
      try {
        this.$gtag.event('click', {
          event_category: 'Product FAQ',
          event_label: e.target.getAttribute('href')
        })
      } catch (e) {}
    },
    clickDownload(e) {
      try {
        this.$gtag.event('click', {
          event_category: 'Product Downloads',
          event_label: e.target.getAttribute('href')
        })
      } catch (e) {}
    },
    clickRailTicket(e) {
      try {
        this.$gtag.event('click', {
          event_category: 'Product RailTicket',
          event_label: e.target.getAttribute('href')
        })
      } catch (e) {}
    },
    log(x) {
      console.log(x)
    },
    getSanitizedLogo(code) {
      code = code.replace(/\u00DC/g, 'UE') // Ü
      code = code.replace(/\u00C4/g, 'AE') // Ä
      code = code.replace(/\u00D6/g, 'OE') // Ö
      code = code.replace(/\s/g, '_')
      const path = `/img/sidebar/${code}.png`
      return path
    },
    updateSelectedCardId(nummer) {
      this.updateQueryParam(nummer)
    },
    updateQueryParam(nummer) {
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, {
          termin: nummer
        })
      })
    },
    getTextBlock(textart) {
      return head(
        filter(this.vorlage.texte, (text) => text.textart === textart)
      )
    },
    getVeranstaltungsTextBlock(textart) {
      if (this.vorlage.is_special_event === 1) {
        const textblock = head(
          filter(
            this.vorlage.veranstaltungen[0].veranstaltungtexte,
            (text) => text.textart === textart
          )
        )
        return textblock
      }
      const textblock = head(
        filter(
          this.selectedVeranstaltung.veranstaltungtexte,
          (text) => text.textart === textart
        )
      )
      return textblock
    },
    lowestPriceGroup(leistungen) {
      const lowestPrice = _(leistungen)
        .flattenDeep()
        .sortBy('preis_lcy_net')
        .head()
      return lowestPrice
    },
    getSpecialEventPriceGroup() {
      const priceGroup = _(this.vorlage.veranstaltungen[0].leistungen)
        .filter((p) => p.preisgruppencode === 'SP8')
        .head()
      return priceGroup.preis_lcy_net
    }
  }
}
</script>
<style lang="postcss">
.date-cards {
  @apply flex flex-wrap items-start -mx-4 mt-6 mb-12 border border-gray-100 pb-4;
}
@media (pointer: coarse) and (min-width: 768px) {
  .date-cards {
    @apply -mx-8;
  }
}
@media (pointer: coarse) {
  .date-cards {
    @apply overflow-auto flex-no-wrap;
    scroll-snap-type: x mandatory;
    width: 100vw;
    -webkit-overflow-scrolling: touch;
  }
  .date-cards::after {
    content: '';
    display: block;
    position: static;
    width: 0.02rem;
    height: 1rem;
    flex-shrink: 0;
  }
}
.event-text-blocks {
  line-height: 1.61;
}
.event-text-block {
  @apply mb-12 flex flex-col;
}
.module:last-of-type {
  @apply border-b-0 pb-0;
}
@screen xxl {
  .event-text-block {
    @apply flex-row;
  }
}
.event-text-block ul li {
  @apply pl-2 ml-6;
  list-style-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA1IDgiIHdpZHRoPSI1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0xLjcxIDcuMjg5OTcgMi41OS0yLjU5Yy4zOS0uMzkuMzktMS4wMiAwLTEuNDFsLTIuNTktMi41ODk5OTljLS42My0uNjE5OTk5OC0xLjcxLS4xOC0xLjcxLjcwOTk5OXY1LjE3YzAgLjkgMS4wOCAxLjM0IDEuNzEuNzF6IiBmaWxsPSIjMjg3ZmMxIi8+PC9zdmc+);
}
.static-downloads :last-of-type::after {
  content: none;
}
.download {
  @apply flex items-center py-4 relative;
}
.download:not(:last-of-type)::after {
  content: '';
  @apply w-full bg-gray-400 absolute left-0;
  bottom: 0;
  max-width: 23rem;
  height: 1px;
}
.contact__image {
  @apply border-5 border-white shadow-blue object-cover pointer-events-none select-none;
  box-sizing: content-box;
  width: 9.375rem;
  height: 12.5rem;
}
.badges {
  @apply flex flex-wrap items-center mt-12 w-full;
}
.badges img {
  @apply mb-8 mr-8;
}
@screen lg {
  .badges {
    @apply justify-between;
  }
  .badges img {
    @apply mb-6 mr-0;
  }
}
</style>