<template>
  <div>
    <div v-if="isCompany">
      <h3 class="mb-6">Unternehmen</h3>
      <div class="flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="company"
            type="text"
            name="company"
            :class="{
              Input: true,
              'Input--Error': $v.company.$error
            }"
            required
            @input="$v.company.$touch()"
          />
          <label for="company" class="flex">
            Name des Unternehmens (max. 100 Zeichen)
            <span class="ml-2 w-6 h-6">
              <img
                v-tooltip="'Achten Sie bitte auf die korrekte Schreibweise'"
                src="~/assets/img/info.svg"
                class="w-6 h-6 tooltip-icon"
              />
            </span>
          </label>
        </div>
        <!-- <div class="InputContainer w-full md:w-1/3 ml-3">
          <input
            v-model.trim="companyNumber"
            type="text"
            name="companyNumber"
            :class="{
              Input: true,
              'Input--Error': $v.companyNumber.$error
            }"
            @input="$v.companyNumber.$touch()"
          />
          <label for="company">Kontaktnummer des Unternehmen</label>
        </div> -->
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyContactFirstName"
            type="text"
            name="companyContactFirstName"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactFirstName.$error
            }"
            @input="$v.companyContactFirstName.$touch()"
          />
          <label for="companyContactFirstName" class="flex">
            Vorname des Ansprechpartners
            <span class="ml-2 w-6 h-6">
              <img
                v-tooltip="'Nur ausfüllen wenn abweichend vom Teilnehmer'"
                src="~/assets/img/info.svg"
                class="w-6 h-6 tooltip-icon"
              />
            </span>
          </label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyContactLastName"
            type="text"
            name="companyContactLastName"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactLastName.$error
            }"
            @input="$v.companyContactLastName.$touch()"
          />
          <label for="companyContactLastName">
            Nachname des Ansprechpartners
          </label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyContactPhone"
            type="text"
            name="companyContactPhone"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactPhone.$error
            }"
            @input="$v.companyContactPhone.$touch()"
          />
          <label for="companyContactPhone">Telefon</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyContactEmail"
            type="text"
            name="companyContactEmail"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactEmail.$error
            }"
            required
            @input="$v.companyContactEmail.$touch()"
          />
          <label for="companyContactEmail">E-Mail-Adresse</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyAddress"
            type="text"
            name="companyAddress"
            :class="{
              Input: true,
              'Input--Error': $v.companyAddress.$error
            }"
            required
            @input="$v.companyAddress.$touch()"
          />
          <label for="companyAddress">Straße / Hausnummer</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyZip"
            type="text"
            name="companyZip"
            :class="{
              Input: true,
              'Input--Error': $v.companyZip.$error
            }"
            required
            @input="$v.companyZip.$touch()"
          />
          <label for="companyZip">PLZ</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyCity"
            type="text"
            name="companyCity"
            :class="{
              Input: true,
              'Input--Error': $v.companyCity.$error
            }"
            required
            @input="$v.companyCity.$touch()"
          />
          <label for="companyCity">Ort</label>
        </div>
      </div>
      <v-collapse-group class="mt-8">
        <v-collapse-wrapper
          class="border-none"
          @onStatusChange="toggleButtonCaption"
        >
          <button v-collapse-toggle class="button button--gray z-20">
            <span>Abweichender Rechnungsempfänger</span>
          </button>
          <div v-collapse-content>
            <h3 class="mb-6 mt-6">Abweichender Rechnungsempfänger</h3>
            <div class="InputContainer w-full md:w-1/3">
              <input
                v-model.trim="billingCompany"
                type="text"
                name="company"
                :class="{
                  Input: true,
                  'Input--Error': $v.billingCompany.$error
                }"
                required
                @input="$v.billingCompany.$touch()"
              />
              <label for="company" class="flex">
                Name des Unternehmens (max. 100 Zeichen)
                <span class="ml-2 w-6 h-6">
                  <img
                    v-tooltip="'Achten Sie bitte auf die korrekte Schreibweise'"
                    src="~/assets/img/info.svg"
                    class="w-6 h-6 tooltip-icon"
                  />
                </span>
              </label>
            </div>
            <div class="mt-6 flex flex-wrap">
              <div class="InputContainer w-full md:w-1/3">
                <input
                  v-model.trim="billingFirstName"
                  type="text"
                  name="billingFirstName"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingFirstName.$error
                  }"
                  @input="$v.billingFirstName.$touch()"
                />
                <label for="billingFirstName" class="flex">
                  Vorname des Empfängers
                </label>
              </div>
              <div class="InputContainer w-full md:w-1/3 md:ml-3">
                <input
                  v-model.trim="billingLastName"
                  type="text"
                  name="companyContactLastName"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingLastName.$error
                  }"
                  @input="$v.billingLastName.$touch()"
                />
                <label for="billingLastName">
                  Nachname des Empfängers
                </label>
              </div>
            </div>
            <div class="mt-6 flex flex-wrap">
              <div class="InputContainer w-full md:w-1/3">
                <input
                  v-model.trim="billingEmail"
                  type="text"
                  name="billingEmail"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingEmail.$error
                  }"
                  @input="$v.billingEmail.$touch()"
                />
                <label for="billingEmail">E-Mail-Adresse</label>
              </div>
              <div class="InputContainer w-full md:w-1/3 md:ml-3">
                <input
                  v-model.trim="billingAddress"
                  type="text"
                  name="billingAddress"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingAddress.$error
                  }"
                  @input="$v.billingAddress.$touch()"
                />
                <label for="billingAddress">Straße / Hausnummer</label>
              </div>
            </div>
            <div class="mt-6 flex flex-wrap">
              <div class="InputContainer w-full md:w-1/3">
                <input
                  v-model.trim="billingZip"
                  type="text"
                  name="billingZip"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingZip.$error
                  }"
                  @input="$v.billingZip.$touch()"
                />
                <label for="companyZip">PLZ</label>
              </div>
              <div class="InputContainer w-full md:w-1/3 md:ml-3">
                <input
                  v-model.trim="billingCity"
                  type="text"
                  name="billingCity"
                  :class="{
                    Input: true,
                    'Input--Error': $v.billingCity.$error
                  }"
                  @input="$v.billingCity.$touch()"
                />
                <label for="billingCity">Ort</label>
              </div>
            </div>
          </div>
        </v-collapse-wrapper>
      </v-collapse-group>

      <!-- TEILNEHMER -->
      <div
        v-for="(v, index) in $v.participants.$each.$iter"
        :key="index"
        class="mt-12 mb-12 p-6 bg-gray-200"
      >
        <h3 class="mb-6">
          Teilnehmer (Natürliche Personen)
          <span v-if="parseInt(index) >= 1">{{ parseInt(index) + 1 }}</span>
        </h3>
        <div class="flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <select
              v-model="v.salutation.$model"
              name="salutation"
              required
              :class="{
                Input: true,
                'Input--Error': v.salutation.$error
              }"
              class="bg-white cursor-pointer focus:outline-none"
              @select="v.salutation.$touch()"
            >
              <option value="" disabled>Auswählen</option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
              <option value="-">Keine Angabe</option>
            </select>
            <label for="salutation">Anrede</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <select
              v-model="v.jobTitle.$model"
              name="jobTitle"
              :class="{
                Input: true,
                'Input--Error': v.jobTitle.$error
              }"
              class="bg-white cursor-pointer focus:outline-none"
              @select="v.jobTitle.$touch()"
            >
              <option value="">keine Angabe</option>
              <option value="Dr.">Dr.</option>
              <option value="Prof.">Prof.</option>
              <option value="Prof. Dr.">Prof. Dr.</option>
            </select>
            <label for="jobTitle">Titel</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.firstName.$model"
              type="text"
              name="firstName"
              :class="{
                Input: true,
                'Input--Error': v.firstName.$error
              }"
              required
              @input="v.firstName.$touch()"
            />
            <label for="firstName">Vorname</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="v.lastName.$model"
              type="text"
              name="lastName"
              :class="{
                Input: true,
                'Input--Error': v.lastName.$error
              }"
              required
              @input="v.lastName.$touch()"
            />
            <label for="lastName">Nachname</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.email.$model"
              type="text"
              name="email"
              :class="{
                Input: true,
                'Input--Error': v.email.$error
              }"
              required
              @input="v.email.$touch()"
            />
            <label for="email">E-Mail-Adresse</label>
          </div>
          <!-- <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="v.participantNumber.$model"
              type="text"
              name="participantNumber"
              :class="{
                Input: true,
                'Input--Error': v.participantNumber.$error
              }"
              @input="v.participantNumber.$touch()"
            />
            <label for="participantNumber">Teilnehmernummer</label>
          </div> -->
        </div>

        <div class="mt-6">
          <div class="InputContainer w-full md:w-1/3">
            <Dropdown
              id="funktion"
              :selected="v.funktion.$model"
              :placeholder="'z. B. ' + funktionen[0].desc"
              :options="funktionen"
              class="mb-4"
              :class="{
                Input: true,
                'Input--Error': v.funktion.$error
              }"
              required
              @on-input="updateFunktion($event, index)"
            />
            <label for="funktion">Funktion / Position *</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.bestellnummer.$model"
              type="text"
              name="bestellnummer"
              :class="{
                Input: true,
                'Input--Error': v.bestellnummer.$error
              }"
              @input="v.bestellnummer.$touch()"
            />
            <label for="bestellnummer">Bestellnummer</label>
          </div>
          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="v.kostenstelle.$model"
              type="text"
              name="kostenstelle"
              :class="{
                Input: true,
                'Input--Error': v.kostenstelle.$error
              }"
              @input="v.kostenstelle.$touch()"
            />
            <label for="kostenstelle">Kostenstelle</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.bemerkung.$model"
              type="text"
              name="bemerkung"
              :class="{
                Input: true,
                'Input--Error': v.bemerkung.$error
              }"
              @input="v.bemerkung.$touch()"
            />
            <label for="bemerkungen">Bemerkungen (max. 50 Zeichen)</label>
          </div>
        </div>
      </div>

      <div class="my-6 flex justify-between">
        <button type="button" class="button" @click="addParticipant">
          weiterer Teilnehmer
        </button>
        <button
          v-if="participants.length > 1"
          type="button"
          class="button button--ghost"
          @click="removeParticipant"
        >
          weniger Teilnehmer
        </button>
      </div>

      <h3 class="mt-12 mb-3">Rabatt-Code</h3>
      <p>
        Falls Sie einen Rabatt-Code erhalten haben, geben Sie diesen bitte in
        das folgende Feld ein.
      </p>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="rabattCode"
            type="text"
            name="rabattCode"
            placeholder="Ihr Rabatt-Code"
            :class="{
              Input: true,
              'Input--Error': $v.rabattCode.$error
            }"
            @input="$v.rabattCode.$touch()"
          />
          <!-- <label for="rabattCode">Rabatt-Code</label> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

import Dropdown from '~/components/inputs/Dropdown'

// import { filter } from 'lodash'

// import Input from '~/components/Input'

export default {
  components: {
    Dropdown
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      company: '',
      companyNumber: '',
      companyContactFirstName: '',
      companyContactLastName: '',
      companyContactEmail: '',
      companyContactPhone: '',
      companyAddress: '',
      companyContactNumber: '',
      companyZip: '',
      companyCity: '',
      billingCompany: '',
      billingFirstName: '',
      billingLastName: '',
      billingEmail: '',
      billingAddress: '',
      billingZip: '',
      billingCity: '',
      rabattCode: '',
      participants: [
        {
          salutation: '',
          firstName: '',
          lastName: '',
          email: '',
          jobTitle: '',
          bestellnummer: '',
          kostenstelle: '',
          bemerkung: '',
          funktion: null,
          participantNumber: ''
        }
      ],
      funktionen: [
        { code: 'AZUBI', desc: 'Auszubildende/r' },
        { code: 'GF/VO', desc: 'Geschäftsführung/Vorstand' },
        { code: 'LT-P', desc: 'Leiter/in Personal' },
        { code: 'LT-A', desc: 'Leiter/in Ausbildung' },
        { code: 'LT-B', desc: 'Leiter/in Bestand/Vermietung' },
        { code: 'LT-I', desc: 'Leiter/in IT' },
        { code: 'LT-R', desc: 'Leiter/in Rechnungswesen' },
        { code: 'LT-T', desc: 'Leiter/in Technik' },
        { code: 'LT-W', desc: 'Leiter/in WEG' },
        { code: 'MA', desc: 'Mitarbeiter/in' }
      ]
    }
  },
  computed: {
    isCompany() {
      return this.type === 'company'
    }
  },
  mounted() {},
  validations: {
    company: {
      required: requiredIf('isCompany'),
      minLength: minLength(3),
      maxLength: maxLength(100)
    },
    companyNumber: {},
    companyContactFirstName: {
      minLength: minLength(3)
    },
    companyContactLastName: {
      minLength: minLength(3)
    },
    companyContactEmail: {
      required: requiredIf('isCompany'),
      email
    },
    companyContactPhone: {},
    companyContactNumber: {},
    companyAddress: {
      required: requiredIf('isCompany'),
      minLength: minLength(1)
    },
    companyZip: {
      required: requiredIf('isCompany'),
      minLength: minLength(4),
      maxLength: maxLength(6)
    },
    companyCity: {
      required: requiredIf('isCompany'),
      minLength: minLength(1)
    },
    billingCompany: {
      minLength: minLength(3),
      maxLength: maxLength(100)
    },
    billingFirstName: {
      minLength: minLength(3)
    },
    billingLastName: {
      minLength: minLength(3)
    },
    billingEmail: {
      email
    },
    billingAddress: {
      minLength: minLength(1)
    },
    billingZip: {
      minLength: minLength(4),
      maxLength: maxLength(6)
    },
    billingCity: {
      minLength: minLength(1)
    },
    participants: {
      required,
      $each: {
        salutation: {
          required
        },
        firstName: {
          required,
          minLength: minLength(1)
        },
        lastName: {
          required,
          minLength: minLength(1)
        },
        email: {
          required,
          email
        },
        participantNumber: {},
        jobTitle: {},
        bestellnummer: {},
        kostenstelle: {},
        bemerkung: {
          maxLength: maxLength(50)
        },
        funktion: {
          required
        }
      }
    },
    rabattCode: {}
  },
  methods: {
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    buildParticipant() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        participantNumber: '',
        jobTitle: '',
        bestellnummer: '',
        kostenstelle: '',
        bemerkung: '',
        funktion: null
      }
    },
    addParticipant() {
      this.participants.push(this.buildParticipant())
    },
    removeParticipant() {
      this.participants.pop()
    },
    updateFunktion(event, index) {
      const participantId = parseInt(index)
      this.participants[participantId].funktion = event
    }
  }
}
</script>

<style lang="postcss">
.tooltip {
  @apply bg-black text-white p-2 rounded-lg z-50 shadow-md;
}
.tooltip-icon {
  @apply cursor-pointer;
  filter: invert(50%);
}
</style>
