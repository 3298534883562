<template>
  <div>
    <div v-if="processing">
      <h3 class="mt-6 mb-3 text-green-700">Wir übermitteln Ihre Anfrage</h3>
      <p>
        Bitte haben Sie einen Moment Geduld, Sie werden in Kürze weitergeleitet.
      </p>
    </div>
    <div v-else>
      <h3>Inhouse Anfrage</h3>
      <p>Gerne setzen wir uns zeitnah mit Ihnen in Verbindung.</p>
      <p class="mt-2">
        Bitte füllen Sie die untenstehenden Felder aus, sofern Ihnen diese
        Angaben bereits vorliegen. Dies hilft uns, Ihnen noch schneller ein
        passgenaues Angebot erstellen zu können. Danke für Ihre Unterstützung.
      </p>
      <form @submit.prevent="submit">
        <div class="InputContainer w-full md:w-1/3 mt-6">
          <select
            v-model="$v.salutation.$model"
            name="salutation"
            required
            :class="{
              Input: true,
              'Input--Error': $v.salutation.$error
            }"
            class="bg-white cursor-pointer focus:outline-none"
          >
            <option value="" disabled>Auswählen</option>
            <option value="Frau">Frau</option>
            <option value="Herr">Herr</option>
            <option value="Keine Angabe">Divers/Keine Angabe</option>
          </select>
          <label for="salutation">Anrede</label>
        </div>
        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.firstName.$model"
              type="text"
              name="firstName"
              :class="{
                Input: true,
                'Input--Error': $v.firstName.$error
              }"
              required
            />
            <label for="firstName">Vorname</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="$v.lastName.$model"
              type="text"
              name="lastName"
              :class="{
                Input: true,
                'Input--Error': $v.lastName.$error
              }"
              required
            />
            <label for="lastName">Nachname</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.email.$model"
              type="email"
              name="email"
              :class="{
                Input: true,
                'Input--Error': $v.email.$error
              }"
              required
            />
            <label for="email">E-Mail Adresse</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.company.$model"
              type="text"
              name="company"
              :class="{
                Input: true,
                'Input--Error': $v.company.$error
              }"
            />
            <label for="company">Unternehmen</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="$v.position.$model"
              type="text"
              name="position"
              :class="{
                Input: true,
                'Input--Error': $v.position.$error
              }"
            />
            <label for="position">Funktion im Unternehmen</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.address.$model"
              type="text"
              name="address"
              :class="{
                Input: true,
                'Input--Error': $v.address.$error
              }"
            />
            <label for="address">Adresse</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="$v.phone.$model"
              type="text"
              name="phone"
              :class="{
                Input: true,
                'Input--Error': $v.phone.$error
              }"
            />
            <label for="phone">Telefon</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.participantAmount.$model"
              type="text"
              name="participantAmount"
              :class="{
                Input: true,
                'Input--Error': $v.participantAmount.$error
              }"
            />
            <label for="participantAmount">Teilnehmeranzahl</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="$v.trainingDaysAmount.$model"
              type="text"
              name="trainingDaysAmount"
              :class="{
                Input: true,
                'Input--Error': $v.trainingDaysAmount.$error
              }"
            />
            <label for="trainingDaysAmount">Anzahl Trainingstage</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.targetGroup.$model"
              type="text"
              name="targetGroup"
              :class="{
                Input: true,
                'Input--Error': $v.targetGroup.$error
              }"
            />
            <label for="targetGroup">Zielgruppe (Vorwissen)</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="$v.trainingDate.$model"
              type="text"
              name="trainingDate"
              :class="{
                Input: true,
                'Input--Error': $v.trainingDate.$error
              }"
            />
            <label for="trainingDate">gewünschte Trainingszeiträume</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="$v.trainer.$model"
              type="text"
              name="trainer"
              :class="{
                Input: true,
                'Input--Error': $v.trainer.$error
              }"
            />
            <label for="trainer">Trainerwunsch</label>
          </div>
        </div>

        <!-- Contact -->
        <div class="mt-12">
          <strong>Bitte kontaktieren Sie mich:</strong>
          <div>
            <div v-for="item in contactOptions" :key="item.code">
              <input
                :id="`contact_${item.code}`"
                v-model="contactOption"
                type="checkbox"
                class="Input"
                :value="item"
              />
              <label :for="`contact_${item.code}`">{{ item.desc }}</label>
            </div>
          </div>
        </div>

        <div class="mt-12">
          <div class="flex flex-wrap">
            <div class="InputContainer w-full md:w-1/3">
              <textarea
                v-model.trim="$v.message.$model"
                name="message"
                :class="{
                  Input: true,
                  'Input--Error': $v.message.$error
                }"
              />
              <label for="message">Nachricht</label>
            </div>
          </div>
        </div>

        <!-- SUBMIT -->
        <div class="mt-6 flex flex-wrap justify-end w-full md:w-2/3 md:ml-3">
          <button type="submit" class="button button--green">
            Anfrage absenden
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  props: {
    processing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      position: '',
      branche: null,
      address: '',
      phone: '',
      participantAmount: '',
      trainingDaysAmount: '',
      targetGroup: '',
      trainingDate: '',
      trainer: '',
      schulungsort: '',
      schulungszeitraum: '',
      contactOption: [],
      message: '',
      contactOptions: [
        { code: 'email', desc: 'schriftlich per E-Mail' },
        {
          code: 'phone',
          desc: 'telefonisch (bitte geben Sie Ihre Rufnummer an)'
        }
      ]
    }
  },
  validations: {
    salutation: {
      required
    },
    firstName: {
      required,
      minLength: minLength(1)
    },
    lastName: {
      required,
      minLength: minLength(1)
    },
    email: {
      required,
      email
    },
    contactOption: {},
    phone: {},
    company: {},
    position: {},
    address: {},
    participantAmount: {},
    trainingDaysAmount: {},
    targetGroup: {},
    trainingDate: {},
    trainer: {},
    message: {
      required
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'OK'

        this.$emit('on-submit', this.$data)
      }
    }
  }
}
</script>
