<template>
  <!-- TODO: Required option -->
  <div class="flex flex-col relative">
    <label v-if="label" :for="id" class="h-6">
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      rows="4"
      :value="value"
      :placeholder="placeholder"
      class="ebz-input-field ebz-textarea transition"
      @input="onChange"
    />
    <input
      v-else
      :id="id"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="ebz-input-field transition"
      @input="onChange"
    />
    <button
      v-if="value"
      class="clear-input-button absolute right-0 bottom-0 p-1 flex items-center mb-1 mr-1 rounded hover:bg-gray-100 focus:bg-gray-300 transition"
      type="button"
      @click="clearInput"
    >
      <Icon name="close" class="text-gray-700" />
    </button>
  </div>
</template>

<script>
import Icon from '~/components/Icon'
export default {
  components: {
    Icon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: ''
    }
  },
  methods: {
    clearInput() {
      this.$emit('on-clear')
    },
    onChange(e) {
      this.$emit('on-change', e.target.value, this.id)
    }
  }
}
</script>

<style lang="postcss">
input.ebz-input-field {
  @apply h-10 rounded px-4 mt-2 w-full max-w-full shadow border border-white;
  @apply text-sm;
  appearance: none;
}

.ebz-textarea {
  @apply h-auto py-4;
}

.ebz-input-field::placeholder {
  @apply text-gray-600 opacity-100;
}
.ebz-input-field:focus {
  @apply border-dark-blue-900;
  caret-color: #627aad;
}

.ebz-input-field[type="number"] {
  appearance: textfield;
}

@screen lg {
  .filters .clear-input-button {
    @apply mr-3;
  }
}

@screen xxl {
  .filters .clear-input-button {
    @apply mr-5;
  }
}
</style>
