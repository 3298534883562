<template>
  <div>
    <div class="container pt-6 pb12">
      <a class="button mb-8" @click="goBack">
        zurück
      </a>
      <section>
        <h1 class="mb-8">Warenkorb</h1>
      </section>
    </div>
    <section class="mt-12 pt-6 pb-12 bg-white border-t border-dark-gray-600">
      <div v-if="items.length > 0" class="container mx-auto">
        <h2>Übersicht</h2>
        <div v-if="shopItems.length >= 1" class="mt-4">
          <h3 class="text-dark-blue-900 font-bold text-sm">Steuerpflichtig</h3>
          <div
            v-for="(item, index) in shopItems"
            :key="index"
            class="border-b border-gray-600 pb-4 mt-4"
          >
            <div class="flex justify-between">
              <span class="text-gray-750 text-sm">
                {{ item.nummer }} - Dauer: {{ item.shop_duration }} Min
              </span>
              <span class="font-semibold text-green-700 text-lg">
                {{ getShopPrice(item) | currency }}
              </span>
            </div>
            <h3 class="mb-2">{{ item.titel }}</h3>
            <span
              class="text-sm text-red-400 cursor-pointer"
              @click="
                removeItemFromCart(
                  item.is_shop_article ? item.shop_nummer : item.template_nummer
                )
              "
            >
              Löschen
            </span>
          </div>
          <div class="pb-4 mt-4 flex justify-between md:justify-end">
            <div class="w-full md:w-auto">
              <div class="flex justify-between">
                <h5>zzgl. Umsatzsteuer (19%)</h5>
                <span class="font-semibold text-green-700 text-lg ml-12">
                  {{ ustPrice | currency }}
                </span>
              </div>
              <div class="flex justify-between mt-2">
                <h4>Zwischensumme</h4>
                <span class="font-semibold text-green-700 text-lg ml-12">
                  {{ shopItemPrice | currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="nonShopItems.length >= 1" class="mt-4">
          <h3 class="text-dark-blue-900 font-bold text-sm">Steuerfrei</h3>
          <div
            v-for="(item, index) in nonShopItems"
            :key="index"
            class="border-b border-gray-600 pb-4 mt-4"
          >
            <div class="flex justify-between">
              <span class="text-gray-750 text-sm">
                {{ item.nummer }} - {{ item.region }} - Startdatum:
                {{ item.start_datum | moment('DD.MM.YYYY') }}
              </span>
              <span class="font-semibold text-green-700 text-lg">
                {{ getShopPrice(item) | currency }}
              </span>
            </div>
            <h3 class="mb-2">{{ item.titel }}</h3>
            <span
              class="text-sm text-red-400 cursor-pointer"
              @click="
                removeItemFromCart(
                  item.is_shop_article ? item.shop_nummer : item.template_nummer
                )
              "
            >
              Löschen
            </span>
          </div>
          <div
            v-if="nonShopItems.length >= 2 && shopItems.length >= 1"
            class="pb-4 mt-4 flex justify-between md:justify-end"
          >
            <div class="w-full md:w-auto">
              <div class="flex justify-between">
                <h4>Zwischensumme</h4>
                <span class="font-semibold text-green-700 text-lg ml-12">
                  {{ itemPrice | currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex justify-between md:justify-end pb-4 mt-4"
          :class="{ 'border-t border-gray-600 pt-4': hasNonShopItems }"
        >
          <h4>Gesamtsumme</h4>
          <span class="font-semibold text-green-700 text-lg ml-12">
            {{ completePrice | currency }}
          </span>
        </div>
        <div class="pb-4 mt-4 text-right">
          <nuxt-link
            :to="`/shopping_cart/anmeldung`"
            class="button button--green"
          >
            Weiter zur Buchung
          </nuxt-link>
        </div>
      </div>
      <div v-else class="container mx-auto">
        <h3>Keine Elemente im Warenkorb vorhanden</h3>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  scrollTop: true,
  components: {},
  data() {
    return {}
  },
  head() {
    return {
      title: 'Warenkorb — EBZ Bildungsfinder',
      link: [
        {
          rel: 'canonical',
          href: this.getCanonical
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      items: 'cart/items',
      shopItems: 'cart/shopItems',
      nonShopItems: 'cart/nonShopItems',
      shopItemPrice: 'cart/shopItemPrice',
      itemPrice: 'cart/itemPrice',
      ustPrice: 'cart/ustPrice',
      completePrice: 'cart/completePrice'
    }),
    hasNonShopItems() {
      return this.nonShopItems.length >= 2 && this.shopItems.length >= 1
    }
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    },
    removeItemFromCart(nummer) {
      this.$store.commit('cart/remove', {
        nummer
      })
    },
    getShopPrice(item) {
      if (item.vorlage.is_shop_item) {
        return item.shop_price
      }

      if (item.vorlage.is_special_event) {
        const priceGroup = _(item.vorlage.veranstaltungen[0].leistungen)
          .filter((p) => p.preisgruppencode === 'SP8')
          .head()

        return priceGroup.preis_lcy_net
      }

      const leistungen = item.leistungen

      const lowestPrice = _(leistungen)
        .flattenDeep()
        .sortBy('preis_lcy_net')
        .head()

      if (lowestPrice) {
        return lowestPrice.preis_lcy_net
      }

      return 0
    }
  }
}
</script>

<style lang="postcss"></style>
