import { render, staticRenderFns } from "./Anmeldung.vue?vue&type=template&id=af6f4cba"
import script from "./Anmeldung.vue?vue&type=script&lang=js"
export * from "./Anmeldung.vue?vue&type=script&lang=js"
import style0 from "./Anmeldung.vue?vue&type=style&index=0&id=af6f4cba&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports