<template>
  <div
    v-if="isOpen"
    class="CookieBanner print:hidden"
    @close="closeCookieConsent"
  >
    <div class="CookieBanner__Inner">
      <div class="CookieBanner__Header">
        <div class="text-2xl font-semibold">
          Einstellungen zum Datenschutz
        </div>
      </div>
      <div class="CookieBanner__Content">
        <p class="font-semibold">
          Wir verwenden Cookies, um Ihnen ein optimales Erlebnis auf unserer
          Website zu bieten.
        </p>
        <p>
          Zu diesen Cookies zählen solche, die für den Betrieb der Seite
          unmittelbar notwendig sind, sowie solche, die lediglich zu anonymen
          Statistikzwecken, für Komforteinstellungen oder zur Anzeige
          personalisierter Inhalte genutzt werden. Bitte entscheiden Sie selbst,
          welche Kategorien Sie zulassen möchten. Um die Website zu nutzen,
          müssen Sie jedoch die Nutzung der notwendigen Cookies akzeptieren.
          Weitere Informationen sowie die im Detail genutzten Cookies finden Sie
          in
          <a
            href="https://www.e-b-z.de/datenschutzerklaerung.html"
            target="_blank"
            >unseren Datenschutzhinweisen<span
              class="small glyphicon glyphicon-new-window"
              aria-hidden="true"
            ></span></a
          >.
        </p>
        <p class="font-semibold">
          <a href="#" @click="showDetails = !showDetails"
            >Mehr Informationen und erweiterte Einstellungen<Icon
              height="20"
              width="20"
              class="inline-block"
              :name="[showDetails ? 'chevron-up' : 'chevron-down']"
            />
          </a>
        </p>
      </div>
      <div v-show="showDetails" class="CookieBanner__Details">
        <p class="font-bold">
          Notwendige Cookies
        </p>
        <p>
          Diese Cookies sind für den Betrieb der Seite unbedingt notwendig und
          ermöglichen beispielsweise sicherheitsrelevante Funktionalitäten.
          <!--Außerdem können wir mit dieser Art von Cookies ebenfalls erkennen,
            ob Sie in Ihrem Profil eingeloggt bleiben möchten, um Ihnen unsere
            Dienste bei einem erneuten Besuch unserer Seite schneller zur
            Verfügung zu stellen.-->
        </p>
        <p>
          Diese Art von Cookies sind Voraussetzung für die Nutzung der Website.
          Die Einstellung kann daher nicht abgewählt werden. Sie müssen der
          Nutzung jedoch zustimmen (siehe unten), bevor Sie die Website nutzen
          können.
        </p>
        <p>
          <label
            class="flex items-start w-full pb-2 cursor-pointer select-none filter"
            style="font-weight: bold"
            ><input
              type="checkbox"
              value=""
              class="relative flex items-center justify-center flex-shrink-0 mr-2 bg-white border-2 border-gray-600 rounded cursor-pointer ebz-checkbox"
              checked
              disabled
            />
            Notwendige Cookies aktivieren (nicht abwählbar)</label
          >
        </p>
        <p class="font-bold">
          Anonymisierte Statistiken
        </p>
        <p>
          Um unser Angebot und unsere Webseite weiter zu verbessern, erfassen
          wir anonymisierte Daten für Statistiken und Analysen. Mithilfe dieser
          Cookies können wir beispielsweise die Besucherzahlen, ggfs. die
          Besucherquelle und den Effekt bestimmter Seiten unseres Web-Auftritts
          ermitteln und unsere Inhalte optimieren.
        </p>
        <p>
          <label
            class="flex items-start w-full pb-2 cursor-pointer select-none filter"
            style="font-weight: bold"
            ><input
              v-model="consentStatistics"
              type="checkbox"
              class="relative flex items-center justify-center flex-shrink-0 mr-2 bg-white border-2 border-gray-600 rounded cursor-pointer ebz-checkbox"
              value=""
            />
            Cookies für Anonymisierte Statistiken aktivieren</label
          >
        </p>
        <p class="font-bold">
          Werbung & Komfort
        </p>
        <p>
          Diese Cookies werden genutzt, um Ihnen personalisierte Inhalte,
          passend zu Ihren Interessen anzuzeigen. Somit können wir Ihnen auf
          unserer und anderen Websiten Angebote und Empfehlungen präsentieren,
          die für Sie und ihre Suchkriterien besonders relevant sind.
        </p>
        <p>
          <label
            class="flex items-start w-full pb-2 cursor-pointer select-none filter"
            style="font-weight: bold"
            ><input
              v-model="consentAdsComfort"
              type="checkbox"
              class="relative flex items-center justify-center flex-shrink-0 mr-2 bg-white border-2 border-gray-600 rounded cursor-pointer ebz-checkbox"
              value=""
            />
            Cookies für Werbung & Komfort aktivieren</label
          >
        </p>
        <!--<p>
          Die Aufstellung aller Cookies dieser Kategorien finden Sie unter
          <a href="#">diesem Link</a>.
        </p>-->
        <p class="font-bold">
          Einwilligungen widerrufen
        </p>
        <p>
          Sie können Ihre Einwilligungen jederzeit über den folgenden Link
          widerrufen. Durch den Widerruf der Erlaubnis zur Verwendung auch der
          notwendigen Cookies können Sie die Website nicht mehr benutzen, bis
          Sie der Verwendung mindestens der notwendigen Cookies erneut
          zustimmen.
          <a href="#" @click="revokeAllConsents"> Klicken Sie hier</a>, um alle
          Einwilligungen zu widerrufen.
        </p>
      </div>
      <div class="w-full CookieBanner__Footer">
        <div class="mt-5 CookieBanner__Actions">
          <button
            v-show="showDetails"
            type="button"
            class="mt-5 button button--gray focus:shadow-outline"
            @click="saveAndClose"
          >
            Einstellungen speichern
          </button>
          <button
            type="button"
            class="mt-5 ml-2 button focus:shadow-outline"
            @click="saveAllAndClose"
          >
            Alle akzeptieren
          </button>
        </div>
        <div class="mt-5 text-sm CookieBanner__Actions">
          <a
            href="https://www.e-b-z.de/impressum.html"
            title="Impressum"
            target="_blank"
          >
            Impressum<Icon
              height="16"
              width="16"
              class="inline-block"
              name="new-tab"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '~/components/Icon'

export default {
  name: 'CookieConsentManager',
  components: {
    Icon
  },
  props: {
    showDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      consentEssentials: this.getConsentEssentials(),
      consentStatistics: this.getConsentStatistics(),
      consentAdsComfort: this.getConsentAdsComfort()
    }
  },
  computed: {
    isOpen() {
      return this.$store.state.cookiebanner.isCookieConsentOpen
    }
  },
  watch: {
    consentEssentials(value) {
      if (value === true) localStorage.setItem('GDPR:essentials', true)
      else localStorage.removeItem('GDPR:essentials')
    },
    consentAdsComfort(value) {
      if (value === true) localStorage.setItem('GDPR:adscomfort', true)
      else localStorage.removeItem('GDPR:adscomfort')
    },
    consentStatistics(value) {
      if (value === true) localStorage.setItem('GDPR:statistics', true)
      else localStorage.removeItem('GDPR:statistics')
    }
  },
  created() {
    // log proof of evidence
    this.sendCookieConsentData()

    // onload handling
    if (!this.consentEssentials) this.showCookieConsent()
  },
  methods: {
    async sendCookieConsentData() {
      try {
        const params = {
          data: {
            'page-url': window.location.href,
            consents: [
              {
                type: 'GDPR:essentials',
                value: this.consentEssentials
              },
              {
                type: 'GDPR:statistics',
                value: this.consentStatistics
              },
              {
                type: 'GDPR:adscomfort',
                value: this.consentAdsComfort
              }
            ]
          }
        }
        await this.$axios.post('/cookie-consent', params)
      } catch {}
    },

    showCookieConsent() {
      this.$store.dispatch('cookiebanner/showModal')
    },
    closeCookieConsent() {
      this.$store.dispatch('cookiebanner/hideModal')
    },

    getConsentEssentials() {
      if (localStorage.getItem('GDPR:essentials') === 'true') return true
      else return false
    },
    getConsentStatistics() {
      if (localStorage.getItem('GDPR:statistics') === 'true') return true
      else return false
    },
    getConsentAdsComfort() {
      if (localStorage.getItem('GDPR:adscomfort') === 'true') return true
      else return false
    },

    saveAndClose() {
      // essential cookies will always be activated
      this.consentEssentials = true
      this.closeCookieConsent()
    },
    saveAllAndClose() {
      // set all consents true
      this.consentEssentials = true
      this.consentStatistics = true
      this.consentAdsComfort = true
      this.closeCookieConsent()
    },
    revokeAllConsents() {
      this.consentEssentials = false
      this.consentStatistics = false
      this.consentAdsComfort = false
    }
  },

  /* prevent bg scroll when cookie banner is open */
  head() {
    if (this.isOpen)
      return {
        bodyAttrs: {
          class: ['relative', 'overflow-hidden']
        }
      }
  }
}
</script>

<style lang="postcss" scoped>
.CookieBanner {
  @apply fixed inset-0 flex items-center justify-center;
  @apply w-full h-screen;
  @apply opacity-100;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.CookieBanner a {
  @apply underline;
}

.CookieBanner__Inner {
  @apply relative;
  @apply w-full max-w-3xl p-8 max-h-full;
  @apply bg-white;
  @apply /*rounded-lg*/ shadow-lg;
  @apply opacity-100;
  @apply overflow-y-auto;

  z-index: 1001;
}

.CookieBanner__Header {
  @apply mb-5;
}

.CookieBanner__Content {
  @apply flex-1;
}

.CookieBanner__Details {
}

.CookieBanner__Actions > p,
.CookieBanner__Details > p,
.CookieBanner__Content > p {
  @apply text-sm;
  @apply mb-5;
  @apply text-gray-ebz;
}

.CookieBanner__Actions {
  @apply flex flex-wrap justify-end;
  @apply w-full;
}
</style>
