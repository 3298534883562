<template>
  <div>
    <div v-if="isCompany">
      <h3 class="mb-6">Unternehmen / Rechnungsempfänger</h3>
      <div>
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="company"
            type="text"
            name="company"
            :class="{
              Input: true,
              'Input--Error': $v.company.$error
            }"
            required
            @input="$v.company.$touch()"
          />
          <label for="company">Name des Unternehmen (max. 50 Zeichen)</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyContactFirstName"
            type="text"
            name="companyContactFirstName"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactFirstName.$error
            }"
            required
            @input="$v.companyContactFirstName.$touch()"
          />
          <label for="companyContactFirstName">
            Vorname des Ansprechpartners
          </label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyContactLastName"
            type="text"
            name="companyContactLastName"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactLastName.$error
            }"
            required
            @input="$v.companyContactLastName.$touch()"
          />
          <label for="companyContactLastName">
            Nachname des Ansprechpartners
          </label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyContactEmail"
            type="text"
            name="companyContactEmail"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactEmail.$error
            }"
            required
            @input="$v.companyContactEmail.$touch()"
          />
          <label for="companyContactEmail">E-Mail</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyContactPhone"
            type="text"
            name="companyContactPhone"
            :class="{
              Input: true,
              'Input--Error': $v.companyContactPhone.$error
            }"
            @input="$v.companyContactPhone.$touch()"
          />
          <label for="companyContactPhone">Telefon</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyAddress"
            type="text"
            name="companyAddress"
            :class="{
              Input: true,
              'Input--Error': $v.companyAddress.$error
            }"
            required
            @input="$v.companyAddress.$touch()"
          />
          <label for="companyAddress">Straße / Hausnummer</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="companyZip"
            type="text"
            name="companyZip"
            :class="{
              Input: true,
              'Input--Error': $v.companyZip.$error
            }"
            required
            @input="$v.companyZip.$touch()"
          />
          <label for="companyZip">PLZ</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="companyCity"
            type="text"
            name="companyCity"
            :class="{
              Input: true,
              'Input--Error': $v.companyCity.$error
            }"
            required
            @input="$v.companyCity.$touch()"
          />
          <label for="companyCity">Ort</label>
        </div>
      </div>

      <!-- TEILNEHMER -->
      <div
        v-for="(v, index) in $v.participants.$each.$iter"
        :key="index"
        class="mt-12 mb-12 p-6 bg-gray-200"
      >
        <h3 class="mb-6">
          Teilnehmer (Natürliche Personen)
          <span v-if="parseInt(index) >= 1">{{ parseInt(index) + 1 }}</span>
        </h3>
        <div class="flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <select
              v-model="v.salutation.$model"
              name="salutation"
              required
              :class="{
                Input: true,
                'Input--Error': v.salutation.$error
              }"
              class="bg-white cursor-pointer focus:outline-none"
              @select="v.salutation.$touch()"
            >
              <option value="" disabled>Auswählen</option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
              <option value="-">Keine Angabe</option>
            </select>
            <label for="salutation">Anrede</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <select
              v-model="v.jobTitle.$model"
              name="jobTitle"
              :class="{
                Input: true,
                'Input--Error': v.jobTitle.$error
              }"
              class="bg-white cursor-pointer focus:outline-none"
              @select="v.jobTitle.$touch()"
            >
              <option value="">keine Angabe</option>
              <option value="Dr.">Dr.</option>
              <option value="Prof.">Prof.</option>
              <option value="Prof. Dr.">Prof. Dr.</option>
            </select>
            <label for="jobTitle">Titel</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.firstName.$model"
              type="text"
              name="firstName"
              :class="{
                Input: true,
                'Input--Error': v.firstName.$error
              }"
              required
              @input="v.firstName.$touch()"
            />
            <label for="firstName">Vorname</label>
          </div>

          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="v.lastName.$model"
              type="text"
              name="lastName"
              :class="{
                Input: true,
                'Input--Error': v.lastName.$error
              }"
              required
              @input="v.lastName.$touch()"
            />
            <label for="lastName">Nachname</label>
          </div>
        </div>

        <div class="mt-6">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.email.$model"
              type="text"
              name="email"
              :class="{
                Input: true,
                'Input--Error': v.email.$error
              }"
              required
              @input="v.email.$touch()"
            />
            <label for="email">E-Mail</label>
          </div>
        </div>

        <div class="mt-6">
          <div class="InputContainer w-full md:w-1/3">
            <Dropdown
              id="funktion"
              :selected="v.funktion.$model"
              :placeholder="'z. B. ' + funktionen[0].desc"
              :options="funktionen"
              class="mb-4"
              @on-input="updateFunktion($event, index)"
            />
            <label for="funktion">Funktion / Position</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.bestellnummer.$model"
              type="text"
              name="bestellnummer"
              :class="{
                Input: true,
                'Input--Error': v.bestellnummer.$error
              }"
              @input="v.bestellnummer.$touch()"
            />
            <label for="bestellnummer">Bestellnummer</label>
          </div>
          <div class="InputContainer w-full md:w-1/3 md:ml-3">
            <input
              v-model.trim="v.kostenstelle.$model"
              type="text"
              name="kostenstelle"
              :class="{
                Input: true,
                'Input--Error': v.kostenstelle.$error
              }"
              @input="v.kostenstelle.$touch()"
            />
            <label for="kostenstelle">Kostenstelle</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-1/3">
            <input
              v-model.trim="v.bemerkung.$model"
              type="text"
              name="bemerkung"
              :class="{
                Input: true,
                'Input--Error': v.bemerkung.$error
              }"
              @input="v.bemerkung.$touch()"
            />
            <label for="bemerkungen">Bemerkungen (max. 50 Zeichen)</label>
          </div>
        </div>

        <div class="mt-6 flex flex-wrap">
          <div class="InputContainer w-full md:w-2/3">
            <multiselect
              v-if="priceGroups"
              v-model="v.priceGroup.$model"
              name="priceGroup"
              :searchable="false"
              :options="priceGroups"
              :show-labels="true"
              :class="{
                'Input--Error': v.priceGroup.$error
              }"
              placeholder="Bitte treffen Sie eine Auswahl"
              track-by="preis_lcy_net"
              label="preisgruppenbeschreibung"
              @input="v.priceGroup.$touch()"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.preis_lcy_net | currency }}</strong>
                <span v-if="priceGroups.length > 1" class="ml-2">
                  {{ option.beschreibung }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <span class="option__title"
                  ><strong>{{ props.option.preis_lcy_net | currency }}</strong>
                  <em v-if="priceGroups.length > 1" class="ml-2 not-italic">
                    {{ props.option.beschreibung }}
                  </em>
                </span>
              </template>
              <span slot="noResult" class="no-result"
                >Kein Ergebnis gefunden.</span
              >
            </multiselect>
            <label for="priceGroup" class="block mb-2"
              >Wählen Sie ein Veranstaltungspaket *</label
            >
          </div>
        </div>
      </div>

      <div class="my-6 flex justify-between">
        <button type="button" class="button" @click="addParticipant">
          weiterer Teilnehmer
        </button>
        <button
          v-if="participants.length > 1"
          type="button"
          class="button button--ghost"
          @click="removeParticipant"
        >
          weniger Teilnehmer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

import _ from 'lodash'
import Multiselect from 'vue-multiselect'
import Dropdown from '~/components/inputs/Dropdown'

// import { filter } from 'lodash'

// import Input from '~/components/Input'

export default {
  components: {
    Dropdown,
    Multiselect
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    vorlage: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      company: '',
      companyContactFirstName: '',
      companyContactLastName: '',
      companyContactEmail: '',
      companyContactPhone: '',
      companyAddress: '',
      companyZip: '',
      companyCity: '',
      participants: [
        {
          salutation: '',
          firstName: '',
          lastName: '',
          email: '',
          jobTitle: '',
          bestellnummer: '',
          kostenstelle: '',
          bemerkung: '',
          funktion: null,
          priceGroup: this.preselectPriceGroup()
        }
      ],
      funktionen: [
        { code: 'AZUBI', desc: 'Auszubildende/r' },
        { code: 'GF/VO', desc: 'Geschäftsführung/Vorstand' },
        { code: 'LT-P', desc: 'Leiter/in Personal' },
        { code: 'LT-A', desc: 'Leiter/in Ausbildung' },
        { code: 'LT-B', desc: 'Leiter/in Bestand/Vermietung' },
        { code: 'LT-I', desc: 'Leiter/in IT' },
        { code: 'LT-R', desc: 'Leiter/in Rechnungswesen' },
        { code: 'LT-T', desc: 'Leiter/in Technik' },
        { code: 'LT-W', desc: 'Leiter/in WEG' },
        { code: 'MA', desc: 'Mitarbeiter/in' }
      ]
    }
  },
  computed: {
    isCompany() {
      return this.type === 'company'
    },
    selectedVeranstaltung() {
      const termin = this.$route.query.termin

      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    },
    priceGroups() {
      const priceGroups =
        this.selectedVeranstaltung &&
        this.selectedVeranstaltung.leistungen &&
        _(this.selectedVeranstaltung.leistungen)
          .flattenDeep()
          .orderBy(['preis_lcy_net'], ['desc'])
          .value()

      console.log('priceGroups', priceGroups)

      return priceGroups
    }
  },
  mounted() {
    this.participants.forEach((p) => {
      if (!p.priceGroup) {
        p.priceGroup = this.preselectPriceGroup()
      }
    })
  },
  validations: {
    company: {
      required: requiredIf('isCompany'),
      minLength: minLength(3),
      maxLength: maxLength(50)
    },
    companyContactFirstName: {
      required: requiredIf('isCompany'),
      minLength: minLength(3)
    },
    companyContactLastName: {
      required: requiredIf('isCompany'),
      minLength: minLength(3)
    },
    companyContactEmail: {
      required: requiredIf('isCompany'),
      email
    },
    companyContactPhone: {},
    companyAddress: {
      required: requiredIf('isCompany'),
      minLength: minLength(1)
    },
    companyZip: {
      required: requiredIf('isCompany'),
      minLength: minLength(4),
      maxLength: maxLength(6)
    },
    companyCity: {
      required: requiredIf('isCompany'),
      minLength: minLength(1)
    },
    participants: {
      required,
      $each: {
        salutation: {
          required
        },
        firstName: {
          required,
          minLength: minLength(1)
        },
        lastName: {
          required,
          minLength: minLength(1)
        },
        email: {
          required,
          email
        },
        jobTitle: {},
        bestellnummer: {},
        kostenstelle: {},
        bemerkung: {
          maxLength: maxLength(50)
        },
        funktion: {},
        priceGroup: {
          required
        }
      }
    }
  },
  methods: {
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    buildParticipant() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        bestellnummer: '',
        kostenstelle: '',
        bemerkung: '',
        funktion: null,
        priceGroup: this.preselectPriceGroup()
      }
    },
    preselectPriceGroup() {
      const highestPriceGroup =
        this.selectedVeranstaltung &&
        this.selectedVeranstaltung.leistungen &&
        _(this.selectedVeranstaltung.leistungen)
          .flattenDeep()
          .orderBy(['preis_lcy_net'], ['desc'])
          .head()

      return highestPriceGroup
    },
    addParticipant() {
      this.participants.push(this.buildParticipant())
    },
    removeParticipant() {
      this.participants.pop()
    },
    updateFunktion(event, index) {
      const participantId = parseInt(index)
      this.participants[participantId].funktion = event
    }
  }
}
</script>
