<template>
  <div>
    <div class="container pt-6 pb12">
      <nuxt-link
        :to="
          '../' +
            this.$route.params.slug +
            '?termin=' +
            this.$route.query.termin
        "
        class="button mb-8"
      >
        zurück
      </nuxt-link>
      <section>
        <span class="overline inline-block flex items-center h-10 mb-2">
          {{ vorlage.produktart }}
        </span>
        <h1 class="mb-8">{{ vorlage.titel }}</h1>
      </section>
    </div>
    <section
      class="mt-12 mt-12 pt-6 pb-12 bg-white border-t border-dark-gray-600"
    >
      <div class="container mx-auto">
        <Anfrage class="mt-6" :processing="processing" @on-submit="submit" />
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Anfrage from '~/components/forms/Anfrage'

export default {
  middleware: 'ensure-va-selected',
  components: {
    Anfrage
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'robots',
          name: 'robots',
          content: `noindex,nofollow`
        }
      ]
    }
  },
  data() {
    return {
      processing: false
    }
  },
  computed: {
    ...mapGetters({
      getVorlageBySlug: 'veranstaltungsvorlagen/getVorlageBySlug'
    }),
    isLoading() {
      return this.$store.state.veranstaltungsvorlagen.isLoading
    },
    vorlage() {
      return this.getVorlageBySlug(this.$route.params.slug)
    },
    selectedVeranstaltung() {
      const termin = this.$route.query.termin

      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    }
  },
  async fetch({ store, params }) {
    await store.dispatch('veranstaltungsvorlagen/get', params.slug)
  },
  methods: {
    async submit(model) {
      const veranstaltung = this.selectedVeranstaltung
      const data = {}

      this.processing = true

      Object.assign(data, {
        location: model.location,
        companyName: model.company,
        companyAddress: model.address,
        companyContactFirstName: model.firstName,
        companyContactLastName: model.lastName,
        companyContactEmail: model.email,
        companyContactPhone: model.phone,
        message: model.message,
        contactOption:
          (model.contactOption &&
            model.contactOption.length &&
            model.contactOption.map((o) => o.desc)) ||
          []
      })

      try {
        await this.$axios.post(
          `/veranstaltungen/${veranstaltung.nummer}/anfrage`,
          data
        )

        this.processing = false

        this.$router.replace({
          path: `/${this.vorlage.slug}/anfrage_bestaetigung`,
          query: { termin: veranstaltung.nummer }
        })
      } catch (error) {
        console.error(error)

        // Report to bugsnag
        this.$bugsnag.notify(error, {
          metaData: {
            axios: {
              url: `/veranstaltungen/${veranstaltung.nummer}/anfrage`,
              method: 'POST',
              data
            }
          }
        })

        this.$router.replace({
          path: `/${this.vorlage.slug}/anmeldung_fehler`,
          query: { termin: veranstaltung.nummer }
        })
      }
    }
  }
}
</script>
