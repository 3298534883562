<template>
  <div
    v-if="text && text.text.length"
    class="event-text-block"
    :class="{ 'event-text-block--highlight': highlight }"
  >
    <h3 class="event-text-block__title xxl:w-64 mb-4 flex-shrink-0 mr-16">
      {{ text.textart_beschreibung }}
    </h3>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="event-text-block__content" v-html="text.text" />
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: Object,
      default: () => {}
    },
    highlight: {
      type: Boolean,
      default: false
    },
    structuredItemprop: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="postcss">
.event-text-block {
  @apply text-base;
}

.event-text-block h3 {
  @apply text-gray-800;
}

.event-text-block--highlight h3 {
  @apply text-blue-600 font-semibold;
}

.event-text-block--highlight .event-text-block__content {
  @apply text-gray-900;
}
</style>
