<template>
  <svg
    :class="'icon' + ' icon--' + name"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <!-- <path class="icon__canvas" fill="none" d="M0,0h24v24h-24v-24z" /> -->
    <!-- share icon -->
    <path
      v-if="name == 'share'"
      d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
    />

    <!-- link icon -->
    <path
      v-else-if="name == 'link'"
      d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
    />

    <!-- Facebook icon -->
    <path
      v-else-if="name == 'Facebook'"
      d="M16.5234 9.00023H13V7.00023C13 5.96898 13.0859 5.31663 14.5625 5.31663H16.4297V2.13694C15.5234 2.04319 14.6094 1.99632 13.6914 2.00023C10.9805 2.00023 9 3.65648 9 6.69944V9.00023H6V13.0002H9V22.0002H13V12.9963H16.0664L16.5234 9.00023Z"
    />

    <!-- LinkedIn icon -->
    <path
      v-else-if="name == 'LinkedIn'"
      d="M2 4.06873C2 5.25472 2.8895 6.14421 4.2237 6.14421C5.6995 6.14421 6.5889 5.25472 6.5889 4.06873C6.5889 2.88949 5.6995 2 4.3652 2C2.8895 2 2 2.88949 2 4.06873ZM2.1213 20.4299H6.4744V8.35445H2.1213V20.4299ZM22 20.4299H17.7884V13.8329C17.7884 12.0067 16.6631 11.5822 16.2453 11.5822C15.8208 11.5822 14.4191 11.8652 14.4191 13.8329V20.4299H10.066V8.35445H14.4191V10.0391C14.9784 9.05526 16.1038 8.35445 18.2062 8.35445C20.3154 8.35445 22 10.0391 22 13.8329V20.4299Z"
    />

    <!-- Xing icon -->
    <path
      v-else-if="name == 'Xing'"
      d="M7.45828 16.3269H4.33916C4.05662 16.3269 3.91257 16.0388 4.05662 15.7562L7.31977 9.80055C7.31977 9.65651 7.31977 9.51801 7.31977 9.51801L5.19235 5.82826C5.04831 5.54571 5.19235 5.26316 5.4749 5.26316H8.59401C8.73806 5.26316 8.87656 5.26316 8.87656 5.40166L11.2921 9.51801C11.2921 9.65651 11.2921 9.80055 11.2921 9.80055L7.74637 16.1828C7.74637 16.3269 7.60232 16.3269 7.45828 16.3269ZM21.076 2.5651L14.8378 14.0554C14.8378 14.1994 14.8378 14.3379 14.8378 14.3379L18.9486 21.4294C19.0926 21.7119 18.9486 22 18.666 22H15.4029C15.2588 22 15.1203 22 15.1203 21.856L10.8655 14.482C10.8655 14.3379 10.8655 14.1994 10.8655 14.1994L17.3863 2.42659C17.5 2 17.5303 2 17.6743 2H20.7935C20.932 2 21.22 2.28255 21.076 2.5651Z"
    />

    <!-- Twitter icon -->
    <path
      v-else-if="name == 'Twitter'"
      d="M7.82 22C16.18 22 20.69 15.0434 20.69 9.08068C20.69 8.85984 20.69 8.639 20.69 8.52858C21.57 7.86605 22.34 7.0931 23 6.20973C22.12 6.54099 21.24 6.87225 20.36 6.87225C21.35 6.32015 22.01 5.43677 22.34 4.33256C21.46 4.88467 20.47 5.21593 19.48 5.43677C17.72 3.55961 14.86 3.55961 13.1 5.21593C11.89 6.32015 11.45 7.97647 11.78 9.52237C8.15 9.41195 4.74 7.6452 2.43 4.88467C1.22 6.87225 1.88 9.63279 3.86 10.9578C3.09 10.8474 2.43 10.737 1.77 10.2953C1.77 10.2953 1.77 10.2953 1.77 10.4057C1.77 12.6142 3.31 14.4913 5.4 14.8226C4.74 15.0434 4.08 15.0434 3.42 14.933C3.97 16.8102 5.73 18.0248 7.71 18.1352C6.06 19.3499 4.08 20.1228 2.1 20.1228C1.77 20.1228 1.33 20.1228 1 20.0124C2.98 21.3375 5.4 22 7.82 22Z"
    />

    <!-- WhatsApp icon -->
    <path
      v-else-if="name == 'WhatsApp'"
      d="M22 11.7427C22 17.1231 17.6044 21.4848 12.181 21.4848C10.4599 21.4848 8.84319 21.0446 7.43581 20.2737L2 22L3.77194 16.7716C2.87847 15.3042 2.36391 13.5812 2.36391 11.7421C2.36456 6.36169 6.75886 2 12.1823 2C17.605 2.0013 22 6.36234 22 11.7427ZM12.1803 3.55346C7.62885 3.55346 3.9265 7.22777 3.9265 11.7447C3.9265 13.5368 4.51084 15.1959 5.49887 16.5459L4.46845 19.587L7.63929 18.5794C8.94362 19.435 10.5043 19.9333 12.1803 19.9333C16.7318 19.9333 20.4355 16.2596 20.4355 11.7427C20.4368 7.22777 16.7324 3.55346 12.1803 3.55346ZM17.1394 13.9868C17.0781 13.8883 16.9177 13.8283 16.6777 13.7084C16.4383 13.5884 15.2533 13.0112 15.0336 12.9329C14.8118 12.8527 14.6514 12.8123 14.491 13.0516C14.3325 13.291 13.8707 13.8283 13.7292 13.9888C13.589 14.1486 13.4488 14.1688 13.2081 14.0507C12.9675 13.9294 12.1914 13.679 11.2719 12.8658C10.5564 12.2318 10.0732 11.4512 9.93361 11.2119C9.79209 10.9732 9.91926 10.844 10.0386 10.7253C10.1462 10.6177 10.2793 10.4462 10.3999 10.306C10.5206 10.1671 10.5603 10.0686 10.6406 9.90883C10.7195 9.74905 10.6797 9.61014 10.6203 9.48948C10.5603 9.36949 10.0797 8.19559 9.87818 7.71755C9.67796 7.24016 9.47775 7.31907 9.33688 7.31907C9.19666 7.31907 9.03623 7.29951 8.8758 7.29951C8.71536 7.29951 8.4545 7.35755 8.23472 7.5969C8.01494 7.83624 7.39277 8.41341 7.39277 9.58861C7.39277 10.7651 8.25428 11.8992 8.37493 12.0584C8.49623 12.2168 10.0406 14.7075 12.4868 15.6635C14.9344 16.619 14.9344 16.3001 15.3753 16.2596C15.8175 16.2192 16.7996 15.6825 16.9992 15.1255C17.2001 14.566 17.2001 14.0866 17.1394 13.9868Z"
    />

    <!-- attachment icon -->
    <path
      v-else-if="name == 'attachment'"
      d="M16.5 6.74999V17.33C16.5 19.42 14.97 21.28 12.89 21.48C10.5 21.71 8.5 19.84 8.5 17.5V5.13999C8.5 3.82999 9.44 2.63999 10.74 2.50999C12.24 2.35999 13.5 3.52999 13.5 4.99999V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6.74999C11.5 6.33999 11.16 5.99999 10.75 5.99999C10.34 5.99999 10 6.33999 10 6.74999V15.36C10 16.67 10.94 17.86 12.24 17.99C13.74 18.14 15 16.97 15 15.5V5.16999C15 3.07999 13.47 1.21999 11.39 1.01999C9.01 0.789993 7 2.65999 7 4.99999V17.27C7 20.14 9.1 22.71 11.96 22.98C15.25 23.28 18 20.72 18 17.5V6.74999C18 6.33999 17.66 5.99999 17.25 5.99999C16.84 5.99999 16.5 6.33999 16.5 6.74999Z"
    />
    <!-- arrow foward icon -->
    <path
      v-else-if="name == 'arrow-forward'"
      d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.69997C12.32 4.30997 11.69 4.30997 11.3 4.69997C10.91 5.08997 10.91 5.71997 11.3 6.10997L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
    />

    <!-- open in new icon -->
    <path
      v-else-if="name == 'new-tab'"
      d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"
    />

    <!-- chevron up icon -->
    <path
      v-else-if="name == 'chevron-up'"
      d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
    />

    <!-- chevron down icon -->
    <path
      v-else-if="name == 'chevron-down'"
      d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
    />

    <!-- chevron left icon -->
    <path
      v-else-if="name == 'chevron-left'"
      d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
    />

    <!-- arrow down icon -->
    <path
      v-else-if="name == 'arrow-down'"
      d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
    />

    <!-- check icon -->
    <path
      v-else-if="name == 'check'"
      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
    />

    <!-- small check icon -->
    <path
      v-else-if="name == 'check-small'"
      d="M12,2.7l-1.4-1.4L4,7.9L1.4,5.3L0,6.7l4,4L12,2.7z"
    />

    <!-- close icon -->
    <path
      v-else-if="name == 'close'"
      d="m19 6.41l-1.41 -1.41 -5.59 5.59 -5.59 -5.59 -1.41 1.41 5.59 5.59 -5.59 5.59 1.41 1.41 5.59 -5.59 5.59 5.59 1.41 -1.41 -5.59 -5.59 5.59 -5.59z"
    />

    <!-- small close icon -->
    <path
      v-else-if="name == 'close-small'"
      d="M14 3.20857L12.7914 2L8.00002 6.79143L3.20858 2L2 3.20857L6.79144 8.00001L2 12.7914L3.20858 14L8.00002 9.20858L12.7914 14L14 12.7914L9.2086 8.00001L14 3.20857Z  "
    />

    <!-- date range icon -->
    <path
      v-else-if="name == 'date-range'"
      d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2zM19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z"
    />

    <!-- plus icon -->
    <path v-else-if="name == 'plus'" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />

    <!-- minus icon -->
    <path v-else-if="name == 'minus'" d="M19 13H5v-2h14v2z" />

    <!-- info icon -->
    <path
      v-else-if="name == 'info'"
      d="M12 17C11.45 17 11 16.55 11 16L11 12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12L13 16C13 16.55 12.55 17 12 17ZM12.01 22C17.53 22 22 17.52 22 12C22 6.48 17.53 2 12.01 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12.01 22ZM12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4ZM11 7L13 7L13 9L11 9L11 7Z"
    />

    <!-- add person icon -->
    <path
      v-else-if="name == 'add-person'"
      d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
    />

    <!-- remove person icon -->
    <path
      v-else-if="name == 'remove-person'"
      d="M15,12c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S12.8,12,15,12z M1,10v2h8v-2H1z M15,14c-2.7,0-8,1.3-8,4v2h16v-2 C23,15.3,17.7,14,15,14z"
    />

    <!-- house icon -->
    <path
      v-else-if="name == 'house'"
      d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"
    />

    <!-- business building icon -->
    <path
      v-else-if="name == 'business'"
      d="M12 7V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-8zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm9 12h-7v-2h2v-2h-2v-2h2v-2h-2V9h7c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1zm-1-8h-2v2h2v-2zm0 4h-2v2h2v-2z"
    />

    <!-- support icon -->
    <path
      v-else-if="name == 'support'"
      d="m11 4.4c0-1.876 1.5688-3.4 3.5-3.4s3.5 1.524 3.5 3.4c0 1.8-1.2929 3.664-3.2941 4.6v-1.2h-.2059c-1.9312 0-3.5-1.524-3.5-3.4zm3.0882 1.6v.8h.8236v-.8zm.9759-1.092c.0041-.008.0083-.016.0124-.02.112-.15966.2681-.29262.4264-.42742.3651-.31097.7417-.63171.6153-1.31258-.1194-.676-.6753-1.232-1.3711-1.332-.8483-.12-1.5977.388-1.8242 1.116-.07.232.1112.468.3583.468h.0823c.1689 0 .3047-.116.3624-.26.1317-.356.5188-.6.947-.512.3953.08.6836.46.6465.852-.0279.30542-.2525.47434-.5017.66178-.1556.11697-.3207.24115-.4536.41022l-.0041-.004c-.0071.00693-.0129.01654-.0189.0265-.0043.00727-.0088.01474-.014.0215-.0062.01-.0134.02-.0206.03s-.0144.02-.0206.03c-.0371.056-.0659.112-.0906.176-.0021.008-.0062.014-.0103.02s-.0082.012-.0103.02c-.0041.004-.0041.008-.0041.012-.0494.144-.0824.316-.0824.52h.8277c0-.1.0123-.188.0412-.272.0082-.028.0205-.056.0329-.084.0041-.016.0082-.032.0165-.044.0164-.032.037-.064.0576-.096zm3.9359 8.592c0 1.4-1.1 2.5-2.5 2.5s-2.5-1.1-2.5-2.5 1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5zm-7-1.5c0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3 3 1.3 3 3zm-1 8.8c0-1.9 3.7-2.8 5.5-2.8s5.5.9 5.5 2.8v1.2c0 .5-.5 1-1 1h-9c-.6 0-1-.5-1-1zm-9-.3c0-2.3 4.7-3.5 7-3.5.7 0 1.5.1 2.4.3-2.1 1.2-2.4 2.6-2.4 3.5v2.2h-6c-.5 0-1-.5-1-1z"
    />

    <!-- rss icon -->
    <path
      v-else-if="name == 'rss'"
      d="M4 11.63C4 10.78 4.75 10.09 5.59 10.23C9.79 10.9 13.1 14.21 13.78 18.41C13.91 19.25 13.22 20 12.37 20C11.66 20 11.09 19.47 10.97 18.77C10.45 15.85 8.15 13.54 5.23 13.03C4.53 12.91 4 12.34 4 11.63ZM4 5.93C4 5.1 4.73 4.43 5.56 4.52C12.91 5.25 18.75 11.1 19.48 18.44C19.56 19.27 18.9 20 18.06 20C17.33 20 16.73 19.45 16.66 18.72C16.06 12.71 11.28 7.93 5.27 7.33C4.55 7.26 4 6.66 4 5.93ZM8.36 17.82C8.36 19.024 7.38398 20 6.18 20C4.97602 20 4 19.024 4 17.82C4 16.616 4.97602 15.64 6.18 15.64C7.38398 15.64 8.36 16.616 8.36 17.82Z"
    />

    <!-- standard placeholder: question mark -->
    <path
      v-else
      d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="postcss">
svg {
  @apply inline-block flex-shrink-0;
}

.icon path:not(.icon__canvas) {
  fill: currentcolor;
}
</style>
