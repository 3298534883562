<template>
  <article v-if="vorlage" class="event-card my-4 sm:mx-4">
    <div>
      <header class="mb-8">
        <div
          v-if="vorlage.is_shop_item === 0"
          class="flex justify-between text-gray-750 overline"
        >
          <span v-if="vorlage.produktart">{{ vorlage.produktart }}</span>
          <span v-else>&nbsp;</span>
        </div>
        <div v-else class="flex justify-between text-gray-750 overline">
          <span v-if="vorlage.produktart && vorlage.shop_duration > 0">
            {{ vorlage.produktart }}
            ({{ vorlage.nummer.substring(4) }}) (Dauer:
            {{ vorlage.shop_duration }} Min)
          </span>
          <span v-else-if="vorlage.produktart && vorlage.shop_duration === 0">
            {{ vorlage.produktart }}
            ({{ vorlage.nummer.substring(4) }})
          </span>
          <span v-else>&nbsp;</span>
        </div>
        <h3 class="font-semibold text-lg text-dark-blue-900 mt-2 hyphenate">
          <nuxt-link :to="`/${vorlage.slug}`">
            {{ vorlage.titel }}
          </nuxt-link>
        </h3>
      </header>
      <div v-if="vorlage.is_shop_item === 0" class="flex flex-col">
        <div class="event-card__event-locations">
          <LocationDates
            :vorlage="vorlage"
            :veranstaltungen="vorlage.veranstaltungen"
          />
        </div>
      </div>
      <div v-else>
        <p>{{ vorlage.shop_description }}</p>
      </div>
    </div>
    <footer class="flex justify-between items-center mt-12 print:hidden">
      <!-- <ShareIcons class="mr-3" /> -->
      <div class="font-semibold text-green-700 text-lg">
        {{ priceInfo.text }}
        <span v-if="priceInfo.has_price">
          {{ priceInfo.price | currency }}
        </span>
        <span v-if="priceInfo.tax" class="text-sm">
          {{ priceInfo.tax }}
        </span>
      </div>
      <!-- <button
        v-if="checkIfBasket && hasEvents"
        @click="removeEventFromCart(vorlage)"
        class="button button--green relative z-20"
        title="Artikel aus dem Warenkorb entfernen"
      >
        <img src="~/assets/img/cart.svg" class="w-6 h-6" />
      </button>
      <button
        v-if="checkShopItem"
        :key="vorlage.nummer"
        @click="addEventToCart(vorlage)"
        class="button button--gray relative z-20"
      >
        in den Warenkorb
      </button>
      <button
        v-else-if="!checkShopItem && !hasEvents"
        :key="vorlage.nummer"
        @click="removeEventFromCart(vorlage)"
        class="button button--green relative z-20"
      >
        im Warenkorb
      </button> -->
      <nuxt-link
        :to="`/${vorlage.slug}`"
        class="button button--gray relative z-20"
        >Mehr erfahren</nuxt-link
      >
    </footer>
  </article>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import _ from 'lodash'
import LocationDates from '~/components/locations/LocationDates'
import { getPriceInfoByVorlage } from '~/store/cart'

export default {
  components: {
    LocationDates
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    vorlage: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      persistedState: 'persistedState'
    }),
    ...mapGetters({
      itemExist: 'cart/exist'
    }),
    checkIfBasket() {
      return this.itemExist(this.vorlage.nummer)
    },
    checkShopItem() {
      return (
        this.itemExist(this.vorlage.nummer) === false &&
        ((this.vorlage.is_shop_item && this.vorlage.shop_price > 0) ||
          this.lowestPrice > 0 ||
          this.lowestPrice === 0) &&
        !this.hasPriceGroups &&
        !this.hasEvents
      )
    },
    hasEvents() {
      return this.vorlage.veranstaltungen.length > 1
    },
    priceInfo() {
      return getPriceInfoByVorlage(this.vorlage)
    },
    lowestPrice() {
      const group = this.lowestPriceGroup

      if (group) {
        return group.preis_lcy_net
      }

      return 0
    },
    hasPriceGroups() {
      const leistungen = this.vorlage.veranstaltungen
        .map((va) => va.leistungen)
        .filter((l) => l.length > 1)

      return leistungen.length > 0
    },
    lowestPriceGroup() {
      const leistungen = this.vorlage.veranstaltungen.map((va) => va.leistungen)

      const lowestPrice = _(leistungen)
        .flattenDeep()
        .sortBy('preis_lcy_net')
        .head()

      return lowestPrice
    },
    shopUrl() {
      if (this.vorlage.shop_url === null) {
        return `https://www.ebz-akademie.de/shop/${this.vorlage.slug}`
      }

      return this.vorlage.shop_url
    }
  },
  methods: {
    addEventToCart(vorlage) {
      this.$store.commit('cart/add', {
        ...vorlage.veranstaltungen[0],
        shop_duration: vorlage.shop_duration,
        shop_price: vorlage.shop_price,
        shop_nummer: vorlage.nummer,
        vorlage
      })

      this.$store.commit('cart/addNumber', vorlage.nummer)
    },

    removeEventFromCart(vorlage) {
      const index = this.$store.getters['cart/items'].findIndex(
        (item) => item.shop_nummer === vorlage.nummer
      )

      this.$store.commit('cart/remove', {
        index,
        nummer: vorlage.nummer
      })
    }
  }
}
</script>

<style lang="postcss">
.event-card {
  @apply flex flex-col justify-between w-full bg-white shadow p-8;
}

@screen smd {
  .event-card {
    width: calc(50% - 2rem);
  }
}

@screen xxl {
  .event-card {
    width: calc(100% / 3 - 2rem);
  }
}
</style>
