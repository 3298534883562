<template>
  <div>
    <h3>
      Buchen Sie die Fortbildung für ein Unternehmen oder als Privatperson?
    </h3>

    <div class="mt-4 flex flex-col">
      <div class="flex items-center">
        <input
          id="company"
          v-model="type"
          class="cursor-pointer"
          type="radio"
          value="company"
          @input="$v.type.$touch()"
        />
        <label
          for="company"
          :class="{ 'text-red-500': $v.type.$error }"
          class="cursor-pointer pl-1"
        >
          Unternehmen
        </label>
      </div>

      <div class="mt-3 flex items-center">
        <input
          id="person"
          v-model="type"
          class="cursor-pointer"
          type="radio"
          value="person"
          @input="$v.type.$touch()"
        />
        <label
          for="person"
          :class="{ 'text-red-500': $v.type.$error }"
          class="cursor-pointer pl-1"
        >
          Privatperson
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  components: {},
  data() {
    return {
      type: '',
      priceGroup: ''
    }
  },
  validations: {
    type: {
      required
    },
    form: ['type']
  },
  computed: {},
  methods: {
    validate() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    }
  }
}
</script>
