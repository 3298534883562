<template>
  <div>
    <div class="container pt-6 pb12">
      <nuxt-link
        :to="
          '../' +
            this.$route.params.slug +
            '?termin=' +
            this.$route.query.termin
        "
        class="button mb-8"
      >
        zurück
      </nuxt-link>
      <section>
        <span class="overline inline-block flex items-center h-10 mb-2">
          {{ vorlage.produktart }}
        </span>
        <h1 class="mb-8">{{ vorlage.titel }}</h1>

        <!-- <h3>Anmeldung für folgenden Termin:</h3> -->
        <div class="mt-4 mb-12 flex">
          <div>
            <h4>{{ selectedVeranstaltung.region }}</h4>
            <div>
              {{
                [selectedVeranstaltung.start_datum, 'YYYY-MM-DD']
                  | moment('DD.MM.YYYY')
              }}
              -
              {{
                [selectedVeranstaltung.end_datum, 'YYYY-MM-DD']
                  | moment('DD.MM.YYYY')
              }}
            </div>
          </div>
        </div>
      </section>
    </div>
    <section
      class="mt-12 mt-12 pt-6 pb-12 bg-white border-t border-dark-gray-600"
    >
      <div class="container mx-auto">
        <Anmeldung
          :vorlage="vorlage"
          :veranstaltung="selectedVeranstaltung"
          :processing="processing"
          @on-complete="submit"
        />
      </div>
    </section>
  </div>
</template>

<script>
/* global fbq */

import { mapGetters } from 'vuex'
import { groupBy, orderBy } from 'lodash'
import Anmeldung from '~/components/forms/Anmeldung'

export default {
  middleware: 'ensure-va-selected',
  components: {
    Anmeldung
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'robots',
          name: 'robots',
          content: `noindex,nofollow`
        }
      ]
    }
  },
  data() {
    return {
      processing: false
    }
  },
  computed: {
    ...mapGetters({
      getVorlageBySlug: 'veranstaltungsvorlagen/getVorlageBySlug'
    }),
    isLoading() {
      return this.$store.state.veranstaltungsvorlagen.isLoading
    },
    vorlage() {
      return this.getVorlageBySlug(this.$route.params.slug)
    },
    selectedVeranstaltung() {
      const termin = this.$route.query.termin

      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    },
    lowestPrice() {
      // Find lowest price in set of veranstaltungen
      return this.vorlage.veranstaltungen
        .map((va) => va.leistungen)
        .filter((leistung) => leistung !== null)
        .map((leistung) => leistung.preis_lcy_net)
        .sort((a, b) => a - b)
        .shift()
    },
    module() {
      const module =
        this.selectedVeranstaltung && this.selectedVeranstaltung.module

      const moduleObject = groupBy(
        orderBy(module, ['region'], ['asc']),
        'region'
      )

      return Object.keys(moduleObject).map((key) => [key, moduleObject[key]])
    }
  },
  async fetch({ store, params }) {
    await store.dispatch('veranstaltungsvorlagen/get', params.slug)
  },
  methods: {
    log(x) {
      console.log(x)
    },
    async submit(veranstaltung, model) {
      const data = {}

      this.processing = true

      if (model.type === 'company') {
        Object.assign(data, {
          companyRegistration: true,
          location: model.location ?? '',
          companyName: model.company,
          companyContactFirstName: model.companyContactFirstName,
          companyContactLastName: model.companyContactLastName,
          companyContactEmail: model.companyContactEmail,
          companyContactPhone: model.companyContactPhone,
          companyAddress: model.companyAddress,
          companyZip: model.companyZip,
          companyCity: model.companyCity,
          participants: model.participants ?? []
        })
      } else {
        Object.assign(data, {
          companyRegistration: false,
          location: model.location ?? '',
          privateJobTitle: model.privateJobTitle ?? '',
          privateSalutation: model.privateSalutation,
          privateFirstname: model.firstName,
          privateLastname: model.lastName,
          privateEmail: model.email,
          privateAddress: model.address,
          privateBirthday: model.birthday,
          privateZip: model.zip,
          privateCity: model.city,
          privatePhone: model.phone,
          privateBemerkung: model.privateBemerkung,
          privatePriceGroup: model.priceGroup ?? null
        })
      }

      Object.assign(data, {
        // mitgliedschaften:
        //   (model.mitgliedschaften &&
        //     model.mitgliedschaften.length &&
        //     model.mitgliedschaften.map((m) => m.desc)) ||
        //   [],
        rabattCode: model.rabattCode ?? '',
        branche: model.branche && model.branche.code,
        firmengroesse: model.firmengroesse && model.firmengroesse.code,
        anzahl_wohneinheiten: model.wohneinheiten && model.wohneinheiten.code,
        follow_blog: model.followBlog ?? false,
        newsletter: model.newsletter ?? false,
        newsletter_topics: model.newsletter_topics ?? [],
        newsletter_post: model.newsletter_post ?? false
      })

      try {
        const transaction = await this.$axios.post(
          `/veranstaltungen/${veranstaltung.nummer}/register`,
          data
        )

        if (typeof fbq !== 'undefined') {
          const track = {
            value:
              (data && data.preisgruppe && data.preisgruppe.preis_lcy_net) || 0,
            currency: 'EUR',
            content_ids: this.selectedVeranstaltung.nummer ?? null,
            content_type: 'product'
          }

          fbq('track', 'Purchase', track)
        }

        try {
          this.$gtag.purchase(transaction.data)
        } catch (e) {}

        // this.$gtag.purchase({
        //   transaction_id: (transaction.data && transaction.data.id) ?? null,
        //   value:
        //     (data && data.preisgruppe && data.preisgruppe.preis_lcy_net) ?? 0,
        //   currency: 'EUR',
        //   items: [
        //     {
        //       id: this.selectedVeranstaltung.nummer ?? null,
        //       name: this.vorlage.titel ?? null,
        //       category: this.vorlage.produktart ?? null,
        //       quantity: 1,
        //       price:
        //         (data && data.preisgruppe && data.preisgruppe.preis_lcy_net) ??
        //         0
        //     }
        //   ]
        // })

        this.processing = false

        this.$router.replace({
          path: `/${this.vorlage.slug}/anmeldung_bestaetigung`,
          query: { termin: veranstaltung.nummer }
        })
      } catch (error) {
        console.error(error)

        // Report to bugsnag
        this.$bugsnag.notify(error, {
          metaData: {
            axios: {
              url: `/veranstaltungen/${veranstaltung.nummer}/register`,
              method: 'POST',
              data
            }
          }
        })

        this.$router.replace({
          path: `/${this.vorlage.slug}/anmeldung_fehler`,
          query: { termin: veranstaltung.nummer }
        })
      }
    }
  }
}
</script>

<style lang="postcss"></style>
