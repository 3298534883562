<template>
  <div>
    <div class="container pt-6 pb12">
      <a class="button mb-8" @click="$router.go(-1)">
        zurück zum Warenkorb
      </a>
      <section>
        <h1 class="mb-8">Bestellung abschließen</h1>
      </section>
    </div>
    <section class="mt-12 pt-6 pb-12 bg-white border-t border-dark-gray-600">
      <div class="container mx-auto">
        <Anmeldung
          :cart="cartItems"
          :processing="processing"
          @on-complete="submit"
        />
      </div>
    </section>
  </div>
</template>

<script>
/* global fbq */

import Anmeldung from '~/components/cart/Anmeldung'

export default {
  // middleware: 'ensure-va-selected',
  components: {
    Anmeldung
  },
  data() {
    return {
      processing: false
    }
  },
  computed: {
    cartItems() {
      return this.$store.getters['cart/items']
    }
  },
  methods: {
    async submit(model) {
      const data = {}

      this.processing = true

      if (model.type === 'company') {
        Object.assign(data, {
          companyRegistration: true,
          location: model.location ?? '',
          companyName: model.company,
          companyContactFirstName: model.companyContactFirstName,
          companyContactLastName: model.companyContactLastName,
          companyContactEmail: model.companyContactEmail,
          companyContactPhone: model.companyContactPhone,
          companyAddress: model.companyAddress,
          companyContactNumber: model.companyContactNumber,
          companyNumber: model.companyNumber,
          companyZip: model.companyZip,
          companyCity: model.companyCity,
          billingCompany: model.billingCompany,
          billingName: model.billingName,
          billingFirstName: model.billingFirstName,
          billingLastName: model.billingLastName,
          billingEmail: model.billingEmail,
          billingAddress: model.billingAddress,
          billingZip: model.billingZip,
          billingCity: model.billingCity,
          participants: model.participants ?? []
        })
      } else {
        Object.assign(data, {
          companyRegistration: false,
          location: model.location ?? '',
          privateJobTitle: model.privateJobTitle ?? '',
          privateSalutation: model.privateSalutation,
          privateFirstname: model.firstName,
          privateLastname: model.lastName,
          privateEmail: model.email,
          privateAddress: model.address,
          privateBirthday: model.birthday,
          privateZip: model.zip,
          privateCity: model.city,
          privateBemerkung: model.privateBemerkung,
          participantNumber: model.participantNumber
        })
      }

      Object.assign(data, {
        rabattCode: model.rabattCode ?? '',
        branche: model.branche && model.branche.code,
        firmengroesse: model.firmengroesse && model.firmengroesse.code,
        anzahl_wohneinheiten: model.wohneinheiten && model.wohneinheiten.code,
        follow_blog: model.followBlog ?? false,
        newsletter: model.newsletter ?? false,
        newsletter_topics: model.newsletter_topics ?? [],
        newsletter_post: model.newsletter_post ?? false,
        cart: this.cartItems,
        paymentMethod: model.paymentMethod
      })

      try {
        const transaction = await this.$axios.post(`/cart/register`, data)

        if (typeof fbq !== 'undefined') {
          const track = {
            value: 0,
            currency: 'EUR',
            content_ids: null,
            content_type: 'product'
          }

          fbq('track', 'Purchase', track)
        }

        try {
          this.$gtag.purchase(transaction.data)
        } catch (e) {}

        this.processing = false
        console.log(transaction.data)
        this.$store.commit('cart/reset')
        this.$store.commit(
          'payment/addPaymentUrl',
          transaction.data.payment_url
        )

        this.$router.replace({ path: `/shopping_cart/anmeldung_bestaetigung` })
      } catch (error) {
        console.error(error)

        // Report to bugsnag
        this.$bugsnag.notify(error, {
          metaData: {
            axios: {
              url: `/cart/register`,
              method: 'POST',
              data
            }
          }
        })

        this.$router.replace({ path: `/shopping_cart/anmeldung_fehler` })
      }
    }
  }
}
</script>
