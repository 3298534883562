<template>
  <client-only placeholder="Lädt">
    <div class="datepicker flex flex-col">
      <div class="flex justify-between h-6">
        <label for="date">Zeitraum</label>
        <div
          v-if="value && value.start && value.end"
          class="flex items-center justify-center bg-dark-blue-900 text-gray-100 text-sm font-semibold rounded h-6 pl-2 pr-1 leading-none"
        >
          <span class="whitespace-no-wrap text-sm">eingegrenzt</span>
          <button
            class="filter-reset transition"
            type="button"
            @click="clearSelection"
          ></button>
        </div>
      </div>
      <v-date-picker
        :value="value"
        mode="range"
        is-expanded
        class="relative z-50"
        title-position="left"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        :input-props="{
          class: 'datepicker__input-field',
          placeholder: 'Auswählen'
        }"
        @input="onChange"
      />
    </div>
  </client-only>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  methods: {
    onChange(value) {
      this.$emit('on-change', value)
    },
    clearSelection() {
      this.$emit('on-clear-selection')
    }
  }
}
</script>

<style lang="postcss">
.filter-reset {
  @apply p-0 flex items-center p-1 ml-1 relative rounded;
}

button.filter-reset:hover {
  @apply bg-dark-blue-800;
}

button.filter-reset:focus {
  @apply bg-dark-blue-700 outline-none;
}

button.filter-reset:hover svg,
button.filter-reset:focus svg {
  @apply text-white;
}

.datepicker__input-field,
.datepicker input {
  @apply h-10 rounded pl-4 pr-4 mt-2 w-full shadow border border-white appearance-none text-dark-blue-800;
  @apply text-sm;
}

.datepicker__input-field::placeholder {
  @apply text-gray-600 opacity-100;
}

.datepicker__input-field:focus {
  @apply border-dark-blue-900;
}

.vc-popover-content {
  transition: 0s !important;
}

span.vc-popover-caret {
  @apply invisible;
}

.vc-popover-content {
  @apply shadow-lg border-0;
  margin-left: 0.125rem;
}

.vc-popover-content .vc-popover-content {
  @apply bg-dark-blue-900 shadow;
}

/* datepicker fixes for Internet Explorer */
.vc-grid-container {
  display: -ms-grid;
}

.vc-header {
  padding: 10px 10px 0 10px;
}

.vc-weekday {
  padding: 5px 0;
}

.vc-day-content {
  width: 28px;
  height: 28px;
  margin: 1.6px auto;
}

.vc-highlight {
  width: 28px;
  height: 28px;
}
</style>
