<template>
  <div>
    <section class="mt-12 pt-12 pb-32 bg-white border-t border-dark-gray-600">
      <div class="container">
        <h2 class="text-green-700 mb-8">
          Ihre Zahlung ist bei uns eingegangen
        </h2>
        <p class="mb-4">
          Lorem Ipsum Text und so kommt hierhin. Lorem Ipsum Text und so kommt
          hierhin.
        </p>
        <p class="mb-4">
          Lorem Ipsum Text und so kommt hierhin.
        </p>
        <p class="mb-4">
          Gerne stehen wir Ihnen unter
          <a href="mailto:akademie@e-b-z.de">akademie@e-b-z.de</a> oder
          <a href="tel:+492349447575">+49 234 9447-575</a>
          für Rückfragen zur Verfügung.
        </p>
        <p class="mb-8">
          <i>Ihr Team der EBZ-Akademie</i>
        </p>
        <nuxt-link to="/" class="button">zur Startseite</nuxt-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'robots',
          name: 'robots',
          content: `noindex,nofollow`
        }
      ]
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="postcss">
.icon-bg {
  @apply w-16 h-16 bg-no-repeat bg-center bg-contain mb-4;
}

.icon-bg--company {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3Cpath fill='%23d5d5d5' d='M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z'/%3E%3C/svg%3E");
}
.icon-bg--person {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3Cpath fill='%23d5d5d5' d='M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E");
}
</style>
