export const state = () => ({
  paymentMethods: [],
  paymentMethod: null,
  paymentUrl: null
})

export const getters = {
  getMethods: (state) => {
    return state.paymentMethods
  },
  getPaymentMethod: (state) => {
    return state.paymentMethod
  },
  getPaymentUrl: (state) => {
    return state.paymentUrl
  }
}

export const mutations = {
  restore(state, data) {
    state.paymentMethods = data
  },
  addPaymentMethod(state, data) {
    state.paymentMethod = data
  },
  addPaymentUrl(state, data) {
    state.paymentUrl = data
  },
  resetPaymentMethods(state) {
    state.paymentMethods = []
  }
}

export const actions = {
  async getAll({ commit }, payload) {
    await this.$axios.get(`/payment/methods?1${payload}`).then(({ data }) => {
      commit('restore', data)
    })
  },
  resetPaymentMethods({ commit }) {
    commit('resetPaymentMethods')
  }
}
