import _ from 'lodash'

export const state = () => ({
  cardItems: [],
  itemNumbers: []
})

export const getItemPrice = (items, ust = false) => {
  return items.reduce((sum, item) => {
    if (item.vorlage.is_shop_item === 1) {
      return sum + item.shop_price
    }

    if (!ust && item.vorlage.is_shop_item === 0) {
      if (item.vorlage.is_special_event) {
        const priceGroup = _(item.vorlage.veranstaltungen[0].leistungen)
          .filter((p) => p.preisgruppencode === 'SP8')
          .head()

        return priceGroup.preis_lcy_net
      }

      const leistungen = item.vorlage.veranstaltungen.map((va) => va.leistungen)

      const lowestPrice = _(leistungen)
        .flattenDeep()
        .sortBy('preis_lcy_net')
        .head()

      return sum + lowestPrice.preis_lcy_net
    }

    return sum + 0
  }, 0)
}

export const getUstPrice = (items) => {
  return (getItemPrice(items, true) / 100) * 19
}

/**
 * @typedef {Object} PriceInfo
 * @property {boolean} has_price - Indicates whether there is a numeric price
 * @property {number} price - The numeric price, if any (see `has_price`). Needs to be formatted as currency
 * @property {string} text - Either a prefix for the numeric price (like "ab" (from))
 * or a text that should be displayed instead of a numeric price like "kostenlos" (free), or "auf Anfrage" (on request)
 * @property {string} tax - Tax info like whether the price includes VAT, usually displayed in a smaller font size
 * after the numeric price
 */

/**
 * Get structured price info from a `vorlage` object
 * @param {Object} vorlage a `vorlage` template object
 * @returns {PriceInfo}
 */
export const getPriceInfo = (vorlage) => {
  function getSpecialEventPrice() {
    const priceGroup = _(vorlage.veranstaltungen[0].leistungen)
      .filter((p) => p.preisgruppencode === 'SP8')
      .head()

    return priceGroup.preis_lcy_net
  }

  function findLowestPriceGroup() {
    const leistungen = vorlage.veranstaltungen.map((va) => va.leistungen)

    return _(leistungen)
      .flattenDeep()
      .sortBy('preis_lcy_net')
      .head()
  }

  function amountOfLeistungen() {
    const leistungen = vorlage.veranstaltungen.map((va) => va.leistungen)

    return _(leistungen)
      .flattenDeep()
      .size()
  }

  const price = {
    has_price: false,
    text: '',
    price: 0,
    tax: ''
  }

  if (vorlage.is_shop_item === 0) {
    const lowestPriceGroup = findLowestPriceGroup()
    if (lowestPriceGroup && vorlage.is_special_event === 0) {
      if (lowestPriceGroup.length > 1) {
        price.text = 'ab'
      }

      if (lowestPriceGroup.preis_lcy_net === 0) {
        if (amountOfLeistungen() <= 1) {
          price.text = 'kostenlos'
        } else {
          price.text = 'ab kostenlos'
        }
      } else {
        price.price = lowestPriceGroup.preis_lcy_net
        price.has_price = true
      }
    } else if (vorlage.is_special_event === 1) {
      price.price = getSpecialEventPrice()
      price.has_price = true
    } else {
      price.text = 'auf Anfrage'
    }
  } else if (vorlage.shop_price > 0) {
    price.price = vorlage.shop_price
    price.has_price = true
    price.tax = 'zzgl. USt'
  } else {
    price.text = 'auf Anfrage'
  }

  return price
}

export const getters = {
  amount: (state) => {
    return state.cardItems.length
  },

  exist: (state) => (id) => {
    return state.itemNumbers.includes(id)
  },

  getNumbers: (state) => {
    return state.itemNumbers
  },

  items: (state) => {
    return state.cardItems
  },

  getEventNumbers: (state) => {
    return state.cardItems.map((i) => i.nummer)
  },

  shopItems: (state) => {
    return state.cardItems.filter((i) => i.vorlage.is_shop_item)
  },

  nonShopItems: (state) => {
    return state.cardItems.filter((i) => !i.vorlage.is_shop_item)
  },

  ustPrice(state) {
    return getUstPrice(state.cardItems)
  },

  shopItemPrice(state) {
    return getItemPrice(state.cardItems, true)
  },

  itemPrice(state) {
    return getItemPrice(state.cardItems, false)
  },

  completePrice(state) {
    return getItemPrice(state.cardItems) + getUstPrice(state.cardItems)
  }
}

export const mutations = {
  add(state, data) {
    state.cardItems.push(data)
  },

  addNumber(state, data) {
    state.itemNumbers.push(data)
  },

  reset(state) {
    state.cardItems = []
    state.itemNumbers = []
  },

  remove(state, data) {
    state.cardItems.splice(
      state.cardItems.findIndex((item) => item.shop_nummer === data.nummer),
      1
    )

    state.itemNumbers.splice(
      state.itemNumbers.findIndex((item) => item === data.nummer),
      1
    )
  }
}
