<template>
  <table v-if="regionen.length > 0" class="w-full">
    <thead>
      <tr>
        <th
          v-if="vorlage.is_special_event === 0"
          class="text-gray-750 overline text-left p-2"
        >
          Ort{{ regionen.length > 1 ? 'e' : '' }}
        </th>
        <th class="text-gray-750 overline text-left p-2">Termin(e)</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="region in regionen"
        :key="region"
        :title="region"
        class="event-date-row"
      >
        <td
          v-if="vorlage.is_special_event === 0"
          class="overline text-gray-800 pr-4"
        >
          {{ region }}
        </td>
        <td>
          <ul
            v-if="vorlage.is_special_event === 0"
            class="text-blue-700 overline"
          >
            <li
              v-for="va in eventsByRegion(region)"
              :key="va.nummer"
              class="mb-1"
            >
              <nuxt-link
                v-if="va.start_datum"
                :to="`/${vorlage.slug}?termin=${va.nummer}`"
                class="text-blue-700 hover:no-underline hover:text-dark-blue-900"
              >
                {{ [va.start_datum, 'YYYY-MM-DD'] | moment('DD.MM.YYYY') }}
                <span v-if="va.end_datum !== va.start_datum">
                  - {{ [va.end_datum, 'YYYY-MM-DD'] | moment('DD.MM.YYYY') }}
                </span>
                <span
                  v-for="(termin, index) in va.termine"
                  v-else-if="
                    va.is_available_online && va.end_datum === va.start_datum
                  "
                  :key="index"
                >
                  | {{ [termin.zeit_von, 'HH:mm:ss'] | moment('HH:mm') }} –
                  {{ [termin.zeit_bis, 'HH:mm:ss'] | moment('HH:mm') }} Uhr
                </span>
              </nuxt-link>
            </li>
          </ul>
          <ul
            v-if="vorlage.is_special_event === 1"
            class="text-blue-700 overline"
          >
            <li
              v-for="va in vorlage.veranstaltungen[0].module"
              :key="va.nummer"
              class="mb-1"
            >
              <nuxt-link
                v-if="va.start_datum"
                :to="`/${vorlage.slug}?termin=${va.nummer}`"
                class="text-blue-700 hover:no-underline hover:text-dark-blue-900"
              >
                <span>
                  {{ eventDate(va.start_datum) }}
                </span>
              </nuxt-link>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'

export default {
  components: {},
  props: {
    vorlage: {
      type: Object,
      default: () => {}
    },
    veranstaltungen: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    regionen() {
      let regionen = this.veranstaltungen.map((va) => va.region)
      // Make unique set
      regionen = [...new Set(regionen)]

      return regionen
    },

    lowestPrice() {
      // Find the lowest price in a set of veranstaltungen
      return this.veranstaltungen
        .map((va) => va.leistungen)
        .filter((leistung) => leistung !== null)
        .map((leistung) => leistung.preis_lcy_net)
        .sort((a, b) => a - b)
        .shift()
    }
  },
  methods: {
    eventsByRegion(region) {
      return this.veranstaltungen.filter((va) => va.region === region)
    },
    eventDate(date) {
      moment.locale('de')

      return moment(date).format('MMMM Y')
    }
  }
}
</script>

<style lang="postcss">
.event-date-row {
  td {
    @apply p-2 align-top;
  }
}

.event-date-row:nth-child(odd) {
  td {
    @apply bg-gray-200 p-2;

    &:first-child {
      @apply rounded-tl rounded-bl;
    }
    &:last-child {
      @apply rounded-tr rounded-br;
    }
  }
}
</style>
