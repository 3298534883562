<template>
  <div>
    <div>
      <h3 class="mb-6">Privatperson</h3>
      <div class="InputContainer w-full md:w-1/3">
        <select
          v-model="privateSalutation"
          name="salutation"
          required
          :class="{
            Input: true,
            'Input--Error': $v.privateSalutation.$error
          }"
          class="bg-white cursor-pointer focus:outline-none"
          @select="$v.privateSalutation.$touch()"
        >
          <option value="" disabled>Auswählen</option>
          <option value="Frau">Frau</option>
          <option value="Herr">Herr</option>
          <option value="-">Keine Angabe</option>
        </select>
        <label for="privateSalutation">Anrede</label>
      </div>

      <div class="mt-6">
        <div class="InputContainer w-full md:w-1/3">
          <select
            v-model="privateJobTitle"
            name="privateJobTitle"
            :class="{
              Input: true,
              'Input--Error': $v.privateJobTitle.$error
            }"
            class="bg-white cursor-pointer focus:outline-none"
            @select="$v.privateJobTitle.$touch()"
          >
            <option value="">keine Angabe</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Prof. Dr.">Prof. Dr.</option>
          </select>
          <label for="privateJobTitle">Titel</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="firstName"
            type="text"
            name="firstName"
            :class="{
              Input: true,
              'Input--Error': $v.firstName.$error
            }"
            required
            @input="$v.firstName.$touch()"
          />
          <label for="firstName">Vorname</label>
        </div>

        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="lastName"
            type="text"
            name="lastName"
            :class="{
              Input: true,
              'Input--Error': $v.lastName.$error
            }"
            required
            @input="$v.lastName.$touch()"
          />
          <label for="lastName">Nachname</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="email"
            type="text"
            name="email"
            :class="{
              Input: true,
              'Input--Error': $v.email.$error
            }"
            required
            @input="$v.email.$touch()"
          />
          <label for="email">E-Mail-Adresse</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="birthday"
            type="text"
            name="birthday"
            :class="{
              Input: true,
              'Input--Error': $v.participantNumber.$error
            }"
            @input="$v.participantNumber.$touch()"
          />
          <label for="participantNumber">Geburtstag</label>
        </div>
        <!-- <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="participantNumber"
            type="text"
            name="participantNumber"
            :class="{
              Input: true,
              'Input--Error': $v.participantNumber.$error
            }"
            @input="$v.participantNumber.$touch()"
          />
          <label for="participantNumber">Teilnehmernummer</label>
        </div> -->
      </div>
      <h3 class="mt-6">Rechnungsanschrift</h3>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="address"
            type="text"
            name="address"
            required
            :class="{
              Input: true,
              'Input--Error': $v.address.$error
            }"
            @input="$v.address.$touch()"
          />
          <label for="address">Straße / Hausnummer</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="zip"
            type="text"
            name="zip"
            required
            :class="{
              Input: true,
              'Input--Error': $v.zip.$error
            }"
            @input="$v.zip.$touch()"
          />
          <label for="zip">PLZ</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="city"
            type="text"
            name="city"
            required
            :class="{
              Input: true,
              'Input--Error': $v.city.$error
            }"
            @input="$v.city.$touch()"
          />
          <label for="city">Ort</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="privateBemerkung"
            type="text"
            name="privateBemerkung"
            :class="{
              Input: true,
              'Input--Error': $v.privateBemerkung.$error
            }"
            @input="$v.privateBemerkung.$touch()"
          />
          <label for="privateBemerkung">Bemerkungen (max. 50 Zeichen)</label>
        </div>
      </div>

      <h3 class="mt-12 mb-3">Rabatt-Code</h3>
      <p>
        Falls Sie einen Rabatt-Code erhalten haben, geben Sie diesen bitte in
        das folgende Feld ein.
      </p>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="rabattCode"
            type="text"
            name="rabattCode"
            placeholder="Ihr Rabatt-Code"
            :class="{
              Input: true,
              'Input--Error': $v.rabattCode.$error
            }"
            @input="$v.rabattCode.$touch()"
          />
          <!-- <label for="rabattCode">Rabatt-Code</label> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  email,
  requiredIf,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      privateJobTitle: '',
      privateSalutation: '',
      firstName: '',
      lastName: '',
      email: '',
      participantNumber: '',
      birthday: '',
      address: '',
      zip: '',
      city: '',
      privateBemerkung: '',
      rabattCode: ''
    }
  },
  computed: {
    isPerson() {
      return this.type === 'person'
    }
  },
  validations: {
    privateJobTitle: {},
    privateSalutation: {
      required: requiredIf('isPerson')
    },
    firstName: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    lastName: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    email: {
      required: requiredIf('isPerson'),
      email
    },
    participantNumber: {
      minLength: minLength(1)
    },
    birthday: {},
    address: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    zip: {
      required: requiredIf('isPerson'),
      minLength: minLength(4),
      maxLength: maxLength(6)
    },
    city: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    privateBemerkung: {
      maxLength: maxLength(50)
    },
    rabattCode: {}
  },
  mounted() {},
  methods: {
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    }
  }
}
</script>
