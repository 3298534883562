import { head, find } from 'lodash'

export default async ({ route, redirect, store }) => {
  // const router = store.app.router
  // const route = store.state.route
  const slug = route.params.slug
  const termin = route.query.termin

  // console.log('mw/ensure-va-selected', { slug, termin })

  await store.dispatch('veranstaltungsvorlagen/get', slug)

  const getVorlageBySlug =
    store.getters['veranstaltungsvorlagen/getVorlageBySlug']
  const vorlage = await getVorlageBySlug(slug)

  if (!vorlage) {
    return
  }

  let firstVeranstaltung = null

  if (vorlage.is_special_event === 1) {
    firstVeranstaltung = head(vorlage.veranstaltungen[0].module)
  } else {
    firstVeranstaltung = head(vorlage.veranstaltungen)
  }

  // Keine Veranstaltung in Vorlage gefunden
  if (!firstVeranstaltung) {
    redirect('/')
  }

  // Kein Termin vorselektiert
  if (!termin) {
    const newParams = Object.assign({}, route.query, {
      termin: firstVeranstaltung.nummer
    })

    redirect(route.path, newParams)
  }

  if (vorlage.is_special_event === 1) {
    // Selektierter Termin wurde in Vorlage nicht gefunden
    if (!find(vorlage.veranstaltungen[0].module, { nummer: termin })) {
      redirect(
        route.path,
        Object.assign({}, route.query, {
          termin: firstVeranstaltung.nummer
        })
      )
    }
  } else {
    // prevent infinity redirect for special event
    const nummer = termin

    // Selektierter Termin wurde in Vorlage nicht gefunden
    if (!find(vorlage.veranstaltungen, { nummer })) {
      redirect(
        route.path,
        Object.assign({}, route.query, {
          termin: firstVeranstaltung.nummer
        })
      )
    }
  }
}
