<template>
  <div v-if="is_open" class="basket-popup__backdrop" @click.self="close">
    <div class="basket-popup" role="dialog" aria-modal="true">
      <button class="basket-popup__close-button">
        <img
          src="~/assets/img/close.svg"
          class="w-5 h-5"
          alt="X"
          @click.self="close"
        />
      </button>
      <header>
        <h3>Folgendes wurde dem Warenkorb hinzugefügt</h3>
      </header>
      <section>
        <p class="font-semibold text-lg text-gray-ebz">{{ item.titel }}</p>
        <p v-if="item.vorlage.is_shop_item === 1" class="text-gray-750 text-sm">
          {{ item.nummer }} - Dauer: {{ item.vorlage.shop_duration }} Min
        </p>
        <p v-else-if="item.start_datum" class="text-gray-750 text-sm">
          {{ item.nummer }} - {{ item.region }} - Startdatum:
          {{ item.start_datum | moment('DD.MM.YYYY') }}
        </p>

        <div class="font-semibold text-green-700 text-lg">
          {{ priceInfo.text }}
          <span v-if="priceInfo.has_price">
            {{ priceInfo.price | currency }}
          </span>
          <span v-if="priceInfo.tax" class="text-sm text-gray-750 font-normal">
            {{ priceInfo.tax }}
          </span>
        </div>
      </section>
      <footer>
        <button class="button button--gray" @click.self="close">
          Schließen
        </button>
        <nuxt-link to="/shopping_cart" class="button button--green">
          zum Warenkorb
        </nuxt-link>
      </footer>
    </div>
  </div>
</template>

<script>
import EventBus from '~/events/EventBus'
import { getPriceInfo } from '~/store/cart'

export default {
  head() {
    if (this.is_open) {
      return {
        bodyAttrs: {
          class: ['relative', 'overflow-hidden']
        }
      }
    }
  },
  data() {
    return {
      is_open: false,
      item: {}
    }
  },
  computed: {
    priceInfo() {
      return getPriceInfo(this.item.vorlage)
    }
  },
  destroyed() {
    EventBus.$off('basket:item-added', this.setItem)
  },
  mounted() {
    this.$nextTick(
      function() {
        EventBus.$on('basket:item-added', this.setItem)
      }.bind(this)
    )
  },
  methods: {
    close() {
      this.is_open = false
    },
    setItem(item) {
      this.item = item
      this.open()
    },
    open() {
      this.is_open = true
    }
  }
}
</script>

<style lang="postcss">
.basket-popup__backdrop {
  @apply fixed top-0 left-0 w-full h-full flex items-center justify-center z-50;
  background: rgba(0, 0, 0, 0.4);
}

.basket-popup {
  @apply bg-white max-w-3xl p-8 pt-10 pr-12 relative;
}

.basket-popup__close-button {
  @apply absolute;
  top: 1rem;
  right: 1rem;
}

.basket-popup section {
  @apply my-8;
}
.basket-popup footer {
  @apply flex justify-between;
}
</style>
