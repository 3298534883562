/* eslint-disable */

export default ({ app }) => {

    /*
     ** Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production')
      return

    /*
      // get consent state
      const consentAdsComfort = localStorage.getItem('GDPR:adscomfort');
      // if consent not given, abort
      if (consentAdsComfort !== 'true') return
    */

    /*
    ** Include Script
    */
    window._paq = window._paq = window._paq || [];
    ;(function() {
        var u="https://matomo.e-b-z.de/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '20']);
        _paq.push(['disableCookies']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src='https://matomo.e-b-z.de/matomo.js'; s.parentNode.insertBefore(g,s);
    })()

    /*
     ** Every time the route changes (fired on initialization too)
     */
    app.router.afterEach((to, from) => {
      _paq.push(['setCustomUrl', window.location.href]);
      _paq.push(['setDocumentTitle', document.title]);
      _paq.push(['trackPageView']);
    })
  }

