<template>
  <form-wizard
    color="#002343"
    title
    subtitle
    step-size="sm"
    shape="tab"
    @on-complete="onComplete"
  >
    <tab-content title="Allgemein" :before-change="() => validateStep('step1')">
      <AnmeldungStepOne
        ref="step1"
        :vorlage="vorlage"
        @on-validate="mergePartialModels"
      />
    </tab-content>
    <tab-content
      title="Teilnehmer"
      :before-change="() => validateStep('step2')"
    >
      <AnmeldungStepTwoCompany
        v-if="finalModel.type === 'company'"
        ref="step2"
        :vorlage="vorlage"
        :type="finalModel.type"
        @on-validate="mergePartialModels"
      />

      <AnmeldungStepTwoPerson
        v-if="finalModel.type === 'person'"
        ref="step2"
        :vorlage="vorlage"
        :type="finalModel.type"
        @on-validate="mergePartialModels"
      />
    </tab-content>
    <tab-content
      title="Ergänzende Angaben"
      :before-change="() => validateStep('step3')"
    >
      <AnmeldungStepThree
        ref="step3"
        :type="finalModel.type"
        @on-validate="mergePartialModels"
      />
    </tab-content>
    <tab-content title="Buchung" :before-change="() => validateStep('step4')">
      <AnmeldungStepFour
        ref="step4"
        :type="finalModel.type"
        :model="finalModel"
        :vorlage="vorlage"
        :veranstaltung="veranstaltung"
        :processing="processing"
        @on-validate="mergePartialModels"
      />
    </tab-content>
    <button slot="prev" class="button">zurück</button>
    <button slot="next" class="button">weiter</button>
    <button slot="finish" class="button button--green" :disabled="processing">
      kostenpflichtig anmelden
    </button>
    <template v-if="processing" slot="footer"
      >&nbsp;</template
    >
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import AnmeldungStepOne from '~/components/forms/AnmeldungStepOne'
import AnmeldungStepTwoCompany from '~/components/forms/AnmeldungStepTwoCompany'
import AnmeldungStepTwoPerson from '~/components/forms/AnmeldungStepTwoPerson'
import AnmeldungStepThree from '~/components/forms/AnmeldungStepThree'
import AnmeldungStepFour from '~/components/forms/AnmeldungStepFour'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    AnmeldungStepOne,
    AnmeldungStepTwoCompany,
    AnmeldungStepTwoPerson,
    AnmeldungStepThree,
    AnmeldungStepFour
  },
  props: {
    processing: {
      type: Boolean,
      default: false
    },
    vorlage: {
      type: Object,
      default: () => {}
    },
    veranstaltung: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      finalModel: {}
    }
  },
  methods: {
    validateStep(name) {
      const refToValidate = this.$refs[name]
      this.$scrollTo('.vue-form-wizard', 600)
      return refToValidate.validate()
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },
    onComplete() {
      this.$emit('on-complete', this.veranstaltung, this.finalModel)
    }
  }
}
</script>

<style lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vue-form-wizard .wizard-tab-content,
.vue-form-wizard .wizard-card-footer {
  @apply px-0 py-12;
}
/* .vue-form-wizard .wizard-icon-circle.tab_shape.checked {
  @apply bg-green-700;
}
.vue-form-wizard .wizard-icon-circle.tab_shape.checked .wizard-icon {
  @apply text-white;
} */
.vue-form-wizard .wizard-icon-circle .wizard-icon {
  @apply not-italic;
}
.vue-form-wizard .stepTitle {
  @apply text-sm font-semibold;
  /* @apply text-dark-blue-900; */
}
.vue-form-wizard .wizard-card-footer {
  @apply pt-12;
}
</style>
