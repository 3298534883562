<template>
  <div
    class="date-card"
    :data-vorlage="veranstaltung.template_nummer"
    :data-nummer="veranstaltung.nummer"
    :class="{
      selected: selected,
      'is-booked': isBooked,
      'is-arrangement': isArrangement,
      'is-webinar': isWebinar,
      'is-available-online': isAvailableOnline
    }"
  >
    <label
      :for="veranstaltung.nummer"
      class="absolute top-0 left-0 right-0 bottom-0 z-20 cursor-pointer"
      :class="{
        'cursor-not-allowed': checkIfBasket == 1
      }"
    />
    <!--
    <span
      v-if="isAvailableOnline"
      class="absolute py-2 px-3 -mb-6 -mr-3 bottom-0 right-0 text-xs font-semibold text-white bg-blue-600 print:-mb-10 print:right-auto print:left-0 print:py-1 print:ml-8 print:inline-block"
    >
      Wird online durchgeführt!
    </span>-->
    <span
      class="absolute pt-1 mr-1 top-0 right-0 text-xs font-semibold text-gray-700"
    >
      {{ veranstaltung.nummer }}
    </span>
    <div class="date-card__title">
      <input
        :id="veranstaltung.nummer"
        type="radio"
        class="date-card__radio"
        name="date"
        tabindex="0"
        :checked="selected"
        :disabled="checkIfBasket == 1"
        @change="setCardState"
      />
      <span class="ebz-radio"></span>
      <div
        v-if="!isArrangement && !isWebinar && !isSpecialEvent"
        class="flex flex-col"
      >
        <span>{{ veranstaltung.region }}</span>
        <span class="text-xs" :class="{ ' text-red-900': isBooked }">
          <time
            itemprop="startDate"
            :datetime="getISO8601String(veranstaltung.start_datum)"
          >
            {{
              [veranstaltung.start_datum, 'YYYY-MM-DD'] | moment('DD.MM.YYYY')
            }}
          </time>
          <span v-if="veranstaltung.start_datum !== veranstaltung.end_datum">
            -
            <time
              itemprop="endDate"
              :datetime="getISO8601String(veranstaltung.end_datum)"
            >
              {{
                [veranstaltung.end_datum, 'YYYY-MM-DD'] | moment('DD.MM.YYYY')
              }}
            </time>
          </span>
        </span>
        <span v-if="isAvailableOnline" class="text-xs">
          <span
            v-for="(termin, index) in veranstaltung.termine"
            :key="index"
            :class="{ ' text-red-900': isBooked }"
            class="block"
          >
            <span v-if="termin.wochentag">{{ termin.wochentag }}</span>
            {{ [termin.zeit_von, 'HH:mm:ss'] | moment('HH:mm') }} –
            {{ [termin.zeit_bis, 'HH:mm:ss'] | moment('HH:mm') }} Uhr
          </span>
        </span>
        <span
          v-if="veranstaltung.is_booked"
          class="text-xs text-red-900 uppercase"
        >
          Ausgebucht!
        </span>
      </div>
      <div v-if="!isArrangement && !isWebinar && isSpecialEvent">
        <span class="text-xs" :class="{ ' text-red-900': isBooked }">
          <time
            itemprop="startDate"
            :datetime="getISO8601String(veranstaltung.start_datum)"
          >
            {{ eventDate(veranstaltung.start_datum) }}
          </time>
        </span>
      </div>
    </div>
    <span
      v-if="isArrangement"
      class="text-orange-500 font-semibold self-center print:self-start"
    >
      Nach Vereinbarung
    </span>
    <p
      v-else-if="isWebinar"
      class="self-center flex flex-col items-center print:self-start print:items-start"
    >
      <span class="text-blue-1000 font-semibold">Webinar</span>
      <span class="text-blue-700">22.07.2019 - 26.07.2019</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    veranstaltung: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: String,
      default: ''
    },
    selectedId: {
      type: String,
      default: ''
    },
    isBooked: {
      type: Boolean,
      default: false
    },
    isArrangement: {
      type: Boolean,
      default: false
    },
    isWebinar: {
      type: Boolean,
      default: false
    },
    isGuaranteed: {
      type: Boolean,
      default: false
    },
    isAvailableOnline: {
      type: Boolean,
      default: false
    },
    isSpecialEvent: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      itemExist: 'cart/exist'
    }),
    checkIfBasket() {
      return this.itemExist(this.veranstaltung.template_nummer)
    }
  },
  methods: {
    setCardState() {
      this.$emit('update-selected-card-id', this.veranstaltung.nummer)
    },
    getISO8601String(date) {
      return moment(date).toISOString()
    },
    eventDate(date) {
      moment.locale('de')

      return moment(date).format('MMMM Y')
    }
  }
}
</script>

<style lang="postcss">
.date-card {
  @apply flex flex-col p-8 pb-6 m-4 bg-white border border-white shadow relative bg-white select-none;
  width: calc(100% - 2rem);
  transition: 0.3s;
}

.date-card__radio {
  @apply opacity-0 absolute w-0 h-0;
}

.date-card.selected .ebz-radio {
  @apply border-green-600;
}

.selected .ebz-radio::after {
  @apply bg-green-600;
}

.selected .ebz-radio::after {
  @apply opacity-100;
}

.is-booked.selected .ebz-radio {
  @apply border-red-900;
}

.is-booked .ebz-radio::after {
  @apply bg-red-900;
}

.is-arrangement.selected .ebz-radio {
  @apply border-orange-500;
}

.is-arrangement.selected .ebz-radio::after {
  @apply bg-orange-500;
}

@screen sm {
  .date-card {
    width: calc(50% - 2rem);
  }
}

@screen lg {
  .date-card {
    width: calc(100% / 3 - 2rem);
  }
}

@screen xxl {
  .date-card {
    width: calc(25% - 2rem);
  }
}

@media (pointer: coarse) {
  .date-card {
    min-width: 18.5rem;
    max-width: 18.5rem;
    flex-grow: 0;
    scroll-snap-align: start;
    scroll-margin-left: 1rem;
  }
}

@media (pointer: coarse) and (min-width: 768px) {
  .date-card {
    scroll-margin-left: 2rem;
  }

  .date-card:first-of-type {
    @apply ml-8;
  }
}

.date-card:not(.selected) {
  @apply text-gray-750;
}

.selected {
  @apply shadow-lg border-green-600;
  transform: translateY(-2px);
}

.date-card.is-booked.selected {
  @apply border-red-900;
}

.date-card.is-arrangement.selected {
  @apply border-orange-500;
}

.is-arrangement,
.is-webinar {
  @apply h-28 justify-center;
}

.date-card.is-webinar .date-card__title,
.date-card.is-arrangement .date-card__title {
  @apply absolute;
  top: 2rem;
}

.date-card:not(.selected):hover {
  @apply bg-gray-200 border-gray-200;
}

.date-card__title {
  @apply flex items-baseline font-semibold;
}

.date-card.date-card.selected .date-card__title {
  @apply text-green-700;
}

.date-card.is-booked.date-card.selected .date-card__title {
  @apply text-red-900;
}
/*
.date-card.is-available-online {
  @apply bg-green-600;
  @apply text-green-100;
}

.date-card.is-available-online > span,
.date-card.is-available-online * {
  @apply text-green-100;
}

.date-card.is-available-online .ebz-radio {
  @apply border-green-100;
}
.date-card.is-available-online .ebz-radio::after {
  @apply bg-green-100;
} */
</style>
