<template>
  <label class="flex items-start cursor-pointer select-none">
    <input
      :id="id"
      :checked="checked"
      class="opacity-0 w-0 h-0"
      type="checkbox"
      :name="name"
      tabindex="0"
      true-value="1"
      false-value="0"
      @change="onChange"
    />
    <div
      class="ebz-checkbox rounded border-2 border-gray-600 bg-white mr-2 cursor-pointer flex flex-shrink-0 items-center justify-center relative"
      :class="{ 'ebz-checkbox--red': red }"
      role="checkbox"
    >
      <!-- <Icon name="check-small" class="w-3 h-3 text-white" /> -->
    </div>
    {{ label }}
  </label>
</template>

<script>
// import Icon from '~/components/Icon'

export default {
  components: {
    // Icon
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: null
    },
    red: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked() {
      return this.value === true || this.value === 'true'
    }
  },
  mounted() {},
  methods: {
    onChange(e) {
      // e.preventDefault()
      this.$emit('on-change', e.target.checked)
    }
  }
}
</script>

<style lang="postcss">
.ebz-checkbox {
  width: 1.125rem;
  height: 1.125rem;
  transition: 0.1s;
  bottom: -0.125rem;
}

input[type='checkbox']:focus + .ebz-checkbox {
  @apply border-dark-blue-700;
}

input[type='checkbox']:checked + .ebz-checkbox {
  @apply bg-dark-blue-900 border-dark-blue-900;
  background-size: 0.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+PHBhdGggZD0iTTIzIDYuNjI1NDRMMjAuNDE1IDQgOC4zMzMzMyAxNi4xODQzbC00LjczLTQuNzUxN0wxIDE0LjAzOTVsNy4zMzMzMyA3LjM3NzJMMjMgNi42MjU0NHoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
}

input[type='checkbox']:focus + .ebz-checkbox.ebz-checkbox--red {
  @apply border-red-500;
}
input[type='checkbox']:checked + .ebz-checkbox.ebz-checkbox--red {
  @apply bg-red-500 border-red-500;
}
</style>
