<template>
  <form class="" @submit.prevent="doSearch">
    <div
      class="flex flex-col p-8 mb-4 -mx-4 ebz-filter-bg shadow-lg filters sm:mx-0 xxl:flex-row"
    >
      <div class="flex-1 w-full">
        <div class="flex flex-col flex-wrap sm:flex-row">
          <Inputfield
            id="text"
            label="Freitextsuche / Angebotsnummer"
            placeholder="z.B. Digitales Marketing"
            class="w-full free-text-search filter xxl:self-start lg:w-2/3 xxl:w-3/5 sm:px-2"
            :value="q"
            @on-change="updateQuery"
            @on-clear="resetQuery"
          />
          <DatePicker
            :value="date"
            class="sm:w-1/2 lg:w-1/3 xxl:w-1/5 sm:mt-4 lg:mt-0 sm:px-2"
            @on-change="dateUpdated"
            @on-clear-selection="resetDate"
          />
        </div>
        <div class="flex flex-col flex-wrap sm:flex-row my-8">
          <FilterDropdown
            id="thema"
            class="mb-4 filter sm:w-1/2 xxl:w-1/5 xxl:mb-0 sm:px-2"
            :options="themen"
            :selected="selectedThemen"
            label="Thema"
            track-by="code"
            placeholder="z.B. Digitalisierung"
            multiselect
            @on-input="themaUpdated"
            @on-clear-selection="clearSelectedThemen"
          />
          <FilterDropdown
            id="branche"
            class="mb-4 filter sm:w-1/2 xxl:w-1/5 xxl:mb-0 sm:px-2"
            :options="branchen"
            :selected="selectedBranchen"
            label="Branche"
            track-by="code"
            placeholder="z.B. Makler"
            :disabled="checkForELearnings"
            multiselect
            @on-input="brancheUpdated"
            @on-clear-selection="clearSelectedBranchen"
          />
          <FilterDropdown
            id="region"
            class="mb-4 filter sm:w-1/2 lg:mb-0 xxl:w-1/5 sm:px-2"
            :options="regionen"
            :selected="selectedRegionen"
            label="Region/Ort"
            track-by="code"
            placeholder="z.B. Bochum"
            multiselect
            @on-input="regionUpdated"
            @on-clear-selection="clearSelectedRegionen"
          />
          <FilterDropdown
            id="veranstaltungsart"
            class="mb-4 filter sm:mb-0 sm:w-1/2 lg:mb-0 xxl:w-1/5 sm:px-2"
            :options="veranstaltungsarten"
            :selected="selectedVeranstaltungsarten"
            label="Veranstaltungsart"
            track-by="code"
            placeholder="z.B. Studium"
            multiselect
            @on-input="veranstaltungsartUpdated"
            @on-clear-selection="clearSelectedVeranstaltungsarten"
          />
        </div>
        <div class="flex flex-col flex-wrap sm:flex-row mt-8">
          <div class="mb-4 filter sm:w-1/2 xxl:w-1/5 xxl:mb-0 sm:px-2">
            <div class="text-sm pb-1">
              <span class="font-semibold">Weiterbildung gemäß MaBV für:</span>
            </div>
            <Checkbox
              v-model="mabvAgent"
              label="Immobilienmakler"
              class="w-full pb-1 whitespace-no-wrap filter"
              @on-change="updateMabvAgent"
            />
            <Checkbox
              v-model="mabvManager"
              label="Wohnimmobilienverwalter"
              class="w-full whitespace-no-wrap filter pb-4 xxl:pb-0"
              @on-change="updateMabvManager"
            />
          </div>
          <div class="mb-4 filter sm:w-1/2 xxl:w-1/5 xxl:mb-0 sm:px-2">
            <Checkbox
              v-model="energieberater"
              label="Energieberater"
              class="w-full filter pb-4 xxl:pb-0"
              @on-change="updateEnergieberater"
            />
          </div>
          <div class="mb-4 filter sm:w-1/2 xxl:w-1/5 xxl:mb-0 sm:px-2">
            <Checkbox
              v-model="architektenkammer"
              label="Architektenkammer"
              class="w-full filter pb-4 xxl:pb-0"
              @on-change="updateArchitektenkammer"
            />
          </div>
          <div class="xxl:w-1/5 sm:px-2 sm:mt-4 xxl:mt-0">
            <Checkbox
              v-model="inhouse"
              label="Inhouse buchbar"
              class="w-full pb-3 whitespace-no-wrap filter"
              @on-change="updateInhouse"
            />
          </div>
          <div
            class="flex items-end justify-end xxl:w-1/5 sm:px-2 sm:mt-4 xxl:mt-0"
          >
            <button
              class="button apply-filter-button print:hidden focus:shadow-outline"
              type="submit"
            >
              Suche
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { debounce } from 'lodash'

import Inputfield from '~/components/inputs/Inputfield'
import FilterDropdown from '~/components/inputs/FilterDropdown'
import DatePicker from '~/components/inputs/DatePicker'
import Checkbox from '~/components/inputs/Checkbox'

export default {
  components: {
    Inputfield,
    FilterDropdown,
    DatePicker,
    Checkbox
  },
  props: {
    themen: {
      type: Array,
      default: () => []
    },
    sonstige: {
      type: Array,
      default: () => []
    },
    branchen: {
      type: Array,
      default: () => []
    },
    regionen: {
      type: Array,
      default: () => []
    },
    veranstaltungsarten: {
      type: Array,
      default: () => []
    },
    date: {
      type: Object,
      default: () => {}
    }
    // themaSelected: {
    //   type: Function,
    //   default: function() {}
    // }
  },
  computed: {
    q() {
      return this.$store.state.filter.q
    },
    selectedThemen() {
      return this.$store.state.filter.selectedThemen
    },
    selectedBranchen() {
      return this.$store.state.filter.selectedBranchen
    },
    selectedRegionen() {
      return this.$store.state.filter.selectedRegionen
    },
    selectedVeranstaltungsarten() {
      return this.$store.state.filter.selectedVeranstaltungsarten
    },
    inhouse() {
      return !!this.$store.state.filter.inhouse
    },
    mabvAgent() {
      return !!this.$store.state.filter.mabvAgent
    },
    mabvManager() {
      return !!this.$store.state.filter.mabvManager
    },
    architektenkammer() {
      return !!this.$store.state.filter.architektenkammer
    },
    energieberater() {
      return !!this.$store.state.filter.energieberater
    },
    online() {
      return !!this.$store.state.filter.online
    },
    checkForELearnings() {
      const art = this.$store.state.filter.selectedVeranstaltungsarten
      let check = false

      for (const item of art) {
        if (item.code === 'ELEARN') {
          check = true
        }
      }

      return check
    }
  },
  methods: {
    doSearch() {
      this.$emit('do-search')
    },
    /** Suche */
    updateQuery: debounce(function(value) {
      this.$store.dispatch('filter/updateQuery', value)
    }, 500),
    resetQuery() {
      this.$store.dispatch('filter/resetQuery')
      this.$emit('do-search')
    },
    /** Inhouse buchbar */
    updateInhouse(value) {
      this.$store.dispatch('filter/updateInhouse', value)
    },
    /** Online */
    updateOnline(value) {
      this.$store.dispatch('filter/updateOnline', value)
    },
    /** MaBV */
    updateMabvAgent(value) {
      this.$store.dispatch('filter/updateMabvAgent', value)
    },
    updateMabvManager(value) {
      this.$store.dispatch('filter/updateMabvManager', value)
    },
    /** MaBV */
    updateArchitektenkammer(value) {
      this.$store.dispatch('filter/updateArchitektenkammer', value)
    },
    updateEnergieberater(value) {
      this.$store.dispatch('filter/updateEnergieberater', value)
    },
    /** Thema */
    themaUpdated(value) {
      this.$emit('on-thema-updated', value)
    },
    clearSelectedThemen() {
      this.$store.dispatch('filter/resetSelectedThemen')
      this.$emit('do-search')
    },
    /** Branche */
    brancheUpdated(value) {
      this.$emit('on-branche-updated', value)
    },
    clearSelectedBranchen() {
      this.$store.dispatch('filter/resetSelectedBranchen')
      this.$emit('do-search')
    },
    /** Region */
    regionUpdated(value) {
      this.$emit('on-region-updated', value)
    },
    clearSelectedRegionen() {
      this.$store.dispatch('filter/resetSelectedRegionen')
      this.$emit('do-search')
    },
    /** Region */
    veranstaltungsartUpdated(value) {
      this.$emit('on-veranstaltungsart-updated', value)
    },
    clearSelectedVeranstaltungsarten() {
      this.$store.dispatch('filter/resetSelectedVeranstaltungsarten')
      this.$emit('do-search')
    },
    /** Datum */
    dateUpdated(value) {
      this.$store.dispatch('filter/updateDate', value)
    },
    resetDate() {
      this.$store.dispatch('filter/resetDate')
      this.$emit('do-search')
    }
  }
}
</script>

<style lang="postcss">
.filters .ebz-input-field {
  @apply pr-10 border-white;
}

.apply-filter-button {
  min-width: 8.5rem;
}
</style>
