<template>
  <button class="w-full px-4 md:px-8 lg:px-0 py-4 text-left pr-12">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Keine Angabe'
    }
  }
}
</script>

<style lang="postcss"></style>
