<template>
  <div>
    <div>
      <h3 class="mb-6">Privatperson</h3>
      <div class="InputContainer w-full md:w-1/3">
        <select
          v-model="privateSalutation"
          name="salutation"
          required
          :class="{
            Input: true,
            'Input--Error': $v.privateSalutation.$error
          }"
          class="bg-white cursor-pointer focus:outline-none"
          @select="$v.privateSalutation.$touch()"
        >
          <option value="" disabled>Auswählen</option>
          <option value="Frau">Frau</option>
          <option value="Herr">Herr</option>
          <option value="-">Keine Angabe</option>
        </select>
        <label for="privateSalutation">Anrede</label>
      </div>

      <div class="mt-6">
        <div class="InputContainer w-full md:w-1/3">
          <select
            v-model="privateJobTitle"
            name="privateJobTitle"
            :class="{
              Input: true,
              'Input--Error': $v.privateJobTitle.$error
            }"
            class="bg-white cursor-pointer focus:outline-none"
            @select="$v.privateJobTitle.$touch()"
          >
            <option value="">keine Angabe</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Prof. Dr.">Prof. Dr.</option>
          </select>
          <label for="privateJobTitle">Titel</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="firstName"
            type="text"
            name="firstName"
            :class="{
              Input: true,
              'Input--Error': $v.firstName.$error
            }"
            required
            @input="$v.firstName.$touch()"
          />
          <label for="firstName">Vorname</label>
        </div>

        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="lastName"
            type="text"
            name="lastName"
            :class="{
              Input: true,
              'Input--Error': $v.lastName.$error
            }"
            required
            @input="$v.lastName.$touch()"
          />
          <label for="lastName">Nachname</label>
        </div>
      </div>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="email"
            type="text"
            name="email"
            :class="{
              Input: true,
              'Input--Error': $v.email.$error
            }"
            required
            @input="$v.email.$touch()"
          />
          <label for="email">E-Mail</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="phone"
            type="text"
            name="phone"
            :class="{
              Input: true,
              'Input--Error': $v.phone.$error
            }"
            required
            @input="$v.phone.$touch()"
          />
          <label for="phone">Telefonnumer</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="birthday"
            type="text"
            name="birthday"
            :class="{
              Input: true,
              'Input--Error': $v.birthday.$error
            }"
            @input="$v.birthday.$touch()"
          />
          <label for="birthday">Geburtstag</label>
        </div>
      </div>

      <!-- <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="birthday"
            type="date"
            name="birthday"
            :class="{
              Input: true,
              'Input--Error': $v.birthday.$error
            }"
            @input="$v.birthday.$touch()"
          />
          <label for="birthday">Geburtstag</label>
        </div>
      </div> -->

      <h3 class="mt-6">Rechnungsanschrift</h3>
      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="address"
            type="text"
            name="address"
            required
            :class="{
              Input: true,
              'Input--Error': $v.address.$error
            }"
            @input="$v.address.$touch()"
          />
          <label for="address">Straße / Hausnummer</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="zip"
            type="text"
            name="zip"
            required
            :class="{
              Input: true,
              'Input--Error': $v.zip.$error
            }"
            @input="$v.zip.$touch()"
          />
          <label for="zip">PLZ</label>
        </div>
        <div class="InputContainer w-full md:w-1/3 md:ml-3">
          <input
            v-model.trim="city"
            type="text"
            name="city"
            required
            :class="{
              Input: true,
              'Input--Error': $v.city.$error
            }"
            @input="$v.city.$touch()"
          />
          <label for="city">Ort</label>
        </div>
      </div>

      <div class="mt-6 flex flex-wrap">
        <div class="InputContainer w-full md:w-1/3">
          <input
            v-model.trim="privateBemerkung"
            type="text"
            name="privateBemerkung"
            :class="{
              Input: true,
              'Input--Error': $v.privateBemerkung.$error
            }"
            @input="$v.privateBemerkung.$touch()"
          />
          <label for="privateBemerkung">Bemerkungen (max. 50 Zeichen)</label>
        </div>
      </div>

      <div class="mt-8">
        <h3>
          Wählen Sie das gewünschte Veranstaltungspaket
        </h3>
        <!-- <div class="mt-4">
          <div v-for="item in priceGroups" :key="item.preis_lcy_net">
            <label
              class="flex border-t border-gray-300 items-center cursor-pointer py-4"
              :class="{
                'text-red-500': $v.priceGroup.$error
              }"
            >
              <input
                v-model.trim="priceGroup"
                type="radio"
                name="priceGroup"
                class="mr-4"
                :value="item"
                @input="$v.priceGroup.$touch()"
              />
              <div class="w-3/12 md:w-2/12 lg:w-1/12">
                {{ item.preis_lcy_net | currency }}
              </div>
              <div>
                <div>{{ item.preisgruppenbeschreibung }}</div>
                <div class="font-normal">{{ item.beschreibung }}</div>
              </div>
            </label>
          </div>
        </div> -->

        <!-- <div class="InputContainer w-full md:w-1/3">
          <select
            v-model="priceGroup"
            name="priceGroup"
            :class="{
              Input: true,
              'Input--Error': $v.priceGroup.$error
            }"
            class="bg-white cursor-pointer focus:outline-none"
            @select="$v.priceGroup.$touch()"
          >
            <option
              v-for="item in priceGroups"
              :key="item.preis_lcy_net"
              :value="item"
              :selected="item === priceGroup"
              >{{ item.preis_lcy_net | currency }} –
              {{ item.preisgruppenbeschreibung }} ({{
                item.beschreibung
              }})</option
            >
          </select>
          <label for="priceGroup">Veranstaltungspaket</label>
        </div> -->

        <div class="mt-4">
          <div class="w-full md:w-2/3">
            <multiselect
              v-if="priceGroups"
              v-model="priceGroup"
              :searchable="false"
              :options="priceGroups"
              :show-labels="true"
              track-by="preis_lcy_net"
              label="preisgruppenbeschreibung"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.preis_lcy_net | currency }}</strong>
                <span v-if="priceGroups.length > 1" class="ml-2">
                  {{ option.beschreibung }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <span class="option__title"
                  ><strong>{{ props.option.preis_lcy_net | currency }}</strong>
                  <em v-if="priceGroups.length > 1" class="ml-2 not-italic">
                    {{ props.option.beschreibung }}
                  </em>
                </span>
              </template>
              <span slot="noResult" class="no-result"
                >Kein Ergebnis gefunden.</span
              >
            </multiselect>
          </div>
          <!-- <div class="InputContainer w-full md:w-1/3">
            <select
              v-model="priceGroup"
              name="priceGroup"
              :class="{
                Input: true,
                'Input--Error': $v.priceGroup.$error
              }"
              class="bg-white cursor-pointer focus:outline-none"
              @select="$v.priceGroup.$touch()"
            >
              <option
                v-for="item in priceGroups"
                :key="item.preis_lcy_net"
                :value="item"
                :selected="item === priceGroup"
                >{{ item.preis_lcy_net | currency }} –
                {{ item.preisgruppenbeschreibung }} ({{
                  item.beschreibung
                }})</option
              >
            </select>
            <label for="priceGroup">Veranstaltungspaket</label>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'

import Multiselect from 'vue-multiselect'

import _ from 'lodash'

// import { filter } from 'lodash'

// import Input from '~/components/Input'

export default {
  components: {
    // Input
    Multiselect
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    vorlage: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      privateJobTitle: '',
      privateSalutation: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthday: '',
      address: '',
      zip: '',
      city: '',
      privateBemerkung: '',
      priceGroup: null
    }
  },
  computed: {
    isPerson() {
      return this.type === 'person'
    },
    selectedVeranstaltung() {
      const termin = this.$route.query.termin

      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    },
    priceGroups() {
      const priceGroups =
        this.selectedVeranstaltung &&
        this.selectedVeranstaltung.leistungen &&
        _(this.selectedVeranstaltung.leistungen)
          .flattenDeep()
          .orderBy(['preis_lcy_net'], ['desc'])
          .value()

      console.log('priceGroups', priceGroups)

      return priceGroups
    }
  },
  validations: {
    privateJobTitle: {},
    privateSalutation: {
      required: requiredIf('isPerson')
    },
    firstName: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    lastName: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    email: {
      required: requiredIf('isPerson'),
      email
    },
    phone: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    birthday: {},
    address: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    zip: {
      required: requiredIf('isPerson'),
      minLength: minLength(4),
      maxLength: maxLength(6)
    },
    city: {
      required: requiredIf('isPerson'),
      minLength: minLength(1)
    },
    privateBemerkung: {
      maxLength: maxLength(50)
    },
    priceGroup: {
      required
    }
  },
  mounted() {
    this.preselectPriceGroup()
  },
  methods: {
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    preselectPriceGroup() {
      const highestPriceGroup =
        this.selectedVeranstaltung &&
        this.selectedVeranstaltung.leistungen &&
        _(this.selectedVeranstaltung.leistungen)
          .flattenDeep()
          .orderBy(['preis_lcy_net'], ['desc'])
          .head()

      if (!this.priceGroup) {
        this.priceGroup = highestPriceGroup
      }

      console.log('highestPriceGroup', highestPriceGroup)
    }
  }
}
</script>
