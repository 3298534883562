import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44f08af0 = () => interopDefault(import('../pages/shopping_cart/index.vue' /* webpackChunkName: "pages/shopping_cart/index" */))
const _f7a36f72 = () => interopDefault(import('../pages/shopping_cart/anmeldung.vue' /* webpackChunkName: "pages/shopping_cart/anmeldung" */))
const _7e451068 = () => interopDefault(import('../pages/shopping_cart/anmeldung_bestaetigung.vue' /* webpackChunkName: "pages/shopping_cart/anmeldung_bestaetigung" */))
const _62878598 = () => interopDefault(import('../pages/shopping_cart/anmeldung_fehler.vue' /* webpackChunkName: "pages/shopping_cart/anmeldung_fehler" */))
const _07e83d6c = () => interopDefault(import('../pages/shopping_cart/payment_canceled.vue' /* webpackChunkName: "pages/shopping_cart/payment_canceled" */))
const _3e1d9a0c = () => interopDefault(import('../pages/shopping_cart/payment_complete.vue' /* webpackChunkName: "pages/shopping_cart/payment_complete" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _379f468a = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _00e40a4f = () => interopDefault(import('../pages/_slug/anfrage.vue' /* webpackChunkName: "pages/_slug/anfrage" */))
const _740142c4 = () => interopDefault(import('../pages/_slug/anfrage_bestaetigung.vue' /* webpackChunkName: "pages/_slug/anfrage_bestaetigung" */))
const _36f6ce0c = () => interopDefault(import('../pages/_slug/anmeldung.vue' /* webpackChunkName: "pages/_slug/anmeldung" */))
const _595d21f9 = () => interopDefault(import('../pages/_slug/anmeldung_bestaetigung.vue' /* webpackChunkName: "pages/_slug/anmeldung_bestaetigung" */))
const _15e99585 = () => interopDefault(import('../pages/_slug/anmeldung_fehler.vue' /* webpackChunkName: "pages/_slug/anmeldung_fehler" */))
const _61347f78 = () => interopDefault(import('../pages/_slug/inhouse.vue' /* webpackChunkName: "pages/_slug/inhouse" */))
const _1020ba22 = () => interopDefault(import('../pages/_slug/inhouse_bestaetigung.vue' /* webpackChunkName: "pages/_slug/inhouse_bestaetigung" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/shopping_cart",
    component: _44f08af0,
    name: "shopping_cart"
  }, {
    path: "/shopping_cart/anmeldung",
    component: _f7a36f72,
    name: "shopping_cart-anmeldung"
  }, {
    path: "/shopping_cart/anmeldung_bestaetigung",
    component: _7e451068,
    name: "shopping_cart-anmeldung_bestaetigung"
  }, {
    path: "/shopping_cart/anmeldung_fehler",
    component: _62878598,
    name: "shopping_cart-anmeldung_fehler"
  }, {
    path: "/shopping_cart/payment_canceled",
    component: _07e83d6c,
    name: "shopping_cart-payment_canceled"
  }, {
    path: "/shopping_cart/payment_complete",
    component: _3e1d9a0c,
    name: "shopping_cart-payment_complete"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/:slug",
    component: _379f468a,
    name: "slug"
  }, {
    path: "/:slug/anfrage",
    component: _00e40a4f,
    name: "slug-anfrage"
  }, {
    path: "/:slug/anfrage_bestaetigung",
    component: _740142c4,
    name: "slug-anfrage_bestaetigung"
  }, {
    path: "/:slug/anmeldung",
    component: _36f6ce0c,
    name: "slug-anmeldung"
  }, {
    path: "/:slug/anmeldung_bestaetigung",
    component: _595d21f9,
    name: "slug-anmeldung_bestaetigung"
  }, {
    path: "/:slug/anmeldung_fehler",
    component: _15e99585,
    name: "slug-anmeldung_fehler"
  }, {
    path: "/:slug/inhouse",
    component: _61347f78,
    name: "slug-inhouse"
  }, {
    path: "/:slug/inhouse_bestaetigung",
    component: _1020ba22,
    name: "slug-inhouse_bestaetigung"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
