<template>
  <div>
    <nuxt />

    <Footer :style="footerStyle" />

    <ScrollToTop />

    <client-only>
      <CookieBanner ref="cookieBanner" />
    </client-only>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'
import ScrollToTop from '~/components/ScrollToTop'
import Footer from '~/components/Footer'
import CookieBanner from '~/components/CookieBanner'

require('core-js')

export default {
  components: {
    ScrollToTop,
    Footer,
    CookieBanner
  },
  data() {
    return {
      cookieBannerHeight: 0
    }
  },
  computed: {
    footerStyle() {
      return {
        // marginBottom: `${this.cookieBannerHeight}px`
      }
    }
  },
  head() {
    return {
      title: 'EBZ Bildungsfinder',
      bodyAttrs: { class: 'relative' },
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        { hid: 'robots', name: 'robots', content: 'index,follow' }
      ],
      link: [
        {
          rel: 'preconnect',
          href: '//fonts.googleapis.com'
        },
        {
          rel: 'preconnect',
          href: '//fonts.gstatic.com'
        }
      ]
    }
  },
  mounted() {
    if (localStorage.getItem('GDPR:statistics') === 'true') {
      bootstrap()
      if (this.$matomo) this.$matomo.setConsent(true)
    }
  }
}
</script>

<style lang="postcss"></style>
