<template>
  <div class="container py-16">
    <h1>
      Die angeforderte Seite wurde nicht gefunden.
    </h1>
    <p>
      Die gewünschte Seite oder Veranstaltung ist nicht mehr verfügbar, wählen
      Sie jedoch gerne eines der nachstehenden Themen aus und Sie werden auf die
      Seite der EBZ Akademie in Bochum weitergeleitet und können dort das
      Trainingsangebot in Ruhe prüfen!
    </p>
    <div v-if="themen && themen.length">
      <h4 class="mt-12">Mehr zum Thema…</h4>
      <div class="flex flex-wrap mt-6">
        <nuxt-link
          v-for="thema in themen"
          :key="thema.code"
          :to="buildThemenUrl(thema.code)"
          class="button mr-3 mb-3"
        >
          {{ thema.value }}
        </nuxt-link>
      </div>
    </div>
    <div class="mt-12">
      <nuxt-link class="text-sm mt-12" to="/">
        zur Startseite
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => []
    }
  },
  layout: 'default',
  computed: {
    themen() {
      return this.$store.state.filter.themen
    }
  },
  methods: {
    buildThemenUrl(code) {
      return `/?t=${code}`
    }
  }
}
</script>
