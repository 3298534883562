import { flatten, union, uniqBy } from 'lodash'

export const state = () => ({
  list: [],
  recommended: [],
  isLoading: false,
  meta: {}
})

export const getters = {
  getVorlageBySlug: (state) => (slug) => {
    return state.list.find((item) => item.slug === slug)
  }
}

export const mutations = {
  add(state, data) {
    state.list = uniqBy(union(state.list, [data]), 'nummer')
  },
  restore(state, { data, meta }) {
    state.list = data
    state.meta = meta
  },
  setRecommended(state, data) {
    state.recommended = data
  },
  loading(state, status) {
    state.isLoading = status
  }
}

export const actions = {
  async getAll(
    { commit },
    {
      page = 1,
      t,
      b,
      a,
      r,
      q = null,
      inhouse = 0,
      online = 0,
      mabvAgent = 0,
      mabvManager = 0,
      energieberater = 0,
      architektenkammer = 0,
      start,
      end
    }
  ) {
    commit('loading', true)

    const params = {
      page,
      q,
      themen: t && flatten([t]),
      branchen: b && flatten([b]),
      region: r && flatten([r]),
      art: a && flatten([a]),
      inhouse,
      online,
      mabvAgent,
      mabvManager,
      energieberater,
      architektenkammer,
      start,
      end
    }

    await this.$axios
      .get(`/veranstaltungsvorlagen`, {
        params
      })
      .then(({ data: { data, meta } }) => {
        commit('restore', { data, meta })
        commit('loading', false)
      })
      .catch((e) => {
        this.app.$bugsnag.notify(e, {
          metaData: {
            vuex: 'veranstaltungsvorlagen/getAll',
            axios: {
              url: '/veranstaltungsvorlagen',
              method: 'GET',
              params
            }
          },
          context: 'vuex'
        })

        this.app.context.error({
          statusCode: 404,
          message: 'Veranstaltungsvorlagen nicht gefunden'
        })
      })
  },

  async getRecommended({ commit }) {
    await this.$axios
      .get(`/veranstaltungsvorlagen/recommended`)
      .then(({ data }) => {
        commit('setRecommended', data.data)
      })
      .catch((e) => {
        this.app.$bugsnag.notify(e, {
          metaData: {
            vuex: 'veranstaltungsvorlagen/getRecommended',
            axios: {
              url: '/veranstaltungsvorlagen/recommended',
              method: 'GET'
            }
          },
          context: 'vuex'
        })
        this.app.context.error({
          statusCode: 404,
          message: 'Empfohlene Veranstaltungen nicht gefunden'
        })
      })
  },

  async get({ commit }, slug) {
    commit('loading', true)

    await this.$axios
      .get(`/veranstaltungsvorlagen/${slug}`)
      .then(({ data }) => {
        commit('add', data)
        commit('loading', false)
      })
      .catch((e) => {
        this.app.$bugsnag.notify(e, {
          metaData: {
            vuex: 'veranstaltungsvorlagen/get',
            axios: {
              url: `/veranstaltungsvorlagen/${slug}`,
              method: 'GET'
            }
          },
          context: 'vuex'
        })

        this.app.context.error({
          statusCode: 404,
          message: 'Veranstaltungsvorlage nicht gefunden'
        })
      })
  }
}
