<template>
  <nuxt-link to="/shopping_cart" class="shopping-cart">
    <span v-show="cartItems > 0" class="shopping-cart--bubble">
      {{ cartItems }}
    </span>
    <img src="~/assets/img/cart.svg" class="w-6 h-6" />
  </nuxt-link>
</template>

<script>
export default {
  computed: {
    cartItems() {
      const items = this.$store.getters['cart/items']

      return items.length
    }
  },
  methods: {}
}
</script>

<style lang="postcss">
.shopping-cart {
  @apply bg-dark-blue-800 h-16 w-16 flex items-center justify-center fixed z-50 rounded-full p-0;
  transition: 0.3s ease;
  top: 1rem;
  right: 1rem;
  color: #f9f9f9;
}

.shopping-cart:hover {
  @apply bg-dark-blue-900 shadow-xl;
}

.shopping-cart--bubble {
  @apply absolute bg-red-400 rounded-full w-6 h-6 text-sm text-center text-white shadow;
  top: 0rem;
  right: 0rem;
  z-index: 51;
}
</style>
