<template>
  <div class="min-w-0 max-w-full ebz-dropdown">
    <div class="flex justify-between h-6 items-center">
      <label v-if="label">{{ label }}</label>
      <div
        v-if="selected && selected.length > 0 && !smallReset"
        class="flex items-center justify-center bg-dark-blue-900 text-gray-100 text-sm font-semibold rounded h-6 pl-2 pr-1 leading-none"
      >
        <span class="whitespace-no-wrap text-sm">
          {{ selected.length }}
          Filter
        </span>
        <button
          class="filter-reset transition h-6 w-6"
          type="button"
          @click="clearSelection"
        ></button>
      </div>
      <div
        v-if="selected && selected.length > 0 && smallReset"
        class="flex items-center justify-center bg-dark-blue-900 text-gray-100 text-sm font-semibold rounded h-6 pl-1 pr-1 leading-none ml-2"
      >
        <button
          class="filter-reset transition h-6 w-6 ml-0"
          type="button"
          @click="clearSelection"
        ></button>
      </div>
    </div>
    <div v-if="options && options.length">
      <multiselect
        :value="selected"
        :options="options"
        :close-on-select="false"
        :placeholder="'z.B. ' + options[1].value"
        :max-height="1000"
        :track-by="trackBy"
        :searchable="false"
        class="mt-2"
        :multiple="multiselect"
        :disabled="disabled"
        :class="{
          'multiselect--single': !multiselect
        }"
        @input="onInput"
        @select="onSelect"
        @remove="onRemove"
        @close="onClose"
      >
        <template slot="tag" slot-scope="{ option }">
          <span class="custom__tag">
            <span>{{ option.value }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <div>
            <span class="option__title">{{ props.option.value }}</span>
          </div>
        </template>
        <span slot="noResult" class="no-result">Kein Ergebnis gefunden.</span>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    select: {
      type: Function,
      default: function() {}
    },
    selected: {
      type: Array,
      default: null
    },
    trackBy: {
      type: String,
      default: null
    },
    smallReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOptionsCount: 0,
      isOnRight: false
    }
  },
  methods: {
    transferValue() {
      this.selectedOptionsCount = !this.value
        ? 0
        : this.multiselect
        ? this.value.length
        : 1
    },
    clearSelection() {
      this.$emit('on-clear-selection')
    },
    updateSelected(item) {
      this.$emit('on-select', item)
    },
    onSelect(item) {
      this.$emit('on-select', item)
    },
    onRemove(item) {
      this.$emit('on-remove', item)
    },
    onInput(value) {
      this.$emit('on-input', value)
    },
    onClose(value, id) {
      this.$emit('on-close', value, id)
    }
  }
}
</script>

<style lang="postcss">
.ebz-dropdown :focus {
  outline: none;
}

.filter-reset {
  @apply p-0 flex items-center p-1 ml-1 relative rounded;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld2JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTkgNi40MWwtMS40MSAtMS40MSAtNS41OSA1LjU5IC01LjU5IC01LjU5IC0xLjQxIDEuNDEgNS41OSA1LjU5IC01LjU5IDUuNTkgMS40MSAxLjQxIDUuNTkgLTUuNTkgNS41OSA1LjU5IDEuNDEgLTEuNDEgLTUuNTkgLTUuNTkgNS41OSAtNS41OXoiPjwvcGF0aD48L3N2Zz4=)
    no-repeat center/1rem;
}

button.filter-reset:hover {
  @apply bg-dark-blue-800;
}

button.filter-reset:focus {
  @apply bg-dark-blue-700 outline-none;
}

.multiselect--disabled {
  @apply cursor-not-allowed;
}

.multiselect--disabled .multiselect__tags {
  @apply bg-gray-600 border-gray-800 cursor-not-allowed;
}
.multiselect--disabled .multiselect__placeholder {
  @apply text-gray-900 cursor-not-allowed;
}

.multiselect--disabled .multiselect__select {
  @apply text-gray-900 cursor-not-allowed;
}

.multiselect {
  @apply relative;
}

.multiselect__tags {
  @apply flex items-center bg-white text-dark-blue-800 h-10 rounded px-4 cursor-pointer shadow;
  @apply text-sm;
}

.multiselect__tags-wrap {
  @apply inline-block;
}

.multiselect__tags-wrap,
.multiselect__single {
  @apply overflow-hidden whitespace-no-wrap;
  text-overflow: ellipsis;
  width: calc(100% - 1.5rem);
}

.multiselect__tags-wrap:empty::after {
  @apply text-gray-600;
}

.multiselect--active .multiselect__tags-wrap {
  @apply border-dark-blue-900;
}

.multiselect__select {
  @apply w-6 h-6 mr-2 mt-2 text-gray-600 cursor-pointer;
  @apply inline-block absolute right-0 z-10;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4xMiA5LjI5TDEyIDEzLjE3bDMuODgtMy44OGMuMzktLjM5IDEuMDItLjM5IDEuNDEgMCAuMzkuMzkuMzkgMS4wMiAwIDEuNDFsLTQuNTkgNC41OWMtLjM5LjM5LTEuMDIuMzktMS40MSAwTDYuNyAxMC43Yy0uMzktLjM5LS4zOS0xLjAyIDAtMS40MS4zOS0uMzggMS4wMy0uMzkgMS40MiAweiIgZmlsbD0iIzgxODE4MSIvPjwvc3ZnPg==);
}

.multiselect--active .multiselect__select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4xMiAxNC43MUwxMiAxMC44M2wzLjg4IDMuODhjLjM5LjM5IDEuMDIuMzkgMS40MSAwIC4zOS0uMzkuMzktMS4wMiAwLTEuNDFMMTIuNyA4LjcxYy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMEw2LjcgMTMuM2MtLjM5LjM5LS4zOSAxLjAyIDAgMS40MS4zOS4zOCAxLjAzLjM5IDEuNDIgMHoiIGZpbGw9IiM4MTgxODEiLz48L3N2Zz4=);
}

.multiselect__placeholder {
  @apply flex items-center text-gray-600 h-10 rounded mt-0 cursor-pointer select-none;
  @apply text-sm;
}

input.multiselect__input {
  @apply absolute;
  left: 1rem;
  right: 2.5rem;
  max-width: calc(100% - 3.5rem);
}

.multiselect__input:focus {
  @apply mt-0;
}

.multiselect__input:not(:focus) {
  @apply border-none;
}

.multiselect--active .multiselect__tags-wrap {
  @apply rounded;
}

.custom__tag:last-of-type span::after {
  content: '';
}

.custom__tag:only-of-type span::after {
  content: '';
}

.custom__tag span {
  @apply select-none;
}

.custom__tag span::after {
  content: ', ';
  white-space: pre;
}

.multiselect__content-wrapper {
  @apply absolute w-full bg-white rounded shadow-lg mt-2 z-40 overflow-y-auto;
  top: 2.5rem;
}

.multiselect--right .multiselect__content-wrapper {
  @apply right-0;
}

.multiselect__content {
  @apply w-full text-sm py-2;
}

.multiselect__element {
  @apply w-full cursor-pointer;
  transition: 0.2s;
}

.multiselect__option {
  @apply h-full w-full flex items-center px-4;
}

.multiselect__option span {
  @apply flex items-start relative overflow-hidden w-full py-2;
  word-wrap: break-word;
  hyphens: auto;
}

.multiselect__option span::before,
.multiselect__option span::after {
  @apply flex-shrink-0;
  transition: 0.1s;
}

.multiselect__option span::before {
  content: '';
  @apply border-2 border-gray-600 rounded inline-block mr-4;
  margin-top: 0.125rem;
  width: 1.125rem;
  height: 1.125rem;
}

.multiselect__option span::after {
  content: '';
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+PHBhdGggZD0iTTIzIDYuNjI1NDRMMjAuNDE1IDQgOC4zMzMzMyAxNi4xODQzbC00LjczLTQuNzUxN0wxIDE0LjAzOTVsNy4zMzMzMyA3LjM3NzJMMjMgNi42MjU0NHoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @apply w-3 h-3 rounded inline-block mr-4 absolute left-0 opacity-0;
  top: 0.5rem;
  margin-left: 0.2rem;
  margin-top: 0.325rem;
}

.multiselect__option--highlight {
  @apply bg-blue-100;
}

.multiselect__option--selected {
  @apply text-dark-blue-800;
}

.multiselect__option--selected span::before {
  @apply bg-dark-blue-900 border-dark-blue-900;
}

.multiselect__option--selected span::after {
  @apply opacity-100;
}

.multiselect--single .multiselect__option span::before {
  @apply w-4 h-4 rounded-full border-2 border-gray-500 visible block;
  background: none;
  top: 0.125rem;
  transition: 0.1s ease;
}

.multiselect--single .multiselect__option span::after {
  @apply -mb-1 rounded-full bg-dark-blue-900 visible inline-block opacity-0;
  background-image: none;
  top: 0.625rem;
  left: 0.1rem;
  width: 0.375rem;
  height: 0.375rem;
  transition: 0.1s ease;
}

.multiselect--single .multiselect__option--selected span::before {
  @apply border-dark-blue-900;
}

.multiselect--single .multiselect__option--selected span::after {
  @apply opacity-100;
}

.multiselect__option--highlight span::before,
.multiselect--single .multiselect__option--highlight span::before {
  @apply border-dark-blue-700;
}

.multiselect__option--selected.multiselect__option--highlight span::before,
.multiselect__option--selected.multiselect--single
  .multiselect__option--highlight
  span::before {
  @apply border-dark-blue-900;
}

.multiselect__element:hover {
  @apply bg-blue-100;
}

.no-result {
  @apply text-gray-700;
}

.no-result::before,
.no-result::after {
  @apply hidden !important;
}
</style>
