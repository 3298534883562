<template>
  <Icon
    name="chevron-down"
    class="transition text-gray-700 absolute top-0 right-0 mr-4 lg:mr-0 mt-4 pointer-events-none"
  />
</template>

<script>
import Icon from '~/components/Icon'

export default {
  components: {
    Icon
  }
}
</script>

<style lang="postcss"></style>
