<template>
  <div>
    <div v-if="module.length > 1">
      <h3>
        Für welchen Veranstaltungsort möchten Sie sich anmelden?
      </h3>
      <div class="mb-8 flex flex-col items-start">
        <label
          v-for="region in module"
          :key="region[0]"
          class="inline-flex items-center cursor-pointer mt-4"
          :class="{
            'text-red-500': $v.location.$error
          }"
        >
          <input
            v-model.trim="location"
            type="radio"
            name="location"
            class="mr-2"
            :value="region[0]"
            @input="$v.location.$touch()"
          />
          {{ region[0] }}
        </label>
      </div>
    </div>
    <div>
      <h3>
        Buchen Sie die Fortbildung für ein Unternehmen oder als Privatperson?
      </h3>

      <div class="mt-4 flex flex-col">
        <div class="flex items-center">
          <input
            id="company"
            v-model="type"
            class="cursor-pointer"
            type="radio"
            value="company"
            @input="$v.type.$touch()"
          />
          <label
            for="company"
            :class="{ 'text-red-500': $v.type.$error }"
            class="cursor-pointer pl-1"
          >
            Unternehmen
          </label>
        </div>

        <div class="mt-3 flex items-center">
          <input
            id="person"
            v-model="type"
            class="cursor-pointer"
            type="radio"
            value="person"
            @input="$v.type.$touch()"
          />
          <label
            for="person"
            :class="{ 'text-red-500': $v.type.$error }"
            class="cursor-pointer pl-1"
          >
            Privatperson
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { groupBy, orderBy } from 'lodash'

export default {
  components: {},
  props: {
    vorlage: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      type: '',
      location: '',
      priceGroup: ''
    }
  },
  validations: {
    type: {
      required
    },
    location: {
      required: requiredIf(function() {
        return this.module.length > 1
      })
    },
    form: ['type', 'location']
  },
  computed: {
    selectedVeranstaltung() {
      const termin = this.$route.query.termin

      return this.vorlage.veranstaltungen
        .filter((va) => va.nummer === termin)
        .shift()
    },
    module() {
      const module =
        this.selectedVeranstaltung && this.selectedVeranstaltung.module

      const moduleObject = groupBy(
        orderBy(module, ['region'], ['asc']),
        'region'
      )

      return Object.keys(moduleObject).map((key) => [key, moduleObject[key]])
    }
  },
  methods: {
    validate() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    }
  }
}
</script>
