export const state = () => ({
  list: []
})

export const getters = {
  getRegionById: (state) => (id) => {
    return state.list.find((item) => item.nummer === id)
  }
}

export const mutations = {
  add(state, data) {
    state.list.push(data)
  },
  restore(state, data) {
    state.list = data
  }
}

export const actions = {
  async getAll({ commit }) {
    await this.$axios.get(`/regionen`).then(({ data }) => {
      commit('restore', data)
    })
  },

  async get({ commit }, id) {
    await this.$axios.get(`/regionen/${id}`).then(({ data }) => {
      commit('add', data)
    })
  }
}
