export const state = () => ({
  isCookieConsentOpen: false
})

export const getters = {}

export const mutations = {
  setModalShow(state) {
    if (!state.isCookieConsentOpen) state.isCookieConsentOpen = true
  },
  setModalHide(state) {
    if (state.isCookieConsentOpen) {
      state.isCookieConsentOpen = false
      window.location.reload(true)
    }
  }
}

export const actions = {
  showModal({ commit }) {
    commit('setModalShow')
  },
  hideModal({ commit }) {
    commit('setModalHide')
  }
}
