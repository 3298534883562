<template>
  <div>
    <div v-if="processing">
      <h3 class="mt-6 mb-3 text-green-700">Wir übermitteln Ihre Anfrage</h3>
      <p>
        Bitte haben Sie einen Moment Geduld, Sie werden in Kürze weitergeleitet.
      </p>
    </div>
    <div v-else>
      <h3 class="mt-6 mb-3">
        Bitte überprüfen Sie noch einmal Ihre Daten und bestätigen Sie diese
      </h3>

      <h3 class="mt-12 mb-3">
        Folgende Veranstaltungen haben Sie ausgewählt:
      </h3>

      <ul v-for="(item, index) in items" :key="item.titel">
        <li :key="index" class="list-disc">
          {{ item.titel }} ({{ item.nummer }})
        </li>
      </ul>

      <h3 v-if="type === 'company'" class="mt-12 mb-3">
        Sie haben die Anmeldung als <strong>Unternehmen</strong> ausgefüllt
      </h3>

      <h3 v-if="type === 'person'" class="mt-12 mb-3">
        Sie haben die Anmeldung als <strong>Privatperson</strong> ausgefüllt
      </h3>

      <p v-if="fullPrice > 0">
        Die Gesamtsumme beträgt
        <strong>{{ fullPrice | currency }}</strong>
      </p>

      <p v-if="fullPrice > 0">
        Gewählte Zahlungsmethode:
        <strong>{{ paymentMethod.name }}</strong>
      </p>

      <div v-if="type === 'person'">
        <h3 class="mt-12 mb-3">Privatperson</h3>

        <table class="text-sm">
          <tbody>
            <tr>
              <td>Anrede</td>
              <td>
                {{ model.privateSalutation }}
              </td>
            </tr>
            <tr>
              <td>Titel</td>
              <td>
                {{ model.privateJobTitle }}
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ model.firstName }} {{ model.lastName }}</td>
            </tr>
            <tr>
              <td>Adresse</td>
              <td>
                {{ model.address }}
                <br />
                {{ model.zip }} {{ model.city }}
              </td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>{{ model.email }}</td>
            </tr>
            <tr>
              <td>Bemerkungen</td>
              <td>{{ model.privateBemerkung }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="type === 'company'">
        <h3 class="mt-12 mb-3">Unternehmen</h3>

        <table class="text-sm">
          <tbody>
            <tr>
              <td>Name des Unternehmens</td>
              <td>{{ model.company }}</td>
            </tr>
            <tr>
              <td>Adresse</td>
              <td>
                {{ model.companyAddress }}
                <br />
                {{ model.companyZip }} {{ model.companyCity }}
              </td>
            </tr>
            <tr>
              <td>Ansprechpartner</td>
              <td>
                {{ model.companyContactFirstName }}
                {{ model.companyContactLastName }}
              </td>
            </tr>
            <tr>
              <td>Position</td>
              <td>{{ model.companyContactPosition }}</td>
            </tr>
            <tr>
              <td>Funktion</td>
              <td>{{ model.companyContactPositionFunction }}</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>{{ model.companyContactEmail }}</td>
            </tr>
            <tr>
              <td>Telefon</td>
              <td>{{ model.companyContactPhone }}</td>
            </tr>
          </tbody>
        </table>

        <div v-for="participant in model.participants" :key="participant.email">
          <h3 class="mt-12 mb-3">Teilnehmer</h3>
          <table class="text-sm">
            <tbody>
              <tr>
                <td>Anrede</td>
                <td>{{ participant.salutation }}</td>
              </tr>
              <tr>
                <td>Titel</td>
                <td>{{ participant.jobTitle }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ participant.firstName }} {{ participant.lastName }}</td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>{{ participant.email }}</td>
              </tr>
              <tr>
                <td>Bestellnummer</td>
                <td>{{ participant.bestellnummer }}</td>
              </tr>
              <tr>
                <td>Kostenstelle</td>
                <td>{{ participant.kostenstelle }}</td>
              </tr>
              <tr>
                <td>Bemerkungen</td>
                <td>{{ participant.bemerkung }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Newsletter -->
      <div class="newsletterSection ebz-filter-bg ">
        <h3 class="mb-3 flex gap-4 items-center">
          <span class="flex items-center bg-dark-blue-900 rounded-full p-3">
            <img src="~/assets/img/send.svg" class="w-4 h-4" />
          </span>
          Newsletter-Anmeldung
        </h3>
        <v-collapse-group class="mt-6">
          <v-collapse-wrapper
            class="border-none"
            @onStatusChange="toggleButtonCaption"
          >
            <div class="">
              <div
                v-if="$v.newsletter.$error"
                class="text-red-500 font-semibold my-2"
              >
                Bitte wählen Sie ein oder mehrere Themen aus, zu denen Sie
                unseren Newsletter erhalten möchten.
              </div>

              <input
                id="newsletter"
                v-model="newsletter"
                v-collapse-toggle
                type="checkbox"
                :class="{
                  Input: true,
                  'Input--Error': $v.newsletter.$error
                }"
              />
              <label for="newsletter">
                Mit Ihrer Anmeldung akzeptieren Sie unsere
                <a
                  href="https://www.e-b-z.de/datenschutzerklaerung.html"
                  target="_blank"
                  >Datenschutzbestimmungen</a
                >
                sowie
                <a href="https://www.e-b-z.de/agb.html" target="_blank"
                  >Allgemeinen Geschäftsbedingungen</a
                >
                und erklären sich einverstanden, dass Ihnen per E-Mail auf Ihre
                Interessen abgestimmte Informationen über Angebote des
                Europäischen Bildungszentrums der Wohnungs- und
                Immobilienwirtschaft (EBZ) sowie deren angeschlossenen
                Unternehmen zugesendet werden.
              </label>
            </div>
            <div v-collapse-content>
              <div class="w-full">
                <div class="flex flex-wrap justify-between">
                  <div class="mt-6 w-full md:w-1/3">
                    <h4>Branchen-Newsletter</h4>

                    <div class="mt-3">
                      <div>
                        <input
                          id="newsletter_wohnungswirtschaft"
                          v-model="newsletter_topics.wohnungswirtschaft"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_wohnungswirtschaft"
                          >Wohnungswirtschaft</label
                        >
                      </div>
                      <div class="mt-0 ml-6">
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_management_personal_rechnungswesen"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_management_personal_rechnungswesen
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_management_personal_rechnungswesen"
                            >Fokus auf Management, Personal,
                            Rechnungswesen</label
                          >
                        </div>
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_technik"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_technik
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_technik"
                            >Fokus auf Technik</label
                          >
                        </div>
                        <div>
                          <input
                            id="newsletter_wohnungswirtschaft_fokus_vermietung_bestand_weg"
                            v-model="
                              newsletter_topics.wohnungswirtschaft_fokus_vermietung_bestand_weg
                            "
                            type="checkbox"
                            :class="{ Input: true }"
                          />
                          <label
                            for="newsletter_wohnungswirtschaft_fokus_vermietung_bestand_weg"
                            >Fokus auf Vermietung, Bestand und WEG</label
                          >
                        </div>
                      </div>
                      <div>
                        <input
                          id="newsletter_immobilienverwalter"
                          v-model="newsletter_topics.immobilienverwalter"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_immobilienverwalter"
                          >Immobilienverwalter</label
                        >
                      </div>
                      <div>
                        <input
                          id="newsletter_makler_bankenmakler"
                          v-model="newsletter_topics.makler_bankenmakler"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="newsletter_makler_bankenmakler"
                          >Makler, Bankenmakler</label
                        >
                      </div>
                      <div>
                        <input
                          id="newsletter_property_management_facility_management"
                          v-model="
                            newsletter_topics.property_management_facility_management
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label
                          for="newsletter_property_management_facility_management"
                          >Property Management, Facility Management</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 w-full md:w-1/3">
                    <h4>Themen-Newsletter</h4>

                    <div class="mt-3">
                      <div>
                        <input
                          id="ausbilung_und_karriere"
                          v-model="newsletter_topics.ausbilung_und_karriere"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="ausbilung_und_karriere"
                          >Ausbilung und Karriere</label
                        >
                      </div>
                      <div>
                        <input
                          id="bauen_und_technik"
                          v-model="newsletter_topics.bauen_und_technik"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="bauen_und_technik">Bauen und Technik</label>
                      </div>
                      <div>
                        <input
                          id="digitalisierung_it"
                          v-model="newsletter_topics.digitalisierung_it"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="digitalisierung_it"
                          >Digitalisierung, IT</label
                        >
                      </div>
                      <div>
                        <input
                          id="fuhrung_und_personliche_kompetenzen"
                          v-model="
                            newsletter_topics.fuhrung_und_personliche_kompetenzen
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="fuhrung_und_personliche_kompetenzen"
                          >Führung und persönliche Kompetenzen</label
                        >
                      </div>
                      <div>
                        <input
                          id="genossenschaften"
                          v-model="newsletter_topics.genossenschaften"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="genossenschaften">Genossenschaften</label>
                      </div>
                      <div>
                        <input
                          id="gewerbeimmobilien"
                          v-model="newsletter_topics.gewerbeimmobilien"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="gewerbeimmobilien">Gewerbeimmobilien</label>
                      </div>
                      <div>
                        <input
                          id="klima_energie_nachhaltigkeit"
                          v-model="
                            newsletter_topics.klima_energie_nachhaltigkeit
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="klima_energie_nachhaltigkeit"
                          >Klima, Energie, Nachhaltigkeit</label
                        >
                      </div>
                      <div>
                        <input
                          id="management_personal_marketing"
                          v-model="
                            newsletter_topics.management_personal_marketing
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="management_personal_marketing"
                          >Management, Personal, Marketing</label
                        >
                      </div>
                      <div>
                        <input
                          id="makler"
                          v-model="newsletter_topics.makler"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="makler">Makler</label>
                      </div>
                      <div>
                        <input
                          id="rechnugnswesen_finanzierung_controlling"
                          v-model="
                            newsletter_topics.rechnugnswesen_finanzierung_controlling
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="rechnugnswesen_finanzierung_controlling"
                          >Rechnugnswesen, Finanzierung, Controlling</label
                        >
                      </div>
                      <div>
                        <input
                          id="vermietung_bestandsmanagement_quartiere"
                          v-model="
                            newsletter_topics.vermietung_bestandsmanagement_quartiere
                          "
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="vermietung_bestandsmanagement_quartiere"
                          >Vermietung, Bestandsmanagement, Quartiere</label
                        >
                      </div>
                      <div>
                        <input
                          id="immobilienverwaltung_weg"
                          v-model="newsletter_topics.immobilienverwaltung_weg"
                          type="checkbox"
                          :class="{ Input: true }"
                        />
                        <label for="immobilienverwaltung_weg"
                          >Immobilienverwaltung, WEG</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 w-full md:w-1/3">
                    <h4>Weitere Newsletter</h4>

                    <div class="mt-3">
                      <input
                        id="bildungsangebote_frankfurt"
                        v-model="newsletter_topics.bildungsangebote_frankfurt"
                        type="checkbox"
                        :class="{ Input: true }"
                      />
                      <label for="bildungsangebote_frankfurt"
                        >Bildungsangebote Frankfurt</label
                      >
                    </div>
                    <div class="mt-3">
                      <input
                        id="newsletter_ebz_business_school"
                        v-model="newsletter_topics.ebz_business_school"
                        type="checkbox"
                        :class="{ Input: true }"
                      />
                      <label for="newsletter_ebz_business_school"
                        >EBZ Business School News</label
                      >
                    </div>
                    <div>
                      <input
                        id="newsletter_stellenangebote"
                        v-model="newsletter_topics.stellenangebote"
                        type="checkbox"
                        :class="{ Input: true }"
                      />
                      <label for="newsletter_stellenangebote"
                        >Stellenangebote aus der Immobilienwirtschaft</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-12 w-full md:w-2/3">
                <h4>Neuigkeiten aus dem EBZ Akademie Blog</h4>
                <div class="mt-3">
                  <input
                    id="followBlog"
                    v-model="followBlog"
                    type="checkbox"
                    :class="{
                      Input: true,
                      'Input--Error': $v.followBlog.$error
                    }"
                  />
                  <label for="followBlog">
                    Bitte senden Sie mir regelmäßig Neuigkeiten aus dem
                    <a
                      href="https://www.e-b-z.de/fachbereiche/ebz-akademie/ebz-akademie-blog.html"
                      target="_blank"
                      >EBZ Akademie Blog</a
                    >
                    per E-Mail.
                  </label>
                </div>
              </div>

              <div class="mt-8 w-full">
                <p class="text-sm w-full max-w-full">
                  Nach der Registrierung zum Newsletter erhalten Sie von uns
                  eine E-Mail mit einem Bestätigungslink. Erst durch das
                  Anklicken dieses Linkes ist Ihre Anmeldung abgeschlossen und
                  wirksam. Sie können Ihre Einwilligung zum Erhalt der
                  Informationen per E-Mail mit Wirkung für die Zukunft unter den
                  in den Datenschutzbestimmungen angegebenen Kontakten oder an
                  <a href="mailto:werbung@e-b-z.de">werbung@e-b-z.de</a>
                  jederzeit widerrufen.
                </p>
              </div>
            </div>
          </v-collapse-wrapper>
        </v-collapse-group>
      </div>

      <!-- Bestätigung -->
      <div class="mt-12 w-full md:w-2/3">
        <div
          v-if="$v.einwilligung.$error"
          class="text-red-500 font-semibold my-2"
        >
          Sie müssen unseren AGB und Datenschutzbestimmungen zustimmen, um die
          Buchung abzuschließen.
        </div>

        <input
          id="einwilligungCheck"
          v-model="einwilligung"
          type="checkbox"
          :class="{ Input: true, 'Input--Error': $v.einwilligung.$error }"
        />
        <label for="einwilligungCheck">
          Hiermit bestätige ich, dass ich die
          <a href="https://www.e-b-z.de/agb/ebz-stiftung.html" target="_blank"
            >Allgemeinen Geschäftsbedingungen</a
          >, sowie die
          <a href="https://www.e-b-z.de/agb/ebz-stiftung.html" target="_blank"
            >Widerrufsbelehrung</a
          >
          gelesen und zur Kenntnis genommen habe. Die
          <a
            href="https://www.e-b-z.de/datenschutzerklaerung.html"
            target="_blank"
            >Datenschutzbestimmungen</a
          >
          habe ich gelesen und zur Kenntnis genommen.
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { sameAs } from 'vuelidate/lib/validators'
import { filter } from 'lodash'

export default {
  props: {
    processing: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'company'
    },
    paymentMethod: {
      type: Object,
      default: () => {
        return {
          name: 'Keine ausgewählt',
          image: null
        }
      }
    },
    model: {
      type: Object,
      default: () => {}
    },
    cart: {
      type: Array,
      default: () => []
    }
  },
  validations: {
    followBlog: {},
    newsletter: {
      sameAs: sameAs('newsletterTopicsSelected')
    },
    einwilligung: {
      checked: (value) => value === true
    }
  },
  data() {
    return {
      newsletterVisible: false,
      einwilligung: false,
      newsletter: false,
      newsletter_topics: {
        wohnungswirtschaft: false,
        wohnungswirtschaft_fokus_management_personal_rechnungswesen: false,
        wohnungswirtschaft_fokus_technik: false,
        wohnungswirtschaft_fokus_vermietung_bestand_weg: false,
        immobilienverwalter: false,
        makler_bankenmakler: false,
        property_management_facility_management: false,
        ebz_business_school: false,
        bildungsangebote_frankfurt: false,
        stellenangebote: false,
        ausbilung_und_karriere: false,
        bauen_und_technik: false,
        digitalisierung_it: false,
        fuhrung_und_personliche_kompetenzen: false,
        genossenschaften: false,
        gewerbeimmobilien: false,
        klima_energie_nachhaltigkeit: false,
        management_personal_marketing: false,
        makler: false,
        rechnugnswesen_finanzierung_controlling: false,
        vermietung_bestandsmanagement_quartiere: false,
        immobilienverwaltung_weg: false
      },
      followBlog: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'cart/items'
    }),
    fullPrice() {
      const price = this.$store.getters['cart/completePrice']

      const anzahl =
        this.type === 'person'
          ? 1
          : this.model.participants && this.model.participants.length

      return price * anzahl
    },
    newsletterTopicsSelected() {
      const selectedTopics = filter(
        this.newsletter_topics,
        (item) => item === true
      )

      return selectedTopics && selectedTopics.length > 0
    }
  },
  mounted() {},
  methods: {
    toggleButtonCaption() {
      this.newsletterVisible = !this.newsletterVisible

      // reset newsletter topics if checkbox is unchecked
      if (this.newsletterVisible === false) {
        this.newsletter_topics = {
          wohnungswirtschaft: false,
          wohnungswirtschaft_fokus_management_personal_rechnungswesen: false,
          wohnungswirtschaft_fokus_technik: false,
          wohnungswirtschaft_fokus_vermietung_bestand_weg: false,
          immobilienverwalter: false,
          makler_bankenmakler: false,
          property_management_facility_management: false,
          ausbildung_karriere_auszubildende: false,
          ausbildung_karriere_ausbilder_personal: false,
          ebz_business_school: false,
          stellenangebote: false,
          ausbilung_und_karriere: false,
          bauen_und_technik: false,
          digitalisierung_it: false,
          fuhrung_und_personliche_kompetenzen: false,
          genossenschaften: false,
          gewerbeimmobilien: false,
          klima_energie_nachhaltigkeit: false,
          management_personal_marketing: false,
          makler: false,
          rechnugnswesen_finanzierung_controlling: false,
          vermietung_bestandsmanagement_quartiere: false,
          immobilienverwaltung_weg: false
        }
      }
    },
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    updateRabattCode(value) {
      this.branche = value
    }
  }
}
</script>

<style lang="postcss" scoped>
table {
  @apply w-full;
}

@screen md {
  table {
    @apply w-2/3;
  }
}

tr:nth-child(odd) {
  @apply bg-gray-300;
}

td {
  @apply w-1/3 align-top py-2 px-1;
}

td:first-child {
  @apply font-semibold;
}

.vc-collapse {
  @apply m-0;
}

.newsletterSection {
  @apply mt-12 px-4 py-6;

  @screen md {
    @apply pl-6;

    margin-left: -1.5rem;
  }
}
</style>
