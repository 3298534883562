<template>
  <button
    ref="scrollToTop"
    v-scroll-to="{
      el: 'html',
      easing: 'ease-in-out',
      duration: 600
    }"
    v-scroll-class:is-shown="645"
    class="button scroll-to-top print:hidden"
  >
    <Icon name="chevron-up" class="w-6 h-6" />
  </button>
</template>

<script>
import VueScrollClass from 'vue-scroll-class'
import Icon from '~/components/Icon'

export default {
  components: {
    Icon
  },
  directives: {
    'scroll-class': VueScrollClass
  }
}
</script>

<style lang="postcss">
.scroll-to-top {
  @apply bg-blue-600 h-10 w-10 flex items-center justify-center fixed z-50 rounded-full opacity-0 invisible p-0;
  transition: 0.3s ease;
  right: 2rem;
  bottom: 2rem;
}

@screen md {
  .scroll-to-top {
    bottom: 2rem;
  }
}

.scroll-to-top:hover {
  @apply bg-blue-700;
}

.scroll-to-top.is-shown,
.scroll-to-top.is-shown:focus {
  @apply visible opacity-100;
}
</style>
