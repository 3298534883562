<template>
  <div>
    <h3 class="mt-6 mb-3">Zahlungsmethoden</h3>
    <div class="w-full">
      <div
        v-for="item in paymentMethods"
        :key="item.name"
        class="flex border-b-2 border-gray-300"
      >
        <label
          :for="item.name"
          class="flex py-4 w-full"
          :class="{ 'text-red-500': $v.paymentMethod.$error }"
        >
          <input
            :id="item.name"
            v-model="paymentMethod"
            type="radio"
            class="mr-4"
            :value="item"
            required
            @input="$v.paymentMethod.$touch()"
          />
          <span class="mr-4">{{ item.name }}</span>
          <img :src="item.image" />
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      paymentMethod: null
    }
  },
  validations: {
    paymentMethod: {
      required
    },
    form: ['paymentMethod']
  },
  computed: {
    paymentMethods() {
      return this.$store.getters['payment/getMethods'].flat()
    }
  },
  mounted() {
    const numbers = this.$store.getters['cart/getEventNumbers']
    let events = ''

    for (let i = 0; i < numbers.length; i++) {
      events += `&events[]=${numbers[i]}`
    }

    this.$store.dispatch('payment/getAll', events)
  },
  methods: {
    updatePaymentMethod(value) {
      this.paymentMethod = value
    },
    validate() {
      this.$v.form.$touch()

      const isValid = !this.$v.form.$invalid

      this.$emit('on-validate', this.$data, isValid)

      return isValid
    }
  }
}
</script>
