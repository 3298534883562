<template>
  <div>
    <!-- <h3 class="mt-6 mb-3">Mitgliedschaften des Unternehmens / Arbeitsgebers</h3>
    <div class="w-full">
      <div v-for="item in mitgliedschaftenOptions" :key="item.code">
        <input
          :id="item.code"
          v-model="mitgliedschaften"
          type="checkbox"
          class="Input"
          :value="item"
        />
        <label :for="item.code">{{ item.desc }}</label>
      </div>
    </div> -->

    <h3 class="mt-6 mb-3">Branche</h3>
    <div class="w-full md:w-1/3">
      <div v-for="item in branchenOptions" :key="item.code">
        <input :id="item.code" v-model="branche" type="radio" :value="item" />
        <label :for="item.code">{{ item.desc }}</label>
      </div>
    </div>

    <h3 class="mt-6 mb-3">Größe des Unternehmens</h3>
    <div class="w-full md:w-1/3">
      <Dropdown
        id="firmengroesse"
        :selected="firmengroesse"
        :placeholder="'z. B. ' + firmengroesseOptions[0].desc"
        :options="firmengroesseOptions"
        class="mb-4"
        @on-input="updateFirmengroesse"
      />
    </div>

    <h3 class="mt-6 mb-3">Anzahl der WE in Bestand und/oder Verwaltung</h3>
    <div class="w-full md:w-1/3">
      <Dropdown
        id="wohneinheiten"
        :selected="wohneinheiten"
        :placeholder="'z. B. ' + wohneinheitenOptions[0].desc"
        :options="wohneinheitenOptions"
        class="mb-4"
        @on-input="updateWohneinheiten"
      />
    </div>
  </div>
</template>
<script>
import Dropdown from '~/components/inputs/Dropdown'

export default {
  components: {
    Dropdown
  },
  validations: {},
  data() {
    return {
      mitgliedschaften: [],
      branche: null,
      firmengroesse: null,
      wohneinheiten: null,
      mitgliedschaftenOptions: [
        {
          code:
            'GdW Bundesverband deutscher Wohnungs- und Immobilienunternehmen e.V.',
          desc:
            'GdW Bundesverband deutscher Wohnungs- und Immobilienunternehmen e.V.'
        },
        {
          code: 'VdW Rheinland Westfalen e.V.',
          desc: 'VdW Rheinland Westfalen e.V.'
        },
        {
          code:
            'BFW Bundesverband freier Immobilien- und Wohnungsunternehmen e.V.',
          desc:
            'BFW Bundesverband freier Immobilien- und Wohnungsunternehmen e.V.'
        },
        { code: 'ZIA', desc: 'ZIA' },
        { code: 'Haus und Grund', desc: 'Haus und Grund' },
        {
          code: 'DDIV und seine Landesverbände',
          desc: 'DDIV und seine Landesverbände'
        },
        {
          code: 'BVI Bundesfachverband der Immobilienverwalter e.V.',
          desc: 'BVI Bundesfachverband der Immobilienverwalter e.V.'
        },
        {
          code:
            'Immobilienverband Deutschland IVD Bundesverband der Immobilienberater, Makler, Verwalter und Sachverständigen e.V.',
          desc:
            'Immobilienverband Deutschland IVD Bundesverband der Immobilienberater, Makler, Verwalter und Sachverständigen e.V.'
        }
      ],
      branchenOptions: [
        { code: 'AS', desc: 'Architekt/Stadtplaner' },
        { code: 'BT', desc: 'Bauträger/Projektentwickler' },
        { code: 'BZ', desc: 'Bauzuliefererer' },
        { code: 'FW', desc: 'Finanzwirtschaft Banken' },
        { code: 'IU', desc: 'Industrieunternehmen' },
        { code: 'IV', desc: 'Immobilienverwalter' },
        { code: 'MA', desc: 'Makler/Bankenmakler' },
        { code: 'PM-FM', desc: 'Property Management/Facility Management' },
        { code: 'WU', desc: 'Wohnungsunternehmen' },
        { code: 'SONSTIGE', desc: 'Sonstige' }
      ],
      firmengroesseOptions: [
        { code: '1-20', desc: '1 - 20 Mitarbeiter' },
        { code: '21-100', desc: '21 - 100 Mitarbeiter' },
        { code: '101-500', desc: '100 - 500 Mitarbeiter' },
        { code: '>500', desc: 'Über 500 Mitarbeiter' },
        { code: 'UNBEKANNT', desc: 'Unbekannt' }
      ],
      wohneinheitenOptions: [
        { code: '1-500', desc: 'Bis 500 Wohneinheiten' },
        { code: '500-1.000', desc: '501 - 1.000 Wohneinheiten' },
        { code: '1.000-2.000', desc: '1.001 - 2.000 Wohneinheiten' },
        { code: '2.000-5.000', desc: '2.001 - 5.000 Wohneinheiten' },
        { code: '5.000-10.000', desc: '5.001 - 10.000 Wohneinheiten' },
        { code: 'MEHR ALS 10.000', desc: 'Mehr als 10.000 Wohneinheiten' },
        { code: 'OHNE ZUORD', desc: 'Keine Wohneinheiten' }
      ]
    }
  },

  methods: {
    validate() {
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      this.$emit('on-validate', this.$data, isValid)
      return isValid
    },
    updateBranche(value) {
      this.branche = value
    },
    updateFirmengroesse(value) {
      this.firmengroesse = value
    },
    updateWohneinheiten(value) {
      this.wohneinheiten = value
    },
    updateMitgliedschaften(value) {
      this.mitgliedschaften = value
    }
  }
}
</script>
