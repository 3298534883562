import Vue from 'vue'
import VueGtag from 'vue-gtag'

// Vue.use(VueGtag, {
//   config: {
//     id: process.env.GA_PROPERTY,
//     params: {
//       anonymize_ip: true
//     }
//   }
// })

const vueGtag = ({ app, env }) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: env.GA_PROPERTY,
        pageTrackerScreenviewEnabled: true,
        params: {
          anonymize_ip: true,
          groups: 'default'
        }
      },
      includes: [
        {
          id: env.GA4_PROPERTY,
          params: {
            anonymize_ip: true
          }
        }
      ],
      // enabled: false,
      bootstrap: false
    },
    app.router
  )
}

export default vueGtag
