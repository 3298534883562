/* global fbq */
/* eslint-disable */
const loadScript = (src, callback) => {
  const head = document.getElementsByTagName('head')[0]

  if (src) {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    head.appendChild(script)
    script.addEventListener('load', () => {
      if (typeof callback === 'function') callback()
    })
  }
}

export default ({ app, env }) => {
  // if (process.env.NODE_ENV !== 'production') return

  const consentAdsComfort = localStorage.getItem('GDPR:adscomfort')

  if (consentAdsComfort !== 'true') return

  const { GA_PROPERTY, FACEBOOK_PIXEL } = env

  // loadScript(
  //   `https://www.googletagmanager.com/gtag/js?id=${GA_PROPERTY}`,
  //   () => {
  //     window.dataLayer = window.dataLayer || []
  //     function gtag() {
  //       window.dataLayer.push(arguments)
  //     }
  //     gtag('js', new Date())
  //     gtag('config', GA_PROPERTY, { anonymize_ip: true })

  //     app.router.afterEach((to, from) => {
  //       /*
  //        ** We tell Google Analytics to add a `pageview`
  //        */
  //       // ga('set', 'page', to.fullPath)
  //       // ga('send', 'pageview')
  //       gtag('config', GA_PROPERTY, {
  //         // page_title: document.title,
  //         page_title: to.fullPath,
  //         page_path: to.fullPath
  //       })
  //     })
  //   }
  // )

  /* eslint-disable */
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  /* eslint-enable */

  const head = document.getElementsByTagName('head')[0]

  const noscript = document.createElement('noscript')
  const pixel = document.createElement('img')
  pixel.height = 1
  pixel.width = 1
  pixel.style.display = 'none'
  pixel.src = `https://www.facebook.com/tr?id=${FACEBOOK_PIXEL}&ev=PageView&noscript=1`

  noscript.appendChild(pixel)

  head.appendChild(noscript)

  fbq('init', FACEBOOK_PIXEL)

  app.router.afterEach((to, from) => {
    fbq('track', 'PageView')
  })

  // loadScript('https://connect.facebook.net/en_US/fbevents.js', () => {
  //   console.log('LOAD Facebook')

  //   fbq('init', '{your-pixel-id-goes-here}')
  //   fbq('track', 'PageView')
  // })
  // const script = document.createElement('script')
  // script.src = src
  // script.async = true
  // head.appendChild(script)
  // script.addEventListener('load', () => {
  // })
  // app.router.afterEach((to, from) => {
  //   /*
  //    ** We tell Google Analytics to add a `pageview`
  //    */
  //   // ga('set', 'page', to.fullPath)
  //   // ga('send', 'pageview')
  //   gtag('config', 'UA-101297385-8', {
  //     // 'page_title' : 'homepage',
  //     page_path: to.fullPath
  //   })
  //   console.log(typeof gtag === 'function')
  // })
}
